.checkpoint {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color:#151515;
    z-index: 3;
    left: 0%;
    top: 0%;
    overflow-y: scroll;

    img{
      margin-top: 1%;
      width: 97%;
      margin-left: 1.5%;
      height: 22%;
      border-radius: 20px;
    }

    .main {
      background-color: #1a1a1a;
      width: 60vw;
      margin-left: 20vw;
      height: max-content;
      padding-bottom: 4%;
      min-height: 80%;
      margin-top: 10vh;
      padding-left: 4%;
      padding-right: 4%;
      border-radius: 25px;
      overflow-y: hidden;
      margin-bottom: 3%;

      .submit-btn {
        background: #a1991d;
        margin-left: 35%;
        width: 30%;
        height: 6vh;
        border-radius: 15px;
        font-size: 17px;
        margin-right: 3%;
        border: none;
        font-size: 600;
      }
      .final {
        display: flex;
        p {
          color: #a8a6a6;
          font-size: 25px;
          margin-left: 8%;
        }
        .nav-btn {
          background: #a1991d;
          width: 32%;
          height: 5vh;
          border-radius: 15px;
          font-size: 15px;
          margin-right: 3%;
          border: none;
          font-size: 600;
        }
      }

      .heading {
        padding-left: 3%;
        padding-top: 0.5%;
        width: 60vw;
        position: absolute;
        top: 30vh;
        left: 20vw;
        height: 8vh;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        background-color:#1f1f1f;
        color: azure;
        font-weight: 300;
        font-size: 25px;
      }

      .question-box {
        width: 90%;
        height: max-content;
        padding-bottom: 4%;
        margin-top: 7%;
        margin-left: 5%;
        padding-left: 4%;
        padding-right: 4%;
        border: none;

        .question {
          width: 100%;
          text-align: left;
          color: azure;
          font-size: 20px;
        }
        .options {
          width: 60%;
          margin-left: 20%;
          margin-top: 5%;
          height: max-content;
          background-color: transparent;
          cursor: pointer;

          p {
            margin-top: 2%;
            background-color: #3c3c3c;
            padding: 2%;
            padding-left: 4%;
            border-radius: 15px;
            color: azure;
          }
        }
        .option.selected {
          background-color: #a1991d; /* Apply yellow color to selected option */
          color: #1a1a1a;
        }
        .option.correct {
          background-color: limegreen; /* Apply green color to correct answer */
        }
        .option.wrong {
          background-color: red; /* Apply red color to wrong answer */
        }
        .answer {
          margin-top: 2%;
          background-color: rgb(9, 82, 9);
          padding: 2%;
          padding-left: 4%;
          border-radius: 15px;
          color: azure;
        }
      }
      .question-box::-webkit-scrollbar {
        width: 7px;
      }

      .question-box::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: #2b2b2b;
        border-radius: 11px;
      }

      .question-box::-webkit-scrollbar-thumb {
        max-height: 50px;
        background: #a8a6a6;
        border-radius: 11px;
      }
    }
  }