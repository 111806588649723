@media only screen and (max-width: 768px) {
  .we-are-one {
    width: 100vw;
    height: 100vh;
    background-color:  #EEDF05;
    overflow: hidden;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: start;
      background-color: #FCEE21;
      width: 250vw;
      height: 100vh;
      margin-left: -135vw;
      margin-top: 10vh;
      // padding: 12vh 20vw 20vw 20vw;
      // height: calc(100vh);
      transform: rotate(-6deg);
      .we-are-one-dots {
        position: relative;
        top: 8vh;
        left: -58vw;
        display: none;
        
      }
      .left-content {
        margin-top: 5vh;
        margin-left: 152vw;

        justify-content: center;
        align-items: center;

        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
  
        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 108.4%;
          /* or 69px */
          color: #000000;
          
          max-width: 350px;
          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
        }
  
        .sub-title {
          width: 491px;
  
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          // line-height: 133.4%;
          max-width: 233.6px;
          /* or 27px */
          padding-top: 20px;
          color: #000000;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
  
        .exp-button {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #000000;
          border-radius: 10px;
          font-family: "Rubik";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 19px;
          /* identical to box height */
          width: 164.21px;
          height: 37.8px;
          gap: 10px;
          margin-top: 50px;
          background: #000000;
          border-radius: 10px;
          order: 2;
          color:aliceblue;
        }
      }
  
      .right-content {
        
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-top: 12vw;
        margin-bottom: 200px;
        margin-left: 152vw;
       
        .img{
          height: 29vh;
          max-width: 330.63px;
          margin-right: auto;
          float: left;
          border-radius: 25px;
          margin-top: 6.5vh;
        }
      }
    }
  }
  }

@media only screen and (min-width: 768px) and (max-width: 1024px)  { 
.we-are-one {
    width: 100vw;
    background-color:  #FCEE21;
  
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: start;
      padding: 12vh 20vw 20vw 20vw;
      height: calc(100vh);
      .we-are-one-dots {
        position: relative;
        top: 8vh;
        left: 58vw;
        
      }
      .left-content {

        justify-content: center;
        align-items: center;

        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
  
        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 64px;
          line-height: 108.4%;
          /* or 69px */
          color: #000000;
          
          max-width: 320px;
          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
        }
  
        .sub-title {
          width: 491px;
  
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 133.4%;
          /* or 27px */
          padding-top: 20px;
          color: #000000;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
  
        .exp-button {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #000000;
          border-radius: 10px;
          font-family: "Rubik";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          /* identical to box height */
          width: 213px;
          height: 57px;
          gap: 10px;
          margin-top: 50px;
          background: #000000;
          border-radius: 10px;
          order: 2;
          color: #ffffff;
          margin-bottom: 7%;
        }
      }
  
      .right-content {
        
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-top: 2vw;
        margin-bottom: 200px;
       
        .img{
          height: 32vh;
          max-width: 335.63px;
          margin-right: auto;
          float: left;
          border-radius: 25px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
.we-are-one {
    display: flex;
    width: 100vw;
    background-color:  #EEDF05;
    height: 100vh;
    overflow: hidden;
    z-index: 3;
    position: relative;
    // margin-top: 100vh;
  

  
    .row {
      display: flex;
      transform: rotate(-12deg);
      width: 240vw;
      height: 200vh;
      margin-left: 0vw;
      margin-top: 10vh;
      background-color: #FCEE21;
      position: absolute;
      top:-35%;
      .we-are-one-dots {
        position: relative;
        top: 11vh;
        left: -24vw;
        
      }
      .left-content {
        justify-content: center;
        margin-left: 0vw;
        align-items: center;
        height: 60vh;
        display: flex;
        flex-direction: column;
        position: absolute;
        top:4vh;
        left:0vw;
        width: 70vw;

  
        .title {
          font-weight: 600;
          font-size: 8vh;

          /* or 69px */
          color: #000000;
          
          max-width: 20vw;
          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
          margin-left: -11vw;
          line-height: 8vh;
        }
  
        .sub-title {
          width: 30vw;
  
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 2.8vh;
          /* or 27px */
          padding-top: 3vh;
          margin-top: 2vh;
          color: #000000;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
  
        .exp-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #000000;
          font-family: "Rubik";
          font-style: normal;
          font-weight: 300;
          font-size: 1.8vh;
          line-height: 19px;
          /* identical to box height */
          margin-top: 5vh;
          margin-left: -17vw;
          background: #000000;
          border-radius: 10px;
          order: 2;
          color: #ffffff;
          cursor: pointer;
          width: 15vw;
          min-height: 7vh;
        }
      }
  
      .right-content {
        height: 50vh;
        position: absolute;
        top:10vh;
        width: 12vw;
        left: 65vw;
        display: flex;
        flex-direction: row;
        img{
          border-radius: 25px;
        }
      }
    }
  }
}
