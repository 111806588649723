

@media only screen and (max-width: 768px) {
    .whatIsOne {
        // display: flex;
        // flex-direction: column;
        height: 100vh;
        background-color: #EEDF05;

    .row {
            height: 100vh;
            display: flex;
            flex-direction: row; 
            width: 100%;

        .column {
            order: 1;
            height: calc(30vh);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // padding: 0 11vw;

            .title {
                margin-left: 6.5%;
                width: 100vw;
                font-weight: 600;
                font-size: 36px;
                line-height: 108.4%;
                /* or 39px */
                
                text-align: center;

                color: #000000;
            }

            .subtitle {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 133.4%;
                width: 70%;
                margin-left: 10%;
                margin-bottom: 4%;
                text-align: center;
                margin-top: 4%;
                margin-top: 5%;

                color: #313131;
            }
            .image{
                margin-top: 5%;
                width: 50%;
                margin-left: 8%;
                cursor: pointer;
            }
        }

        .right-column {
            display: flex;
            flex-direction: column;
            .gifts {
                margin-left: 10vw;
                margin-top: 20%;
                display: flex;
                width: 80vw;
            }
        }

    }
}
}


@media only screen and (min-width: 768px) and (max-width: 1024px)  {
    .whatIsOne {
        display: flex;
        flex-direction: column;
        background-color: #EEDF05;
        height: 100vh;
        width: 100vw;
        .row {

            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-evenly;
            width: 100%;

            .column {
                padding-left: 10%;
                height: calc(90vh);
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                width: 100vw;
                margin-top: 50%;
                margin-left: 10%;

                .title {
                    font-weight: 600;
                    font-size: 60px;
                    line-height: 108.4%;
                    width: 70%;
                    margin-bottom: 2%;
                    /* or 69px */
                    text-align: center;

                    letter-spacing: 0.015em;

                    color: #000000;
                }

                .subtitle {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 28px;
                    max-width: 477px;
                    line-height: 133.4%;
                    text-align: center;
                    /* or 37px */


                    color: #313131;
                }
                .image{
                    width: 40%;
                    margin-left: 16%;
                    cursor: pointer;
                }
            }

            .right-column {
                height: calc(50vh);
                display: flex;
                flex-direction: column;
                position: absolute;
                width:80%;
                margin-bottom: 75%;
                left: 15%;

                .gifts {
                    display: flex;
                    height: 100vh;
                    justify-content: end;
                    width: 544.42px;
                    height: 567.45px;
                    margin-right: 5%;
                }
            }

        }
    }
}

@media only screen and (min-width: 1024px) {
    .whatIsOne {
        display: flex;
        flex-direction: column;
        background-color: #EEDF05;
        height: 100vh;
        width: 100vw;
        
        .row {

            display: flex;
            flex-direction: row;
            align-items: start;
            position: absolute;
            justify-content: space-evenly;
            width: 100%;
            height: 100%;
            z-index: 3;

            .column {
                padding-left: 10%;
                height: calc(90vh);
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                margin-left: 5%;
                // padding: 0 11vw;

                .title {
                    font-weight: 600;
                    font-size: 65px;
                    line-height: 108.4%;
                    margin-top: 5%;
                    /* or 69px */

                    letter-spacing: 0.030em;

                    color: #000000;
                }

                .subtitle {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 26px;
                    max-width: 430px;
                    line-height: 133.4%;
                    margin-top: 2%;
                    /* or 37px */


                    color: #313131;
                }
                .image{
                    width: 18%;
                    margin-top: 2%;
                    cursor:pointer;
                    z-index: 3;
                }
            }

            .right-column {
                height: calc(100vh);
                display: flex;
                flex-direction: column;
                align-items: end;
                justify-content: center;
                align-content: end;
                position: absolute;

                .gifts {
                    display: flex;
                    height: 100vh;
                    justify-content: end;
                    width: 544.42px;
                    height: 567.45px;
                    margin-right: 11%;
                }
            }

        }
    }
}