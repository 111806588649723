// phones
@media only screen and (max-width: 768px) {
    .pricing {
        position: relative;
        background-color: black;
        padding-bottom: 230px;
        border-radius: 0 0 70px 70px;

        .background {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            object-fit: fill;
            height: 65vh;
            color: #2D2D2D;
            width: 140%;
        }

        .content {
            position: relative;
            z-index: 2;
            padding: 15% 8%;

            .column {
                display: flex;
                flex-direction: column;
                align-items: center;
                /* add this line */
                justify-content: center;

                .title {
                    max-width: 270px;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 38px;
                    text-align: center;
                    color: #FFFFFF;
                }

                .sub-title {
                    max-width: 700px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 139.9%;
                    text-align: center;
                    color: #FFFFFF;
                    padding-top: 20px;
                }

                .card {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-top: 60px;
                    background: #FFFFFF;
                    border-radius: 31px;
                    margin-top: 80px;
                    padding-bottom: 20px;
                    text-align: center;

                    .personal-table {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        // width: 80%;

                        tbody {
                            width: 100%;

                            th,
                            td {
                                text-align: end;
                                border-top: 2px solid #E4E4E4;
                                padding: 20px 0;
                                width: 100vw;
                                padding-left: 15%;
                                padding-right: 12%;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 18px;
                                color: #000000;
                            }

                            tr:first-child td {
                                border-top: none;
                            }

                            th.title,
                            td.title {
                                text-align: left;
                                width: 70%;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 15px;
                                line-height: 18px;
                            }

                            .non-ref{
                                width: 100%;
                                text-align: center;
                                position: absolute;
                                margin-top: -1vh;
                                font-size: 1.5vh;
                                color: black;
                                z-index: 4;
                            }
                            .tick {
                                height: 15px;
                            }
                        }
                    }


                    .personal {
                        height: 250px;
                        width: 230px;
                        background: #FCEE21;
                        border-radius: 32px;
                        position: relative;
                        margin: auto;
                        bottom: 50px;

                        .personal-bg {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            height: 250px;
                            width: 230px;
                        }

                        .personal-content {
                            position: relative;
                            z-index: 2;

                            .title {
                                padding-top: 50px;
                                font-family: 'Rubik';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 18px;
                                text-align: center;
                                color: #000000;

                            }

                            .price {
                                padding-top: 40px;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 48px;
                                line-height: 58px;
                                /* identical to box height */
                                text-align: center;
                                color: #000000;

                                .rupees-symbol {
                                    position: absolute;
                                    font-weight: 200;
                                    font-size: 34px;
                                    top: 90px;
                                    left: 35px;
                                }
                            }

                            .sub-title {
                                padding-top: 10px;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 15px;
                                line-height: 18px;
                                text-align: center;

                                color: #454545;
                            }
                        }
                    }

                    .free-plan {
                        display: flex;
                        flex-direction: column;
                        margin: 30% auto 10% auto ;
                        padding: 20% 10% 20% 10%;
                        justify-content: center;
                        align-items: center;
                        background: #FFFFFF;
                        border: 1px solid #EAE8E8;
                        box-shadow: 4px 4px #FCEE21;
                        border-radius: 20px;
                        width: 210px;

                        .free-plan__title {
                            font-family: 'Rubik';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            text-align: center;
                            color: #000000;
                            padding-bottom: 40px;
                        }

                        .free-plan__sub-title {
                            font-family: 'Rubik';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 19px;
                            text-align: center;
                            color: #292929;
                            padding-bottom: 40px;
                        }

                        .signup-button {
                            border: none;
                            padding: 12px 35px;
                            background: #FCEE21;
                            border-radius: 14px;
                            font-family: 'Rubik';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            text-decoration: none;
                            line-height: 24px;
                            /* identical to box height */
                            text-align: center;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
}

// tablet
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    // .pricing {
    //     position: relative;
    //     background-color: black;
    //     padding-bottom: 230px;
    //     border-radius: 0 0 70px 70px;

    //     .background {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         z-index: 1;
    //         object-fit: fill;
    //         height: 100vh;
    //         color: #2D2D2D;
    //         width: 130%;
    //     }

    //     .content {
    //         position: relative;
    //         z-index: 2;
    //         padding: 80px;

    //         .column {
    //             display: flex;
    //             flex-direction: column;
    //             align-items: center;
    //             /* add this line */
    //             justify-content: center;

    //             .title {
    //                 max-width: 350px;
    //                 font-family: 'Rubik';
    //                 font-style: normal;
    //                 font-weight: 500;
    //                 font-size: 36px;
    //                 line-height: 43px;
    //                 text-align: center;

    //                 color: #FFFFFF;
    //             }

    //             .sub-title {
    //                 max-width: 400px;
    //                 font-family: 'Montserrat';
    //                 font-style: normal;
    //                 font-weight: 400;
    //                 font-size: 16px;
    //                 line-height: 20px;
    //                 text-align: center;
    //                 color: #FFFFFF;
    //                 padding-top: 20px;
    //             }

    //             .card {
    //                 width: 100%;
    //                 margin-top: 60px;
    //                 background: #FFFFFF;
    //                 border-radius: 31px;
    //                 margin-top: 80px;
    //                 padding-bottom: 20px;

    //                 table {
    //                     width: 100%;
    //                     border-collapse: collapse;

    //                     th,
    //                     td {
    //                         text-align: center;
    //                         border-top: 1px solid #E4E4E4;
    //                         padding: 20px 0;
    //                         width: 33%;
    //                         font-family: 'Inter';
    //                         font-style: normal;
    //                         font-weight: 600;
    //                         font-size: 16px;
    //                         line-height: 19px;
    //                         color: #000000;

    //                         .personal {
    //                             height: 260px;
    //                             width: 230px;
    //                             background: #FCEE21;
    //                             border-radius: 32px;
    //                             position: relative;
    //                             bottom: 50px;

    //                             .personal-bg {
    //                                 position: absolute;
    //                                 top: 0;
    //                                 left: 0;
    //                                 z-index: 1;
    //                                 height: 260px;
    //                                 width: 230px;
    //                             }

    //                             .personal-content {
    //                                 position: relative;
    //                                 z-index: 2;

    //                                 .title {
    //                                     padding-top: 50px;
    //                                     font-family: 'Rubik';
    //                                     font-style: normal;
    //                                     font-weight: 600;
    //                                     font-size: 15px;
    //                                     line-height: 18px;
    //                                     text-align: center;
    //                                     color: #000000;

    //                                 }

    //                                 .price {
    //                                     padding-top: 40px;
    //                                     font-family: 'Inter';
    //                                     font-style: normal;
    //                                     font-weight: 600;
    //                                     font-size: 48px;
    //                                     line-height: 58px;
    //                                     /* identical to box height */
    //                                     text-align: center;
    //                                     color: #000000;

    //                                     .rupees-symbol {
    //                                         position: absolute;
    //                                         font-weight: 200;
    //                                         font-size: 34px;
    //                                         top: 90px;
    //                                         left: 35px;
    //                                     }
    //                                 }

    //                                 .sub-title {
    //                                     padding-top: 10px;
    //                                     font-family: 'Inter';
    //                                     font-style: normal;
    //                                     font-weight: 400;
    //                                     font-size: 15px;
    //                                     line-height: 18px;
    //                                     text-align: center;

    //                                     color: #454545;
    //                                 }
    //                             }
    //                         }

    //                         .free-plan {
    //                             height: 230px;
    //                             align-items: flex-start;

    //                             .free-plan__title {
    //                                 font-family: 'Rubik';
    //                                 font-style: normal;
    //                                 font-weight: 400;
    //                                 font-size: 16px;
    //                                 line-height: 19px;
    //                                 /* identical to box height */
    //                                 text-align: center;
    //                                 color: #000000;
    //                                 padding-bottom: 20px;
    //                             }

    //                             .free-plan__sub-title {
    //                                 font-family: 'Rubik';
    //                                 font-style: normal;
    //                                 font-weight: 400;
    //                                 font-size: 16px;
    //                                 padding: 5%;
    //                                 line-height: 19px;
    //                                 text-align: center;
    //                                 color: #292929;
    //                                 padding-bottom: 40px;
    //                             }

    //                             .signup-button {
    //                                 border: none;
    //                                 padding: 12px 35px;
    //                                 background: #FCEE21;
    //                                 border-radius: 14px;
    //                                 font-family: 'Rubik';
    //                                 font-style: normal;
    //                                 text-decoration: none;
    //                                 font-weight: 400;
    //                                 font-size: 16px;
    //                                 line-height: 19px;
    //                                 /* identical to box height */
    //                                 text-align: center;
    //                                 color: #000000;
    //                             }
    //                         }
    //                     }

    //                     tr:first-child td {
    //                         border-top: none;
    //                     }

    //                     th.title,
    //                     td.title {
    //                         padding-left: 10%;
    //                         text-align: left;
    //                         width: 35%;
    //                         font-family: 'Inter';
    //                         font-style: normal;
    //                         font-weight: 400;
    //                         font-size: 16px;
    //                         line-height: 19px;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    .pricing {
        position: relative;
        background-color: black;
        padding-bottom: 230px;
        border-radius: 0 0 70px 70px;

        .background {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            object-fit: fill;
            height: 100vh;
            color: #2D2D2D;
            width: 130%;
        }

        .content {
            position: relative;
            z-index: 2;
            padding: 80px;

            .column {
                display: flex;
                flex-direction: column;
                align-items: center;
                /* add this line */
                justify-content: center;

                .title {
                    max-width: 350px;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 43px;
                    text-align: center;

                    color: #FFFFFF;
                }

                .sub-title {
                    max-width: 400px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFFFFF;
                    padding-top: 20px;
                }

                .card {
                    width: 100%;
                    margin-top: 60px;
                    background: #FFFFFF;
                    border-radius: 31px;
                    margin-top: 80px;
                    padding-bottom: 20px;

                    table {
                        width: 100%;
                        border-collapse: collapse;

                        th,
                        td {
                            text-align: center;
                            border-top: 1px solid #E4E4E4;
                            padding: 20px 0;
                            width: 33%;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            color: #000000;

                            .personal {
                                height: 260px;
                                width: 230px;
                                background: #FCEE21;
                                border-radius: 32px;
                                position: relative;
                                bottom: 50px;

                                .personal-bg {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 1;
                                    height: 260px;
                                    width: 230px;
                                }

                                .personal-content {
                                    position: relative;
                                    z-index: 2;

                                    .title {
                                        padding-top: 50px;
                                        font-family: 'Rubik';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 15px;
                                        line-height: 18px;
                                        text-align: center;
                                        color: #000000;

                                    }

                                    .price {
                                        padding-top: 40px;
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 48px;
                                        line-height: 58px;
                                        /* identical to box height */
                                        text-align: center;
                                        color: #000000;

                                        .rupees-symbol {
                                            position: absolute;
                                            font-weight: 200;
                                            font-size: 34px;
                                            top: 90px;
                                            left: 35px;
                                        }
                                    }

                                    .sub-title {
                                        padding-top: 10px;
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 15px;
                                        line-height: 18px;
                                        text-align: center;

                                        color: #454545;
                                    }
                                }
                            }

                            .free-plan {
                                height: 230px;
                                align-items: flex-start;

                                .free-plan__title {
                                    font-family: 'Rubik';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19px;
                                    /* identical to box height */
                                    text-align: center;
                                    color: #000000;
                                    padding-bottom: 20px;
                                }

                                .free-plan__sub-title {
                                    font-family: 'Rubik';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    padding: 5%;
                                    line-height: 19px;
                                    text-align: center;
                                    color: #292929;
                                    padding-bottom: 40px;
                                }

                                .signup-button {
                                    border: none;
                                    padding: 12px 35px;
                                    background: #FCEE21;
                                    border-radius: 14px;
                                    font-family: 'Rubik';
                                    font-style: normal;
                                    text-decoration: none;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19px;
                                    /* identical to box height */
                                    text-align: center;
                                    color: #000000;
                                }
                            }
                        }

                        tr:first-child td {
                            border-top: none;
                        }

                        th.title,
                        td.title {
                            padding-left: 10%;
                            text-align: left;
                            width: 35%;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                        }
                        .non-ref{
                            width: 100%;
                            text-align: center;
                            position: absolute;
                            margin-top: -1.7vh;
                        }
                    }
                }
            }
        }
    }
    
}



// For Desktops
@media only screen and (min-width: 1024px) {
    .pricing {
        position: relative;
        background-color: black;
        padding-bottom: 230px;
        border-radius: 0 0 70px 70px;
        z-index: 3;
        .background {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            object-fit: fill;
            height: 65vh;
            color: #2D2D2D;
            width: 100%;
        }

        .content {
            position: relative;
            z-index: 2;
            padding: 80px;

            .column {
                display: flex;
                flex-direction: column;
                align-items: center;
                /* add this line */
                justify-content: center;

                .title {
                    max-width: 350px;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 48px;
                    line-height: 57px;
                    text-align: center;
                    color: #FFFFFF;
                }

                .sub-title {
                    max-width: 600px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFFFFF;
                    padding-top: 20px;
                }

                .card {
                    width: 1000px;
                    margin-top: 60px;
                    background: #FFFFFF;
                    border-radius: 31px;
                    margin-top: 80px;
                    padding-bottom: 20px;

                    table {
                        width: 100%;
                        border-collapse: collapse;

                        th,
                        td {
                            text-align: center;
                            border-top: 1px solid #E4E4E4;
                            padding: 20px;
                            width: 33%;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            color: #000000;

                            .personal {
                                height: 300px;
                                width: 300px;
                                background: #FCEE21;
                                border-radius: 32px;
                                position: relative;
                                bottom: 50px;

                                .personal-bg {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 1;
                                    height: 300px;
                                    width: 300px;
                                }

                                .personal-content {
                                    position: relative;
                                    z-index: 2;

                                    .title {
                                        padding-top: 50px;
                                        font-family: 'Rubik';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 24px;
                                        line-height: 28px;
                                        text-align: center;
                                        color: #000000;

                                    }

                                    .price {
                                        padding-top: 40px;
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 48px;
                                        line-height: 58px;
                                        /* identical to box height */
                                        text-align: center;
                                        color: #000000;

                                        .rupees-symbol {
                                            position: absolute;
                                            font-weight: 200;
                                            font-size: 34px;
                                            top: 100px;
                                            left: 70px;
                                        }
                                    }

                                    .sub-title {
                                        padding-top: 50px;
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 20px;
                                        line-height: 24px;
                                        text-align: center;

                                        color: #454545;
                                    }
                                }
                            }

                            .free-plan {
                                height: 260px;
                                padding: 0% 10%;
                                align-items: flex-start;

                                .free-plan__title {
                                    font-family: 'Rubik';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 20px;
                                    line-height: 24px;
                                    /* identical to box height */
                                    text-align: center;
                                    color: #000000;
                                    padding-bottom: 40px;
                                }

                                .free-plan__sub-title {
                                    font-family: 'Rubik';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19px;
                                    text-align: center;
                                    color: #292929;
                                    padding-bottom: 40px;
                                }

                                .signup-button {
                                    border: none;
                                    padding: 12px 35px;
                                    background: #FCEE21;
                                    border-radius: 14px;
                                    font-family: 'Rubik';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 20px;
                                    text-decoration: none;
                                    line-height: 24px;
                                    /* identical to box height */
                                    text-align: center;
                                    color: #000000;
                                }
                            }
                        }

                        tr:first-child td {
                            border-top: none;
                        }

                        th.title,
                        td.title {
                            padding-left: 100px;
                            text-align: left;
                            width: 35%;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 24px;
                        }
                        .non-ref{
                            width: 100%;
                            text-align: center;
                            position: absolute;
                            margin-top: -1.7vh;
                            font-size: 1.7vh;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .free-plan{
        height: 260px;
        padding: 0% 10%;

        .free-plan__title {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 24px;
            /* identical to box height */
            text-align: center;
            color: #000000;
            padding-bottom: 40px;
        }

        .free-plan__sub-title {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #292929;
            padding-bottom: 40px;
        }

        .signup-button {
            border: none;
            padding: 12px 35px;
            background: #FCEE21;
            border-radius: 14px;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            text-decoration: none;
            line-height: 24px;
            /* identical to box height */
            text-align: center;
            color: #000000;
        }
    }
}