.discover_inner{
    // position: absolute;
    height: 100vh;
    width: 100vw;
    background: #FCEE21;
    z-index: 2;

    .content{
        width: 80vw;
        height: 100vh;
        margin-left: 10%;
        background: #F1E422;
        align-self: center;

        .main{
            display: flex;
            flex-direction: column;
            width: 80vw;
            height: 100vh;

            .text{
                width: 80vw;
                margin-top: -25vh;
                h1{
                    width: 50%;
                    margin-left: 15%;
                    height: 15%;
                    text-align: left;
                    font-size: 7vh;
                    margin-bottom: 9vh;
                }
                p{
                    width: 70%;
                    margin-left: 15%;
                    height: 5%;
                    text-align: left;
                    font-size: 2.6vh;
                    margin-bottom: 5%;
                    letter-spacing: 0.1vh;
                }
                .p2{
                    font-size: 2.8vh;
                }
            }
            img{
                margin-top: 10%;
                width: 17%;
                height: 28%;
                margin-left: 70%;
                margin-bottom: 3%;
                z-index: 5;
            }
        }
        .bts{
            width: 23%;
            margin-left:38.5%;
            margin-top: 5%;
            height: 10.3%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .discover_inner{
        // position: absolute;
        height: 100vh;
        width: 100vw;
        background: #FCEE21;
        z-index: 2;
        padding-top: 20%;
    
        .content{
            width: 100%;
            height: 90%;
            margin-left: 0%;
            background: #F1E422;
            align-self: center;
    
            .main{
                display: flex;
                flex-direction: column;
                width: 80vw;
                height: 100vh;
    
                .text{
                    width: 78vw;
                    margin-top: 2vh;
                    h1{
                        width: 100%;
                        margin-left: 15%;
                        height: 15%;
                        text-align: center;
                        font-size: 3vh;
                        margin-bottom: 0vh;
                        margin-top: 3vh;
                    }
                    p{
                        width: 100%;
                        margin-left: 15%;
                        height: 5%;
                        text-align: center;
                        font-size: 1.2vh;
                        margin-bottom: 5%;
                        margin-top: 3vh;
                    }
                    .p2{
                        font-size: 1.4vh;
                        margin-top: 8vh;
                    }
                }
                img{
                    margin-top: 15%;
                    width: 50%;
                    height: 18%;
                    margin-left: 35%;
                    margin-bottom: 3%;
                    z-index: 5;
                }
            }
            .bts{
                width: 65%;
                margin-left:17.5%;
                margin-top: 30%;
                height: 11%;
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px)  {
    .discover_inner{
        height: 100vh;
        width: 100vw;
        background: #FCEE21;
        z-index: 2;
    
        .content{
            width: 100%;
            height: 90%;
            margin-left: 0%;
            background: #F1E422;
            align-self: center;
    
            .main{
                display: flex;
                flex-direction: column;
                width: 80vw;
                height: 100vh;
    
                .text{
                    width: 78vw;
                    margin-top: 2vh;
                    h1{
                        width: 100%;
                        margin-left: 15%;
                        height: 15%;
                        text-align: center;
                        font-size: 3vh;
                        margin-bottom: 1vh;
                        margin-top: 3vh;
                    }
                    p{
                        width: 100%;
                        margin-left: 15%;
                        height: 5%;
                        text-align: center;
                        font-size: 1.2vh;
                        margin-bottom: 5%;
                        margin-top: 5vh;
                    }
                    .p2{
                        font-size: 1.4vh;
                        margin-top: 10vh;
                    }
                }
                img{
                    margin-top: 15%;
                    width: 50%;
                    height: 18%;
                    margin-left: 35%;
                    margin-bottom: 3%;
                    z-index: 5;
                }
            }
            .bts{
                width: 50%;
                margin-left:25%;
                margin-top: 10%;
                height: 10%;
            }
        }
    }
}