@media only screen and (max-width: 768px) {   
  .mental-health {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: start;
  align-items: center;
  background-color: white;
  overflow: hidden;
  .btn{
    // position: absolute;
    margin-top: -7vh;
    background-color: black;
    color: white;
    padding: 2%;
    border-radius: 10px;
    width: 30vw;
    padding-left: 5%;
    padding-right: 5%;
  }
  .mental-health__title{
     font-size: 40px;
     width: 80vw;
     height: 20vh;
      margin-top: 9vh;
    img{
      height: 150px;
      width: 250px;
    }
    .bottom-text{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      margin-top: 200px;
      line-height: 137.02%;
      text-align: center;
      letter-spacing: 0.07em;
      position: absolute;
      width: 90%;
      right: 5%;
      width: 100%;
      top: 270%;
      z-index: 4;
    }
  }
  .right-section {
      img {
          margin-top: 10vh;
          height: 45vh;
          padding-right: auto;
      }
  }
  
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px)  {   
 .mental-health {
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: start;
      align-items: center;
      background-color: white;
      overflow: hidden;
      
      .mental-health__title{
         font-size: 40px;
         width: 80vw;
         height: 20vh;
          margin-top: 9vh;
        img{
          height: 150px;
          width: 250px;
        }
        .bottom-text{
          font-weight: 400;
          font-size: 16px;
          margin-top: 200px;
          line-height: 137.02%;
          text-align: center;
          letter-spacing: 0.07em;
          position: absolute;
          width: 100%;
          left:-5%;
          top: 300%;
        }
      }
      .right-section {
          img {
              margin-top: 10vh;
              height: 45vh;
              padding-right: auto;
          }
      }
      
  }
  }



@media only screen and (min-width: 1024px) {
.mental-health {
    display: flex;
    direction: row;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    background-color:white;
    overflow: hidden;
    padding: 0;

    .btn{
      // position: absolute;
      margin-top: -7vh;
      background-color: black;
      color: white;
      padding: 1%;
      border-radius: 10px;
      width: max-content;
      padding-left: 5%;
      padding-right: 5%;
      position: absolute;
      left: 10%;
      margin-top: 40vh;
    }

    .mental-health__title{
      margin-top: -15vh;
      font-size: 4vw;
        width: 35vw;
        .bottom-text{
          font-size: 2.7vh;
          font-weight: 500;
        }

    }
    .right-section {
        img {
            height: 95vh;
            padding-right: 2vw;
        }
    }
    .care-image{
      height: 250px;
      width: 25vw;
    }
    
}
}