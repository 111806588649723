.progressTabMob{ 
    position: absolute;
    left:0%;
    top:18%;
    margin-top: 1.5%;
    margin-left: 2.5%;
    width:95%;
    height: 80%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // padding: 20px 21px;
    padding: 2%;
    gap: 19px;
    background-color:#1A1A1A;
    z-index: 2;
    // display: none;
    
    .container-1{
        height: 25vh;
        width: 100%;
        // background-color: aliceblue;
        border: 3px solid #252525;
        border-right: none;
        border-left: none;
        border-top: none;
        h5{
            text-align: left;
            font-size: 18px;
            margin-left: 8%;
            color: #B8B8B8;
        }
        .per{
            font-size: 15px;
            margin-left: 15%;
            z-index: 3;
            height: 1%;
            width: 70%;
            p{
                display: flex;
                flex-direction: column;
                margin-left: 0%;
                width:100%;
                color: #B8B8B8;
            }
        }
        .progressBar {
            padding: 0.5px;
            margin-left: 15%;
            margin-top: 15%;
            width: 70%;
            height: 6%;
            background: #414141;
            border-radius: 10px;
            overflow: hidden;
            // .progressPopUp{
            //     // position: absolute;
            //     // left: 85%;
            // }
          }
          
          .PBinner {
            border-radius: 10px;
            width: 50%;
            height: 100%;
            background: #A1991D;
            transition: width 0.5s ease-in-out;

          }
    }
    .container-2{
        max-height: 30vh;
        min-height: 30vh;
        width: 100%;
        border: 3px solid #252525;
        border-top: none;
        border-left: none;
        border-right: none;

        .viewInfo{
            width: 100%;
            height: 100%;
            z-index:3;
            gap: 19px;
            overflow-y: scroll;

            .back{
                background-color: #A1991D;
                height: 18%;
                width: 28%;
                border: none;
                border-radius: 15px;
                padding: 1.5%;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                margin-left: 5%;
                margin-bottom: 3%;

            }

            .component{
                display: flex;
                width: 90%;
                margin-left: 5%;
                height: 20%;
                background-color: #2b2b2b;
                border-radius: 15px;
                padding-left: 5%;
                padding-top: 1%;
                margin-bottom: 3%;
                p{
                    font-size: 15px;
                    padding-top: 3%;
                    padding-left: 5%;
                    width: 40%;
                    color: #B8B8B8;
                    overflow-x: hidden;
                    overflow-y: hidden;
                }

                a{
                    margin-left: 28%;
                    margin-right: 2%;
                    background-color: #A1991D;
                    height: 90%;
                    width: 38%;
                    border: none;
                    border-radius: 15px;
                    padding: 1.5%;
                    font-weight: 400;
                    font-size: 15px;
                    padding-left: 2%;
                                                text-decoration: none;
                            color: black;
                            text-align: center;
                            padding-top: 3%;
                }
            }
        }
        h5{
            color: #B8B8B8;
            text-align: left;
            font-size: 18px;
        }

        .desctext{
            margin-top: 30%;
            width:60%;
            margin-left: 20%;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.04em;
            text-align: center;
            color: #B8B8B8;
        }
        .Lib-btn{
            background: #A2991D;
            height: 15%;
            width: 32%;
            border: none;
            border-radius: 10px;
            padding: 1.5%;
            margin-left: 34%;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
        }
        img{
            position: absolute;
            top:24%;
            left: 0%;
            width: 23%;
            height: 20%;
            margin-left: 40%;
            margin-bottom: 2%;
            z-index: 1;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {    
        .progressTab{ 
            margin-left: 0%;
            margin-top: 1%;
            width:98%;
            height: 25%;
            padding: 1%;
            border: 1px solid #252525;
            border-radius: 35px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            // padding: 20px 21px;
            // padding: 2%;
            gap: 19px;
            background-color: #161616;
            z-index: 1;
            .container-1{
                padding: 2%;
                height: 100%;
                width: 100%;
                // background-color: aliceblue;
                border: 3px solid #252525;
                // border-right: none;
                border-left: none;
                border-top: none;
                border-bottom: none;
                h5{
                    margin-top: 20%;
                    text-align: left;
                    font-size: 18px;
                    color: #B8B8B8;
                }
                p{
                    font-size: 15px;
                    z-index: 3;
                    height: 1%;
                    width: 90%;
                    color: #B8B8B8;
                }
                .progressBar {
                    padding: 0.5px;
                    margin-left: 5%;
                    width: 90%;
                    height: 6%;
                    background: #414141;
                    border-radius: 10px;
                    overflow: hidden;
                    // .progressPopUp{
                    //     // position: absolute;
                    //     // left: 85%;
                    // }
                  }
                  
                  .PBinner {
                    border-radius: 10px;
                    height: 100%;
                    background: #A1991D;
                    transition: width 0.5s ease-in-out;
                  }
            }
            .container-2{
                height: 100%;
                width: 100%;
                padding: 2%;
                // background-color: aliceblue;
                border: 3px solid #252525;
                border-left: none;
                border-top: none;
                border-bottom: none;
                .viewInfo{
                    width: 100%;
                    height: 100%;
                    z-index:3;
                    gap: 19px;
                    overflow-y: scroll;

                    .back{
                        background-color: #A1991D;
                        height: 18%;
                        width: 35%;
                        border: none;
                        border-radius: 15px;
                        padding: 1.5%;
                        font-family: 'Manrope';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        margin-left: 5%;
                        margin-bottom: 5%;

                    }

                    .component{
                        display: flex;
                        width: 95%;
                        margin-left: 2.5%;
                        height: 20%;
                        background-color: #2b2b2b;
                        border-radius: 15px;
                        padding-left: 5%;
                        padding-top: 1%;
                        margin-bottom: 3%;
                        p{
                            font-size: 12px;
                            padding-top: 5%;
                            padding-left: 5%;
                            width: 40%;
                            overflow-x: hidden;
                            overflow-y: hidden;
                        }

                        a{
                            margin-left: 8%;
                            margin-top: 1%;
                            margin-right: 2%;
                            background-color: #A1991D;
                            height: 90%;
                            width: 48%;
                            border: none;
                            border-radius: 15px;
                            padding: 1.5%;
                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            padding-left: 3%;
                            text-decoration: none;
                            color: black;
                            text-align: center;
                            padding-top: 5%;
                        }
                    }
                }
                h5{
                    
                    text-align: left;
                    font-size: 18px;
                }

                .desctext{
                    margin-top: 30%;
                    width:60%;
                    margin-left: 20%;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    text-align: center;
                    color: #B8B8B8;
                }
                h5{
                    text-align: left;
                    font-size: 18px;
                    color: #B8B8B8;
                }

                p{
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    text-align: center;
                    color: #B8B8B8;
                }
                .Lib-btn{
                    background: #A2991D;
                    height: 15%;
                    width: 62%;
                    border: none;
                    border-radius: 15px;
                    padding: 1.5%;
                    margin-left: 18%;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                }
                img{
                    width: 30%;
                    height: 35%;
                    margin-left: 35%;
                    margin-bottom: 2%;
                }
        }
    }
}




@media only screen and (min-width: 1024px) {
    .progressTab{ 
        position: fixed;
        left: 73%;
        margin-top: 1%;
        width:25%;
        height: 96vh;
        border: 1px solid #252525;
        border-radius: 35px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // padding: 20px 21px;
        padding: 3vh;
        gap: 19px;
        
        .container-1{
            max-height: 17vh;
            min-height: 17vh;
            width: 100%;
            // background-color: aliceblue;
            border: 3px solid #252525;
            border-right: none;
            border-left: none;
            border-top: none;
            h5{
                text-align: left;
                color: #B8B8B8;
            }
            .progressPopUp{
                font-size: 15px;
                z-index: 3;
                height: 1%;
                width: 90%;
                margin-left: 5%;
                color: #B8B8B8;
                p{
                    margin: 0%;
                    width: fit-content;
                }
            }
            .progressBar {
                padding: 0.5px;
                margin-left: 5%;
                margin-top: 15%;
                width: 90%;
                height: 6%;
                background: #414141;
                border-radius: 10px;
                overflow: hidden;
                // .progressPopUp{
                //     // position: absolute;
                //     // left: 85%;
                // }
              }
              
              .PBinner {
                border-radius: 10px;
                height: 100%;
                background: #A1991D;
                transition: width 0.5s ease-in-out;
              }
        }
        .container-2{
            max-height: 35vh;

            min-height: 35vh;
            width: 100%;
            // background-color: aliceblue;
            border: 3px solid #252525;
            border-right: none;
            border-left: none;
            border-top: none;
                .viewInfo{
                    width: 100%;
                    height: 100%;
                    z-index:3;
                    gap: 19px;
                    overflow-y: scroll;

                    .back{
                        background-color: #A1991D;
                        height: 18%;
                        width: 28%;
                        border: none;
                        border-radius: 15px;
                        padding: 1.5%;
                        font-family: 'Manrope';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        margin-left: 5%;
                        margin-bottom: 3%;

                    }

                    .component{
                        display: flex;
                        width: 90%;
                        margin-left: 5%;
                        height: 20%;
                        background-color: #2b2b2b;
                        border-radius: 15px;
                        padding-left: 5%;
                        padding-top: 1%;
                        margin-bottom: 3%;
                        p{
                            padding-top: 4.5%;
                            font-size: 2vh;
                            width: 45%;
                            height: 65%;
                            overflow-x: hidden;
                            overflow-y: hidden;
                            line-height: 130%;
                        }

                        a{
                            margin-left: 18%;
                            margin-right: 2%;
                            background-color: #A1991D;
                            height: 90%;
                            width: 50%;
                            border: none;
                            border-radius: 15px;
                            padding: 1.5%;
                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            padding-left: 2%;
                            text-decoration: none;
                            color: black;
                            text-align: center;
                            padding-top: 3.5%;
                            
                        }
                    }
                }
                .viewInfo::-webkit-scrollbar {
                    width: 7px;
                  }
        
                  .viewInfo::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background: #2b2b2b;
                    border-radius: 11px;
                  }
        
                  .viewInfo::-webkit-scrollbar-thumb {
                    max-height: 50px;
                    background: #a8a6a6;
                    border-radius: 11px;
                  }
                h5{
                    text-align: left;
                    color: #B8B8B8;
                }
    
                p{
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.6vh;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    width: 80%;
                    margin-left: 10%;
                    text-align: center;
                    color: #B8B8B8;
                }
                .Lib-btn{
                    background: #A2991D;
                    height: 15%;
                    width: 42%;
                    border: none;
                    border-radius: 15px;
                    padding: 1.5%;
                    margin-left: 32%;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    margin-top: 2%;
                }
                img{
                    width: 25%;
                    height: 8vh;
                    margin-top: 3%;
                    margin-left: 37.5%;
                    margin-bottom: 7%;
                }
        }
    }
}