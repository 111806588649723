@media only screen and (max-width: 380px){

    .library{
        display: flex;
        flex-direction: column;
        padding-left: 100px;
        background-color: #1A1A1A;
        height: 100vh;
        color: white;
        width: 100%;
        position: fixed;
        overflow-y: hidden;
        overflow-x: hidden;

        .StayTuned{
            color: gold;
            width: 100%;
            text-align: center;
        }

        .mainP{
            position: fixed;
            background-color: #16161686;
            height: 100vh;
            width: 100vw;
            left: 0;
            z-index: 5;

            .contentP{
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                width: 80%;
                margin-left: 10%;
                padding: 5%;
                margin-top: 30%;

                .heading{
                    color: #C2B825;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
                img{
                    width: 60%;
                    margin-left: 20%;
                }
                .p1{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .p2{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                button{
                    border-radius: 6px;
                    background-color: #F4E61B;
                    border: none;
                    padding: 3%;
                    width: 50%;
                    margin-left: 25%;
                    font-size: 14px;
                }
            }
        }
    
        .progressTabMob{ 
            position: absolute;
            left:0%;
            top:16%;
            margin-top: 1.5%;
            margin-left: 0%;
            width:100%;
            height: 84%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            // padding: 20px 21px;
            padding: 2%;
            gap: 19px;
            background-color:#1A1A1A;
            z-index: 2;
            overflow-y: scroll;
            padding-bottom: 10vh;
            // display: none;
            
            .container-1{
                height: 25vh;
                width: 100%;
                // background-color: aliceblue;
                border: 3px solid #252525;
                border-right: none;
                border-left: none;
                border-top: none;
                h5{
                    text-align: left;
                    font-size: 18px;
                    margin-left: 8%;
                }
                .per{
                    font-size: 15px;
                    margin-left: 15%;
                    z-index: 3;
                    height: 1%;
                    width: 70%;
                    p{
                        display: flex;
                        flex-direction: column;
                        margin-left: 0%;
                        width:100%;
                    }
                }
                .progressBar {
                    padding: 0.5px;
                    margin-left: 15%;
                    margin-top: 15%;
                    width: 70%;
                    height: 6%;
                    background: #414141;
                    border-radius: 10px;
                    overflow: hidden;
                    // .progressPopUp{
                    //     // position: absolute;
                    //     // left: 85%;
                    // }
                  }
                  
                  .PBinner {
                    border-radius: 10px;
                    width: 50%;
                    height: 100%;
                    background: #A1991D;
                    transition: width 0.5s ease-in-out;

                  }
            }
            .container-2{
                max-height: 30vh;
                min-height: 30vh;
                width: 100%;
                border: 3px solid #252525;
                border-top: none;
                border-left: none;
                border-right: none;
                padding-bottom: 4%;

                h5{
                    padding-left: 10%;
                }
                .viewInfo{
                    width: 100%;
                    height: 100%;
                    z-index:3;
                    gap: 19px;
                    overflow-y: scroll;
        
                    .back{
                        background-color: #A1991D;
                        height: 18%;
                        width: 28%;
                        border: none;
                        border-radius: 15px;
                        padding: 1.5%;
                        font-family: 'Manrope';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        margin-left: 5%;
                        margin-bottom: 3%;
        
                    }
        
                    .component{
                        display: flex;
                        width: 90%;
                        margin-left: 5%;
                        height: 20%;
                        background-color: #2b2b2b;
                        border-radius: 15px;
                        padding-left: 5%;
                        padding-top: 1%;
                        margin-bottom: 3%;
                        p{
                            font-size: 15px;
                            padding-top: 1.6%;
                            padding-left: 5%;
                            width: 40%;
                            color: #B8B8B8;
                            overflow-x: hidden;
                            overflow-y: hidden;
                        }
        
                        a{
                            margin-left: 28%;
                            margin-right: 2%;
                            background-color: #A1991D;
                            height: 90%;
                            width: 28%;
                            border: none;
                            border-radius: 15px;
                            padding: 1.5%;
                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            padding-left: 2%;
                            text-decoration: none;
                            color: black;
                            text-align: center;
                            padding-top: 3%;
                        }
                    }
                }
                .desctext{
                    width: 94%;
                    margin-left: 3%;
                    margin-top: 13vh;

                }
                
                .Lib-btn{
                    background: #A2991D;
                    height: 17%;
                    width: 32%;
                    border: none;
                    border-radius: 10px;
                    padding: 1.5%;
                    margin-left: 34%;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                }
                img{
                    position: absolute;
                    top:21vh;
                    left: 0%;
                    width: 20%;
                    height: 20%;
                    margin-left: 40%;
                    margin-bottom: 2%;
                    z-index: 1;
                }
                #img2{
                    position: absolute;
                    top:56vh;
                    left: -2%;
                    width: 20%;
                    height: 15%;
                    margin-left: 40%;
                    margin-bottom: 2%;
                    z-index: 1;
                }
            }
        }
        .Chapters{
            position: absolute;
            left:0%;
            top:18%;
            margin-top: 1.5%;
            width:100%;
            height:80%;
            margin-right: 2%;
            // background-color: aliceblue;
            overflow-y: scroll;
            cursor: pointer;
            padding-bottom: 10vh;

            .NavbarMob2{
                width:70%;
                height: 6%;
                left: 15%;
                top:10%;
                background-color: #1C1C1C;
                display: flex;
                margin-bottom: 5%;
                border-radius: 8px;
                z-index:2;
                position: fixed;

                button{
                    width: 45%;
                    margin-left: 3%;
                    height: 80%;
                    margin-top: 1.5%;
                    background-color: #0E0E0E;
                    color: #979797;
                    border: none;
                    border-radius: 8px;
                    font-size: 13px;

                }
            }
    
            .chapter{
                color: aliceblue;
                border-radius: 25px;
                border: 3px solid #252525;
                height: 20%;
                min-height:15%;
                max-height: max-content;
                width: 95%;
                background-color: #16161667;
                margin: 2%;
                margin-bottom: 5%;
                margin-left: 2.5%;
                padding-top: 1%;
                text-align: center;
                display: flex;


                .banner{
                    height: 90%;
                    margin-top: 1%;
                    margin-left: 1%;
                    width: 23vw;
                }
                .track1{
                    position: absolute;
                    z-index: -1;
                    margin-top: 22%;
                    margin-left: 12%;
                    width: 68%;
                }
                .track2{
                    position: absolute;
                    z-index: -1;
                    margin-top: 22%;
                    margin-left: 12%;
                    width: 68%;
                }
    
                .content{
                    // margin-right: 5%;
                    margin-left: 3%;
                    width:58%;
                    height: 95%;
                    text-align: left;
                    padding-top: 2%;
                    .cName{
                        font-size: 11px;
                        font-weight: 700;
                    }
                    .cDesc{
                        font-size: 7px;
                        font-weight: 200;
                    }
                }
                .StartChap{
                    width:15%;
                    height: 90%;
                    background-color: rgba(5, 255, 0, 0.2);
                    border: 1px solid #252525;
                    border-radius: 15px;
                    color: aliceblue;
                    font-size: 10px;
                    transition: 0.5s ease;
                }
                .StartChap:hover{
                    cursor: pointer;
                    background-color: rgba(5, 255, 0, 0.4);

                }
                .ResumeChap{
                    background: rgba(252, 238, 33, 0.2);
                    width:15%;
                    height: 90%;
                    border-radius: 15px;
                    border: 1px solid #252525;
                    color: aliceblue;
                    font-size: 10px;
                    transition: 0.5s ease;
                    margin-right: 3%;
                }
                .ResumeChap:hover{
                    cursor: pointer;
                    background: rgba(252, 238, 33, 0.6);

                }
                .RestartChap{
                    background: #232323;
                    width:15%;
                    height: 90%;
                    border: 1px solid #252525;
                    border-radius: 15px;
                    color: aliceblue;
                    font-size: 10px;
                    transition: 0.5s ease;
                    margin-right: 3%;
                }
                .RestartChap:hover{
                    cursor: pointer;
                    background: #434343;

                }
                .LockedChapter{
                    width:15%;
                    height: 90%;
                    background-color: transparent;
                    border: 1px solid #252525;
                    border-radius: 15px;
                    color: aliceblue;
                    font-size: 10px;
                    transition: 0.5s ease;
                }
                .LockedChapter:hover{
                    cursor: pointer;
                    background-color: rgba(107, 139, 106, 0.4);

                }
    
            }
            .chapter:hover{
                background: #1E1E1E;
                opacity: 0.9;
            }
        }
        .Chapters::-webkit-scrollbar {
            width: 7px;
          }
           
        .Chapters::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background: #2B2B2B;
            border-radius: 11px;
          }
           
        .Chapters::-webkit-scrollbar-thumb {
            max-height: 50px;
            background: #979797;
            border-radius: 11px;
          }
    }

}

@media only screen and (min-width: 380px) and (max-width: 768px){
    .library{
        display: flex;
        flex-direction: column;
        padding-left: 100px;
        background-color: #1A1A1A;
        height: 100vh;
        color: white;
        width: 100%;
        position: fixed;
        overflow-y: hidden;
        overflow-x: hidden;

        .StayTuned{
            color: gold;
            width: 100%;
            text-align: center;
        }

        .mainP{
            position: fixed;
            background-color: #16161686;
            height: 100vh;
            width: 100vw;
            left: 0;
            z-index: 5;

            .contentP{
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                width: 80%;
                margin-left: 10%;
                padding: 5%;
                margin-top: 30%;

                .heading{
                    color: #C2B825;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
                img{
                    width: 60%;
                    margin-left: 20%;
                }
                .p1{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .p2{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                button{
                    border-radius: 6px;
                    background-color: #F4E61B;
                    border: none;
                    padding: 2%;
                    width: 50%;
                    margin-left: 25%;
                    font-size: 14px;
                }
            }
        }
    
        .progressTabMob{ 
            position: absolute;
            left:0%;
            top:18%;
            margin-top: 1.5%;
            margin-left: 0%;
            width:100%;
            height: 80%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            // padding: 20px 21px;
            padding: 2%;
            gap: 19px;
            background-color:#1A1A1A;
            z-index: 2;
            overflow-y: scroll;
            padding-bottom: 10vh;

            // display: none;
            
            .container-1{
                height: 25vh;
                width: 100%;
                // background-color: aliceblue;
                border: 3px solid #252525;
                border-right: none;
                border-left: none;
                border-top: none;
                h5{
                    text-align: left;
                    font-size: 18px;
                    margin-left: 8%;
                }
                .per{
                    font-size: 15px;
                    margin-left: 15%;
                    z-index: 3;
                    height: 1%;
                    width: 70%;
                    p{
                        display: flex;
                        flex-direction: column;
                        margin-left: 0%;
                        width:100%;
                    }
                }
                .progressBar {
                    padding: 0.5px;
                    margin-left: 15%;
                    margin-top: 15%;
                    width: 70%;
                    height: 6%;
                    background: #414141;
                    border-radius: 10px;
                    overflow: hidden;
                    // .progressPopUp{
                    //     // position: absolute;
                    //     // left: 85%;
                    // }
                  }
                  
                  .PBinner {
                    border-radius: 10px;
                    width: 50%;
                    height: 100%;
                    background: #A1991D;
                    transition: width 0.5s ease-in-out;

                  }
            }
            .container-2{
                max-height: 30vh;
                min-height: 30vh;
                width: 100%;
                border: 3px solid #252525;
                border-top: none;
                border-left: none;
                border-right: none;
                h5{
                    padding-left: 10%;
                }
                .desctext{
                    width: 94%;
                    margin-left: 3%;
                    margin-top: 35%;
        
                }
                .viewInfo{
                    width: 100%;
                    height: 100%;
                    z-index:3;
                    gap: 19px;
                    overflow-y: scroll;

                .back{
                    background-color: #A1991D;
                    height: 18%;
                    width: 28%;
                    border: none;
                    border-radius: 15px;
                    padding: 1.5%;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    margin-left: 5%;
                    margin-bottom: 3%;

                }

                .component{
                    display: flex;
                    width: 90%;
                    margin-left: 5%;
                    background-color: #2b2b2b;
                    border-radius: 15px;
                    padding-left: 5%;
                    padding-top: 1%;
                    margin-bottom: 3%;
                    p{
                        font-size: 15px;
                        padding-top: 1.5%;
                        padding-left: 5%;
                        width: 40%;
                        color: #B8B8B8;
                        overflow-x: hidden;
                        overflow-y: hidden;
                    }

                    a{
                        margin-left: 28%;
                        margin-right: 2%;
                        background-color: #A1991D;
                        height: 90%;
                        width: 28%;
                        border: none;
                        border-radius: 15px;
                        padding: 1.5%;
                        font-family: 'Manrope';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        padding-left: 2%;
                        text-decoration: none;
                        color: black;
                        text-align: center;
                        padding-top: 3%;
                    }
                }
            }

                
                .Lib-btn{
                    background: #A2991D;
                    height: 15%;
                    width: 32%;
                    border: none;
                    border-radius: 10px;
                    padding: 1.5%;
                    margin-left: 34%;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                }
                img{
                    position: absolute;
                    top:22%;
                    left: 0%;
                    width: 20%;
                    height: 20%;
                    margin-left: 40%;
                    margin-bottom: 2%;
                    z-index: 1;
                }
            }
        }
        .Chapters{
            position: absolute;
            left:0%;
            top:18%;
            margin-top: 1.5%;
            width:100%;
            height:80%;
            margin-right: 2%;
            // background-color: aliceblue;
            overflow-y: scroll;
            padding-bottom: 15vh;
            cursor: pointer;

            .NavbarMob2{
                width:70%;
                height: 6%;
                left: 15%;
                top:10%;
                background-color: #1C1C1C;
                display: flex;
                margin-bottom: 5%;
                border-radius: 8px;
                z-index:2;
                position: fixed;

                button{
                    width: 45%;
                    margin-left: 3%;
                    height: 80%;
                    margin-top: 1.5%;
                    background-color: #0E0E0E;
                    color: #979797;
                    border: none;
                    border-radius: 8px;
                    font-size: 13px;

                }
            }
    
            .chapter{
                color: aliceblue;
                border-radius: 25px;
                border: 3px solid #252525;
                height:20%;
                width: 95%;
                background-color: #16161667;
                margin: 2%;
                margin-bottom: 5%;
                margin-left: 2.5%;
                padding-top: 1%;
                text-align: center;
                display: flex;
                .track1{
                    position: absolute;
                    z-index: -1;
                    margin-top: 22%;
                    margin-left: 10%;
                    width: 68%;
                }
                .track2{
                    position: absolute;
                    z-index: -1;
                    margin-top: 23%;
                    margin-left: 14%;
                    width: 68%;
                }
                .banner{
                    height: 90%;
                    margin-top: 1%;
                    margin-left: 1%;
                    width: 25vw;
                }

    
                .content{
                    // margin-right: 5%;
                    margin-left: 2%;
                    width:58%;
                    height: 95%;
                    text-align: left;
                    padding-top: 2%;
                    .cName{
                        font-size: 13px;
                        font-weight: 700;
                    }
                    .cDesc{
                        font-size: 7px;
                        font-weight: 200;
                    }
                }
    
                .StartChap{
                    width:15%;
                    height: 90%;
                    background-color: rgba(5, 255, 0, 0.2);
                    border: 1px solid #252525;
                    border-radius: 15px;
                    color: aliceblue;
                    font-size: 10px;
                    transition: 0.5s ease;
                    margin-right: 2%;
                }
                .StartChap:hover{
                    cursor: pointer;
                    background-color: rgba(5, 255, 0, 0.4);

                }
                .ResumeChap{
                    background: rgba(252, 238, 33, 0.2);
                    width:15%;
                    height: 90%;
                    border-radius: 15px;
                    border: 1px solid #252525;
                    color: aliceblue;
                    font-size: 10px;
                    transition: 0.5s ease;
                    margin-right: 3%;
                }
                .ResumeChap:hover{
                    cursor: pointer;
                    background: rgba(252, 238, 33, 0.6);

                }
                .RestartChap{
                    background: #232323;
                    width:15%;
                    height: 90%;
                    border: 1px solid #252525;
                    border-radius: 15px;
                    color: aliceblue;
                    font-size: 10px;
                    transition: 0.5s ease;
                    margin-right: 3%;
                }
                .RestartChap:hover{
                    cursor: pointer;
                    background: #434343;

                }
    
            }
            .chapter:hover{
                background: #1E1E1E;
                opacity: 0.9;
            }
        }
        .Chapters::-webkit-scrollbar {
            width: 7px;
          }
           
        .Chapters::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background: #2B2B2B;
            border-radius: 11px;
          }
           
        .Chapters::-webkit-scrollbar-thumb {
            max-height: 50px;
            background: #979797;
            border-radius: 11px;
          }
    }
}



@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .library{
        display: flex;
        flex-direction: column-reverse;
        padding-left: 100px;
        background-color: #1A1A1A;
        height: 100vh;
        color: white;
        width: 100%;
        position: fixed;
        overflow-y: hidden;
        overflow-x: hidden;


        .StayTuned{
            color: gold;
            width: 100%;
            text-align: center;
        }

        .mainP{
            position: fixed;
            background-color: #16161686;
            height: 100vh;
            width: 100vw;
            left: 0;
            z-index: 5;

            .contentP{
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                width: 80%;
                margin-left: 10%;
                padding: 5%;
                margin-top: 30%;

                .heading{
                    color: #C2B825;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
                img{
                    width: 60%;
                    margin-left: 20%;
                }
                .p1{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .p2{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                button{
                    border-radius: 6px;
                    background-color: #F4E61B;
                    border: none;
                    padding: 2%;
                    width: 50%;
                    margin-left: 25%;
                }
            }
        }

        .progressTab{ 
            margin-left: 0%;
            margin-top: 1%;
            width:98%;
            height: 32%;
            padding: 1%;
            border: 1px solid #252525;
            border-radius: 35px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            // padding: 20px 21px;
            // padding: 2%;
            gap: 19px;
            background-color: #161616;
            z-index: 1;
        }
    
        .Chapters{
            margin-top: 1.5%;
            width:100%;
            height:95%;
            margin-right: 2%;
            // background-color: aliceblue;
            overflow-y: scroll;
            cursor: pointer;
    
            .chapter{
                color: aliceblue;
                border-radius: 25px;
                border: 3px solid #252525;
                height:20%;
                width: 95%;
                background-color: #161616;
                margin: 2%;
                margin-bottom: 5%;
                margin-left: 2.5%;
                padding-top: 2%;
                text-align: center;
                display: flex;
    
                .track1{
                    position: absolute;
                    z-index: -1;
                    margin-top: 15%;
                    margin-left: 10%;
                    width: 60%;
                }
                .track2{
                    position: absolute;
                    z-index: -1;
                    margin-top: 15%;
                    margin-left: 10%;
                    width: 60%;
                }
                .content{
                    // margin-right: 5%;
                    margin-left: 3%;
                    width:58%;
                    height: 100%;
                    text-align: left;
                    align-self: center;
                    padding-top: 2.5%;
                    .cName{
                        font-size: 21px;
                        font-weight: 700;
                    }
                    .cDesc{
                        font-size: 11px;
                        font-weight: 200;
                    }
                }
    
                .StartChap{
                    width:13%;
                    height: 90%;
                    background-color: rgba(5, 255, 0, 0.2);
                    border-radius: 35px;
                    border: 1px solid #252525;
                    border-radius: 26px;
                    color: aliceblue;
                    font-size: 18px;
                    transition: 0.5s ease;
                    margin-right: 3%;
                }
                .StartChap:hover{
                    cursor: pointer;
                    background-color: rgba(5, 255, 0, 0.4);

                }
                .ResumeChap{
                    background: rgba(252, 238, 33, 0.2);
                    width:12%;
                    height: 90%;
                    border-radius: 35px;
                    border: 1px solid #252525;
                    border-radius: 26px;
                    color: aliceblue;
                    font-size: 16px;
                    transition: 0.5s ease;
                    margin-right: 3%;
                }
                .ResumeChap:hover{
                    cursor: pointer;
                    background: rgba(252, 238, 33, 0.6);

                }
                .RestartChap{
                    background: #232323;
                    width:12%;
                    height: 90%;
                    border-radius: 35px;
                    border: 1px solid #252525;
                    border-radius: 26px;
                    color: aliceblue;
                    font-size: 18px;
                    transition: 0.5s ease;
                    margin-right: 3%;
                }
                .RestartChap:hover{
                    cursor: pointer;
                    background: #434343;

                }
    
            }
            .chapter:hover{
                background: #1E1E1E;
                opacity: 0.9;
            }
        }
        .Chapters::-webkit-scrollbar {
            width: 7px;
          }
           
        .Chapters::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background: #2B2B2B;
            border-radius: 11px;
          }
           
        .Chapters::-webkit-scrollbar-thumb {
            max-height: 50px;
            background: #979797;
            border-radius: 11px;
          }
    }
}



@media only screen and (min-width: 1024px) {
    .library{
        display: flex;
        flex-direction: row;
        padding-left: 100px;
        background-color: #1A1A1A;
        height: 100vh;
        color: white;
        width: 100%;
        position: fixed;
        overflow-y: hidden;
        overflow-x: hidden;


        .StayTuned{
            color: gold;
            width: 100%;
            text-align: center;
        }
    
        .Chapters{
            margin-top: 1.5%;
            width:70%;
            height:95%;
            margin-right: 2%;
            // background-color: aliceblue;
            overflow-y: scroll;
            cursor: pointer;
    
            .chapter{
                border-radius: 25px;
                border: 1.8px solid #252525;
                height: 200px;
                width: 90%;
                margin-left: 5%;
                background-color: #161616a3;
                margin: 2%;
                margin-bottom: 5%;
                margin-left: 5%;
                padding-top: 1.25%;
                text-align: center;
                display: flex;
                z-index: 2;

                img{
                    align-self: center;
                    width: 20%;
                    height: 90%;
                    margin-top: 2%;
                    margin-right: 2%;
                    margin-bottom: 5%;
                    margin-left: 2%;
                }
                .track1{
                    position: absolute;
                    z-index: -1;
                    margin-top: 340px;
                    margin-left: 10%;
                    width: 40%;
                }
                .track2{
                    position: absolute;
                    z-index: -1;
                    margin-top: 340px;
                    margin-left: 10%;
                    width: 40%;
                }
    
                .content{
                    // margin-right: 5%;
                    // margin-left: 20%;
                    width:58%;
                    height: fit-content;    
                    text-align: left;
                    margin-top: 3%;
                    .cName{
                        font-size: 3.5vh;
                    }
                    .cDesc{
                        font-size: 2vh;
                        font-weight: 300;
                    }
                }
    
                .StartChap{
                    width:6vw;
                    height: 90%;
                    background-color: rgba(5, 255, 0, 0.2);
                    border-radius: 35px;
                    border: 1px solid #252525;
                    border-radius: 26px;
                    color: aliceblue;
                    font-size: 18px;
                    transition: 0.5s ease;
                    margin-left: 3%;
                    margin-right: 3%;
                }
                .StartChap:hover{
                    cursor: pointer;
                    background-color: rgba(5, 255, 0, 0.4);

                }
                .ResumeChap{
                    background: rgba(252, 238, 33, 0.2);
                    width:7vw;
                    height: 90%;
                    border-radius: 35px;
                    border: 1px solid #252525;
                    border-radius: 26px;
                    color: aliceblue;
                    font-size: 18px;
                    transition: 0.5s ease;
                    margin-right: 3%;
                }
                .ResumeChap:hover{
                    cursor: pointer;
                    background: rgba(252, 238, 33, 0.6);

                }
                .RestartChap{
                    background: #232323;
                    width:7vw;
                    height: 90%;
                    border-radius: 35px;
                    border: 1px solid #252525;
                    border-radius: 26px;
                    color: aliceblue;
                    font-size: 18px;
                    transition: 0.5s ease;
                    margin-right: 3%;
                }
                .RestartChap:hover{
                    cursor: pointer;
                    background: #434343;

                }
    
            }
            .chapter:hover{
                background: #1e1e1eb3;
            }
        }
        .Chapters::-webkit-scrollbar {
            width: 7px;
          }
           
        .Chapters::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background: #2B2B2B;
            border-radius: 11px;
          }
           
        .Chapters::-webkit-scrollbar-thumb {
            max-height: 50px;
            background: #979797;
            border-radius: 11px;
          }
    }
}

