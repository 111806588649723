@media only screen and (max-width: 768px) {
    .how-left {
        display: flex;
        position: absolute;
        flex-direction: column-reverse;
        overflow-x: hidden;
        overflow-y: hidden;
        justify-content: center;
        align-items: center;
        height: 400px;
        width: 100vw;
        background-color: #000000;
        padding-top: 7%;
        .ncert-image {
            width: 142.81px;
            height: 179.33px;            
            transform: rotate(1.9deg);
        }
        .how-left__title {
            width: 225.34px;
           margin-bottom: 30px;
           font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 108.4%;
            text-align: center;
            color: #FCEE21;
        }
        .how-left__main_title {
            margin-bottom: 20px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 108.4%;
            color: #FFFFFF;
            display:flex;
        }
        .divider {
            opacity: 100%;
            width: 0px;
            border: 0.1px solid #262525;
        }
    }
} 

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
    .how-left {
        display: flex;
        position: absolute;
        flex-direction: column-reverse;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        background-color: #000000;
        padding-top: 7%;
        width: 100vw;

        .ncert-image {
            width: 35vw;
            height: 282.96px;            
            transform: rotate(1.9deg);
            margin-left: 0%;
        }
        .how-left__title {
            width: 300px;
           margin-bottom: 30px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 108.4%;
            text-align: center;
            color: #FCEE21;
        }
        .how-left__main_title {
            margin-bottom: 20px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 108.4%;
            color: #FFFFFF;
            display:flex;
        }
        .divider {
            opacity: 100%;
            width: 0px;
            border: 0.1px solid #262525;
        }
    }
    }

@media only screen and (min-width: 1024px) {
.how-left {
    background-color:#262525;
    overflow-y: hidden;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #171717;
    width: 100%;

    .StBtn{
        position: absolute;
        width: 20vw;
        height: 12vh;
        background-color: #FCEE21;
        border-radius: 10px;
        z-index: 8;
    }

    .ncert-image {
        min-width: 20vw;
        max-width: 20vw;
        height: 55vh;
        transform: rotate(1.9deg);
    }
    .how-left__title {
        position: absolute;
        bottom: 0;
        padding-bottom: 24px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 108.4%;
        /* or 17px */
        text-align: center;
        color: #3E3E3E;
    }
    .how-left__main_title {
       display: none;
    }
    .divider {
        opacity: 100%;
        width: 0px;
        border: 0.1px solid #262525;
    }
}
}