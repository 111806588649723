@media only screen and (min-width:300px) and (max-width: 380px) {
    .footer {
        display: flex;
        width: 100vw;
        padding: 7% 7% 0% 0%;

        flex-direction: column;

        gap: 10%;
        overflow: hidden;


        .col-links {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            // width: 100vw;
        }
        .col-1 {
            padding: 7% 7% 0% 10vw;
            flex: 4;
            width: 100%;
            font-weight: 400;
            font-size: 34px;
            line-height: 40px;
            color: #000000;
            .text{
                width: 75%;
                font-size: 30px;
            }
            .emailPara{
                padding-left: 3%;
                font-size: 2vh;
                width: max-content;
                a{
                    text-decoration: none;
                    color: #000000;
                    font-weight: 600;
                }
            }


            form {
                width: 100%;
                display: flex;
                padding-top: 10%;

                input {
                    width: 100%;
                    background: #EDEDED;
                    border-radius: 22px;
                    height: 56px;
                    // height: 170x;
                    stroke: none;
                    text-decoration: none;
                    border: none;
                    font-size: 15px;
                    padding-left: 7%;
                    padding-right: 20%;
                }
                input:focus{
                    border-color: initial;
                    outline: none;
                }
    
                .done {
                    position: absolute;
                    height: 56px;
                    width: 65px;
                    border-radius: 22px;
                    background: #FCEE21;
                    border: none;
                    right: 9%;
                    img {
                        width: 60%;
                        margin-left: 20%;
                        height: 90%;
                        margin-top: 5%;

                    }
                }

                // ::placeholder {
                //     font-size: 20px;
                //     padding-left: 44px;
                //     font-family: 'Inter';
                //     font-style: normal;
                //     font-weight: 300;
                //     font-size: 20px;
                //     padding-left: 40px;
                //     color: #000000;
                //     align-content: center;
                // }
            }

        }
        .col-2 {
            flex: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding-top: 60px;
            width: max-content;
            text-align: center;
            margin-left: 10%;

            .item {
                height: 100%;
                padding: 20px 0%;
                text-align: center;
                width: max-content;

                a {
                    padding: 8px 0%;
                    display: block;
                    direction: row;
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 20px;
                    width: max-content;
                    line-height: 24px;
                    color: #000000;
                    // background-color: #000000;
                    width: 100%;
                }
            }
        }
    }

    .bottom {
        font-weight: 600;
        font-size: 18px;
        line-height: 129.52%;
        /* or 23px */
        letter-spacing: 0.045em;
        color: #000000;
        padding: 7% 7% 0% 10vw;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;
        gap: 3%;
        width: 100%;
        text-align: center;
        margin-left: 5%;


        .bottom-row{
            margin-bottom: 4%;
            width: 100%;
            text-align: center;
        }

        .bottom-links{
            display: flex;
            margin-bottom: 4%;

            a {
                // padding: 0px 2%;
                display: flex;
                direction: column;
                font-weight: 500;
                font-size: 14px;
                text-decoration: none;
                width: max-content;
                color: #000000;
                // justify-content: center;
                margin-left: 4%;
            }
        }

    }

    .footer-logo {
        margin-top: 10%;
        margin-bottom: 10%;
        width: 50%;
        // position: absolute;
        // bottom: 45px;
        // right: 5%;
    }


}
@media only screen and (min-width:380px) and (max-width: 768px) {
    .footer {
        display: flex;
        width: 100vw;
        padding: 7% 7% 0% 0%;

        flex-direction: column;

        gap: 10%;
        overflow: hidden;



        .col-links {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            // width: 100vw;
        }
        .col-1 {
            padding: 7% 7% 0% 10vw;
            flex: 4;
            width: 100%;
            font-weight: 400;
            font-size: 34px;
            line-height: 40px;
            color: #000000;
            .text{
                width: 75%;
                font-size: 30px;
            }
            .emailPara{
                padding-left: 3%;
                font-size: 2vh;
                width: max-content;
                a{
                    text-decoration: none;
                    color: #000000;
                    font-weight: 600;
                }
            }


            form {
                width: 100%;
                display: flex;
                padding-top: 10%;

                input {
                    width: 100%;
                    background: #EDEDED;
                    border-radius: 22px;
                    height: 56px;
                    // height: 170x;
                    stroke: none;
                    text-decoration: none;
                    border: none;
                    font-size: 15px;
                    padding-left: 7%;
                    padding-right: 20%;
                }
                input:focus{
                    border-color: initial;
                    outline: none;
                }
    
                .done {
                    position: absolute;
                    height: 56px;
                    width: 65px;
                    border-radius: 22px;
                    background: #FCEE21;
                    border: none;
                    right: 9%;
                    img {
                        width: 25px;
                    }
                }

                // ::placeholder {
                //     font-size: 20px;
                //     padding-left: 44px;
                //     font-family: 'Inter';
                //     font-style: normal;
                //     font-weight: 300;
                //     font-size: 20px;
                //     padding-left: 40px;
                //     color: #000000;
                //     align-content: center;
                // }
            }

        }
        .col-2 {
            flex: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding-top: 60px;
            width: max-content;
            text-align: center;
            margin-left: 10%;

            .item {
                height: 100%;
                padding: 20px 0%;
                text-align: center;
                width: max-content;

                a {
                    padding: 8px 0%;
                    display: block;
                    direction: row;
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 20px;
                    width: max-content;
                    line-height: 24px;
                    color: #000000;
                    // background-color: #000000;
                    width: 100%;
                }
            }
        }
    }

    .bottom {
        font-weight: 600;
        font-size: 18px;
        line-height: 129.52%;
        /* or 23px */
        letter-spacing: 0.045em;
        color: #000000;
        padding: 7% 7% 0% 10vw;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;
        gap: 3%;
        width: 100%;
        text-align: center;
        margin-left: 5%;


        .bottom-row{
            margin-bottom: 4%;
            width: 100%;
            text-align: center;
        }

        .bottom-links{
            display: flex;
            margin-bottom: 4%;

            a {
                // padding: 0px 2%;
                display: flex;
                direction: column;
                font-weight: 500;
                font-size: 14px;
                text-decoration: none;
                width: max-content;
                color: #000000;
                // justify-content: center;
                margin-left: 7%;
            }
        }

    }

    .footer-logo {
        margin-top: 10%;
        margin-bottom: 10%;
        width: 50%;
        // position: absolute;
        // bottom: 45px;
        // right: 5%;
    }



}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .footer {
        display: flex;
        width: 100vw;
        padding: 7% 7% 0% 0%;

        flex-direction: column;

        gap: 10%;
        overflow: hidden;
        .col-links {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            // width: 100vw;
        }

        .col-1 {
            padding: 7% 7% 0% 10vw;
            flex: 4;
            min-width: 443px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 60px;
            line-height: 77px;
            color: #000000;
            .emailPara{
                padding-left: 3%;
                font-size: 2vh;
                width: max-content;
                a{
                    text-decoration: none;
                    color: #000000;
                    font-weight: 600;
                }
            }


            form {
                width: 100%;
                display: flex;
                padding-top: 10%;

                input {
                    min-width: 40vw;
                    max-width: 40vw;
                    background: #EDEDED;
                    border-radius: 32px;
                    stroke: none;
                    text-decoration: none;
                    border: none;
                    font-size: 24px;
                    padding-left: 7%;
                    padding-right: 28%;
                }
                input:focus{
                    border-color: initial;
                    outline: none;
                }
    
                .done {
                    position: absolute;
                    left:41%;
                    height: 80px;
                    width: 82px;
                    border-radius: 50%;
                    background: #FCEE21;
                    border: none;
                    img {
                        width: 25px;
                    }
                }
            }

        }

        .col-2 {
            flex: 0;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding-top: 40px;

            .item {
                height: 100%;
                padding: 20px 0%;

                a {
                    padding: 8px 0%;
                    display: block;
                    direction: row;
                    text-decoration: none;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: #000000;
                }
            }
        }
    }

    .bottom {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 129.52%;
        /* or 23px */
        letter-spacing: 0.045em;
        color: #000000;
        padding: 7% 7% 0% 10vw;
        padding-bottom: 5px;
        display: flex;
        flex-direction: row;
        gap: 3%;

        a {
            // padding: 0px 2%;
            display: block;
            direction: column;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 129.52%;
            /* or 23px */
            letter-spacing: 0.045em;
            text-decoration: none;
            color: #000000;
            justify-content: center;

        }

    }

    .footer-logo {
        padding: 20px 7% 10% 10vw;
        // position: absolute;
        // bottom: 45px;
        // right: 5%;
    }
}

@media only screen and (min-width: 1024px) {
    .footer {
        display: flex;
        width: 100vw;
        height: 70vh;
        padding: 7% 10% 7% 10%;
        direction: row;
        gap: 10%;
        z-index: 3;
        position: relative;

        .col-1 {
            flex: 4;
            font-weight: 400;
            font-size: 56px;
            line-height: 8vh;
            max-width: 400px;
            color: #000000;
            p{
                padding-left: 3%;
                font-size: 2vh;
                a{
                    text-decoration: none;
                    color: #000000;
                    font-weight: 600;
                }
            }


            form {
                width: 27vw;
                display: flex;
                height: 8vh;
                margin-top: 3vh;

                input {
                    width: 100%;
                    background: #EDEDED;
                    border-radius: 32px;
                    // height: 170x;
                    font-size: 2.5vh;
                    stroke: none;
                    text-decoration: none;
                    border: none;
                    // padding-top: 4%;
                    // padding-left: 5%;
                    justify-content: center;
                    padding-left: 5%;
                    padding-right: 15%;
                }
                input::placeholder{
                    font-size: 2.5vh;
                }

                input:focus{
                    border-color: initial;
                    outline: none;
                    // padding-bottom: 10%;
                }
                .done {
                    position: relative;
                    height: 100%;
                    width: 5vw;
                    border-radius: 50%;
                    background: #FCEE21;
                    border: none;
                    right: 13%;
                    padding: 0vh;
                    font-size: 3vh;
                    font-weight: 600;
                    justify-content: center;
                    text-align: center;
                }
            }


        }

        .col-2 {
            flex: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding-top: 15px;
            // margin-right: 1%;

            .item {
                height: 100%;
                padding: 20px 0%;
                width: max-content;
                a {
                    padding: 8px 0%;
                    display: block;
                    direction: row;
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: #000000;
                }
            }
        }
    }

    .bottom {
        font-weight: 600;
        font-size: 18px;
        // line-height: 129.52%;
        /* or 23px */
        letter-spacing: 0.045em;
        color: #000000;
        padding: 0% 7%;
        padding-bottom: 45px;
        display: flex;
        flex-direction: row;
        gap: 2%;
        width: 100%;

        .bottom-links{
            display: flex;
            width: 60%;

            a{
                font-weight: 500;
                font-size: 18px;
                /* or 23px */
                letter-spacing: 0.045em;
                text-decoration: none;
                color: #000000;
                justify-content: center;
                margin-left: 2.2%;
                margin-right: 2.2%;
            }
        }
    }

    .footer-logo {
        position: absolute;
        bottom: 45px;
        right: 5%;
    }
}