// For phones
@media only screen and (max-width: 390px) and (max-height: 745px){
    .home{
        height: 95vh;
        width: 100vw;
        display: flex;
        flex-direction: column;

        .homeLeft{
            height: 35%;
            width: 100%;
            display: flex;
            flex-direction: column;
            // background-color: #FCEE21;

            .lap{
                height: 80%;
                width: 80%;
                margin-left: 10%;
                margin-top: 10%;
            }
            .HL-lower{
                display: flex;
                width: 100%;
                // background-color: #000000;
                height: 30%;

                .mob{
                    margin-left: 10%;
                }
                .ex{
                    width: 30%;
                    height: 20%;
                    margin-top: 40%;
                    text-align: center;
                    margin-left: 5%;
                    position: fixed;
                }
            }
        }
        .homeRight{
            width: 100%;
            .title{
                width: 80%;
                margin-left: 10%;
                height: 10%;
                margin-top: 2%;

            }
            .desc{
                width: 80%;
                margin-left: 10%;
                margin-top: 4%;
                height: 8%;

            }
            .bar{
                width: 30%;
                background-color: #FCEE21;
                height: 0.5%;
                margin-left: 35%;
                margin-top:1%;
            }
            .desc2{
                font-size: 1.5vh;
                margin-top: 2%;
                width: 100%;
                text-align: center;
            }
            .mob{
                width: 60%;
                margin-top: 5%;
                margin-left: 20%;

            }

            .btsDiv{
                width: 100%;
                height: 25%;
                .grpImg{
                    height: 50%;
                    width: 70%;
                    margin-left: 15%;
                }
                .btn{
                    width: 80%;
                    margin-left: 10%;
                    background-color: #FCEE21;
                    border-radius: 10px;
                    margin-top: 5%;
                    padding: 2%;

                    .lnk{
                        text-decoration: none;
                        color: #000000;
                        font-weight: 600;
                    }
                }
                    .more{
                        width: 100%;
                        text-align: center;
                        color:#000000;
                        font-size: 1.6vh;
                        cursor: pointer;
                        margin-top: 2%;
                        text-decoration: underline;
                    }
                
            }
            .home-bttm{
                width: 80%;
                margin-left: 10%;
                text-align: center;
                margin-top: 0.5vh;
            }
        }
    }
}

@media only screen and (min-width: 390px) and (max-width: 768px) {
    .home{
        height: 105vh;
        width: 100vw;
        display: flex;
        flex-direction: column;

        .homeLeft{
            height: 30%;
            width: 100%;
            display: flex;
            flex-direction: column;
            // background-color: #FCEE21;

            .lap{
                height: 80%;
                width: 80%;
                margin-left: 10%;
                margin-top: 10%;
            }
            .HL-lower{
                display: flex;
                width: 100%;
                // background-color: #000000;
                height: 30%;

                .mob{
                    margin-left: 10%;
                }
                .ex{
                    width: 30%;
                    height: 20%;
                    margin-top: 40%;
                    text-align: center;
                    margin-left: 5%;
                    position: fixed;
                }
            }
        }
        .homeRight{
            width: 100%;
            .title{
                width: 80%;
                margin-left: 10%;
                height: 10%;
                margin-top: 2%;

            }
            .desc{
                width: 80%;
                margin-left: 10%;
                margin-top: 6%;
                height: 8%;

            }
            .bar{
                width: 30%;
                background-color: #FCEE21;
                height: 0.5%;
                margin-left: 35%;
                margin-top:3%;
            }
            .desc2{
                font-size: 1.5vh;
                margin-top: 4%;
                width: 100%;
                text-align: center;
            }
            .mob{
                width: 60%;
                margin-top: 5%;
                margin-left: 20%;

            }

            .btsDiv{
                width: 100%;
                height: 25%;
                .grpImg{
                    height: 50%;
                    width: 70%;
                    margin-left: 15%;
                }
                .btn{
                    width: 80%;
                    margin-left: 10%;
                    background-color: #FCEE21;
                    border-radius: 10px;
                    margin-top: 5%;
                    padding: 2%;

                    .lnk{
                        text-decoration: none;
                        color: #000000;
                        font-weight: 600;
                    }
                }
                    .more{
                        width: 100%;
                        text-align: center;
                        color:#000000;
                        font-size: 1.6vh;
                        cursor: pointer;
                        margin-top: 2%;
                        text-decoration: underline;
                    }
                
            }

            .home-bttm{
                width: 80%;
                margin-left: 10%;
                text-align: center;
                margin-top: 0.5vh;
            }
        }
    }
}


// For tablets
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .home{
        height: 88vh;
        width: 100vw;
        display: flex;

        .homeLeft{
            width: 50%;
            display: flex;
            flex-direction: column;

            .lap{
                height: 50%;
                width: 80%;
                margin-left: 10%;
                margin-top: 10%;
            }
            .HL-lower{
                display: flex;
                width: 100%;
                // background-color: #000000;
                height: 30%;

                .mob{
                    margin-left: 10%;
                }
                .ex{
                    width: 30%;
                    height: 20%;
                    margin-top: 40%;
                    text-align: center;
                    margin-left: 5%;
                    position: fixed;
                }
            }
        }
        .homeRight{
            width: 50%;
            .title{
                width: 80%;
                margin-left: 5%;
                height: 15%;
                margin-top: 10%;

            }
            .desc{
                width: 80%;
                margin-left: -6%;
                margin-top: 4%;
                height: 8%;

            }
            .bar{
                width: 25%;
                background-color: #FCEE21;
                height: 0.5%;
                margin-left: 10%;
                margin-top:3%;
            }
            .desc2{
                font-size: 1.5vh;
                margin-left: 10%;
                margin-top: 2%;
            }

            .btsDiv{
                width: 100%;
                height: 25%;
                .grpImg{
                    height: 60%;
                    width: 70%;
                    margin-left: 15%;
                }
                .btn{
                    width: 80%;
                    margin-left: 10%;
                    background-color: #FCEE21;
                    border-radius: 10px;
                    margin-top: 5%;
                    padding: 2%;

                    .lnk{
                        text-decoration: none;
                        color: #000000;
                        font-weight: 600;
                    }
                }
                    .more{
                        width: 100%;
                        text-align: center;
                        color:#000000;
                        font-size: 1.6vh;
                        cursor: pointer;
                        margin-top: 2%;
                        text-decoration: underline;
                    }
                
            }
        }
    }
}




// For Desktops
@media only screen and (min-width: 1024px) {
    .home{
        height: 88vh;
        width: 100vw;
        display: flex;

        .homeLeft{
            width: 50%;
            display: flex;
            flex-direction: column;

            .lap{
                height: 55%;
                width: 80%;
                margin-left: 10%;
                margin-top: 10%;
            }
            .HL-lower{
                display: flex;
                width: 100%;
                // background-color: #000000;
                height: 30%;

                .mob{
                    margin-left: 10%;
                }
                .ex{
                    width: 30%;
                    height: 20%;
                    margin-top: 40%;
                    text-align: center;
                    margin-left: 5%;
                    position: fixed;
                }
            }
        }
        .homeRight{
            width: 50%;
            .title{
                width: 80%;
                margin-left: 2.5vw;
                height: 15%;
                margin-top: 10%;

            }
            .desc{
                width: 80%;
                margin-left: -3vw;
                margin-top: 4%;
                height: 8%;

            }
            .bar{
                width: 25%;
                background-color: #FCEE21;
                height: 0.5%;
                margin-left: 5vw;
                margin-top:3%;
            }
            .desc2{
                font-size: 1.5vh;
                margin-left: 5vw;
                margin-top: 2%;
            }

            .btsDiv{
                width: 100%;
                height: 25%;
                .grpImg{
                    height: 80%;
                    width: 70%;
                    margin-left: 15%;
                }
                .btn{
                    width: 80%;
                    margin-left: 10%;
                    background-color: #FCEE21;
                    border-radius: 10px;
                    margin-top: 5%;
                    padding: 2%;

                    .lnk{
                        text-decoration: none;
                        color: #000000;
                        font-weight: 600;
                    }
                }
                    .more{
                        width: 100%;
                        text-align: center;
                        color:#000000;
                        font-size: 1.6vh;
                        cursor: pointer;
                        margin-top: 2%;
                        text-decoration: underline;
                    }
                
            }

            .home-bttm{
                width: 15vw;
                text-align: center;
                position: absolute;
                left: 28vw;
                top:77vh;
                font-weight: 600;
            }
        }
    }
}
