// BlogAppBar.scss

.app-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  color: #FCEE21;
  background-color: #0A0A0A;
  padding-top: 2.5%;
  padding-bottom: 1.8%;
  @media screen and (max-width: 768px) {
    padding-top: 4%;
    padding-bottom: 3%;
  }
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
  margin-left: 4.5%;
  height: 6vh;
  width: 3vw;
  margin-top: -1vh;
  @media screen and (max-width: 768px) {
    height: 36px;
    width: 36px;
    margin-right: 2%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

// .back-button {
//   font-size: 20px;
//   text-decoration: none;
//   color: #FCEE21;
//   transition: transform 0.2s ease-in-out;
//   padding-left: 2%;
// }

.back-button:hover {
  transform: translateX(-5px);
}

.page-title {
  font-size: 26px;
  font-weight: 400;
  // color: #333;
  padding-left: 2%;
}

.spacer {
  flex: 1;
}