// BlogList.scss

.blog-page {
  // padding: 2%;

  h3 {
    padding: 20px 0px 0 4.5%;
    font-size: 1.8rem;
  }
}

.tags {
  display: flex;
  gap: 10px;
  margin-top: 10px;

  .tag {
    border: 1px solid #9a9a9a;
    border-radius: 20px;
    padding: 1px 8px;
  }
}

.blogs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // justify-content: space-between;
  // align-items: flex-start;
  gap: 20px;
  padding: 0px 5%;
  margin-top: 6%;
  height: 120vh;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .latest-blog {
    grid-column: 1/2;
    padding: 0px 10px;
    height: 120vh;
    @media screen and (max-width: 768px) {
      // display: flex;
      // flex-direction: column;
      height: auto;
    }

    // &:hover {
    //   box-shadow: 0 4px 8px rgba(102, 102, 102, 0.3);
    //   transform: scale(1.01);
    //   transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    // }


    img {
      width: 100%;
      height: auto;
    }

    .blog-item-texts {
      padding: 10px 0;

      a {
        font-size: 24px;
        font-weight: bold;
        color: #333;
        text-decoration: none;

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

    }

    .blog-time {
      font-size: 14px;
      color: #666;
    }
  }


  .old-blogs {
    overflow-y: auto;
    height: 115vh;
    grid-column: 2/3;
    // margin: 10px 0px;
    display: grid;
    // flex-direction: column;
    gap: 30px;
    grid-template-rows: repeat(1fr);
    position: relative;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      // overflow-y: auto;
      height: auto;
    }

    .blog-item {
      display: flex;
      // margin-bottom: 20px;
      align-items: flex-start;
      padding: 0px 10px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }


      img {
        width: 30%;
        height: auto;
        margin-right: 12px;
        border-radius: 10px;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .blog-item-texts {
        flex: 1;
        padding: 5px 0px;

        a {
          font-weight: bold;
          font-size: 19px;
          color: #333;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .redirect-button {
          font-size: 20px;
          text-decoration: none;
          color: #333;
          transition: transform 0.2s ease-in-out;
          padding-left: 2%;
        }

        .blog-time {
          font-size: 12px;
          color: #666;
        }
      }
    }
  }
}

.read-more {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px;

  button {
    padding: 10px 40px;
    border-radius: 6px;
    background: #0a0a0a;
    font-weight: 500;
    font-size: 600;
    border: none;
    color: #FCEE21;

    &:hover {
      color: rgb(129, 205, 198);
    }
  }
}