.tabs {
    display: flex;
    height: 45px;
    width: 28vw;
    border: none;
    background-color: #1C1C1C;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    justify-content: center;
    align-items: center;
    text-align: center;
    direction: row;

    letter-spacing: 0.75px;
    img{
        padding-right: 6px;
        padding-bottom: 1px;
    }

}

.tabs .inactive {

    color: #DEDEDE;
    border-bottom: 2px solid #DEDEDE;
   
}

.tabs .active {
    color: #FCEE21;
    border-bottom: 2px solid #FCEE21;
}