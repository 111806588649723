.sidebar {
    z-index: 2;
    position: fixed;
    left: 0;
    background: #1C1C1C;
    border: 1px solid #252525;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 75px;

    a.logo {
        display: block;
        width: 35px;
        padding-top: 3vh;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }


    .nav-button {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 133.52%;
        /* or 13px */


        color: #B1B1B1;


        /* Inside auto layout */
        display: flex;
        text-decoration: none;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        p{
            width: 110%;
            text-align: center;
            padding-left: 3%;
            padding-right: 5%;
        }

        img{
            width: 40%;
            margin-left: 30%;
            margin-bottom: 2%;
            // filter: brightness(1.2) contrast(1.2) hue-rotate(40deg);
            // filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
            // filter: brightness(1.2) contrast(1.2) hue-rotate(0deg);
            // filter: sepia(20) saturate(45) hue-rotate(0deg);
        }
    }

    .logout-button {
        color: #840B0B;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 133.52%;
        /* or 13px */



        /* Inside auto layout */
        display: flex;
        text-decoration: none;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        p{
            width: 100%;
            text-align: center;
        }

        img{
            width: 50%;
            margin-left: 28%;
        }
    }


}


// Navbar for mobile devices below

.navBar{
    width:100%;
    background-color: #1c1c1c;
    height: 9%;
    position: absolute;
    left: 0%;
    z-index: 3;
    .popUpWindow{
        position: fixed;
        width:100%;
        height: 100%;
        z-index: 2;
        background-color: #202020;

        img{
            width: 15%;
            height: 15%;
            margin-left: 2%;
        }
        .more{
            position: absolute;
            z-index: 3;
            top:1%;
            right: 5%;
            width: fit-content;
            font-size: 25px;
            color:#FCEE21;
            background-color: transparent;
            border: none;

        }
        .links{
            width: 70%;
            position: fixed;
            top: 0%;
            left:30%;
            height: 100%;
            background-color: #252525;
            padding: 4%;
            .nav-button {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 133.52%;
                display: flex;
                /* or 13px */
                // border: 2px solid #555252;
                padding: 2%;
                border: none;

        
                color: #B1B1B1;
        
        
                /* Inside auto layout */
                display: flex;
                text-decoration: none;
                flex-direction: row;
                align-content: center;
                p{
                    margin-top: 2%;
                }
        
                img{
                    margin-right: 5%;
                    height: 10%;
                    width: 10%;
        
                }
            }
        
            .logout-button {
                width: 100%;
                color: #840B0B;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 133.52%;
                margin-bottom: 1.5%;
                padding: 2%;
                border: none;

                /* or 13px */
        
        
        
                /* Inside auto layout */
                display: flex;
                text-decoration: none;
                flex-direction: row;
                align-content: center;
                position: absolute;
                bottom:0%;

                p{
                    margin-top: 2%;
                }
        
                img{
                    margin-right: 5%;
                    // filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
                    // filter: brightness(1.2) contrast(1.2) hue-rotate(40deg);
                    height: 10%;
                    width: 10%;
        
                }
            }
        }
    }

    .main{
        display: flex;
        align-content: center;
        img{
            top:15%;
            left:4%;
            position: absolute;
        }

        .more{
            position: absolute;
            top:15%;
            right: 4%;
            width: fit-content;
            font-size: 25px;
            color: #B1B1B1;
            background-color: transparent;
            border-radius: 7px;
            border: none;

        }
    }
}