

html {
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

body {
    overflow-y: hidden;
    position: relative;
    overflow-x: hidden;
}

html,
body {
    margin: 0;
}

.lastContainer {
    display: flex;
    height: 100vh;
    background: black;
}
@media only screen and (min-width: 300px) and (max-width: 380px) {
    .homepage{
        .rocket{
            position: absolute;
            top: 110vh;
            left: 60vw;
            z-index: 3;
            img{
                width: 14vw;
            }
        }
    }
    .how {
        display: flex;
        height: 100%;
        width: 100%;
        background-color: #000000;
        // background-color: #171717;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .how__left {
          
            width: 100vw;

            
        }
        .container{
            height: 100%;
            // width: 100%;
            display: flex;
            flex-direction: column;

            margin-top: 380px;
            margin-bottom: 10vh;
            
            
            .how__right {
                height: 50%;
                display: flex;
                flex-direction: column;
                width: 100%;
                text-align: center;
                padding-left: 7%;

                .StBtn{
                    width: 65vw;
                    margin-left: 8vw;
                    height: 6vh;
                    border: none;
        
                    background-color: #FCEE21;
                    border-radius: 10px;
                    z-index: 8;
                    margin-top: 5vh;
                    .link{
                        text-decoration: none;
                        color:black;
                    }
                }

            }
        }
    }

}
@media only screen and (min-width: 380px) and (max-width: 410px) {
    .homepage{
        .rocket{
            position: absolute;
            top: 113vh;
            left: 60vw;
            z-index: 3;
            img{
                width: 16vw;
            }
        }
    }
    .how {
        display: flex;
        height: 100%;
        width: 100%;
        background-color: #000000;
        // background-color: #171717;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .how__left {
          
            width: 100vw;


            
        }
        .container{
            height: 100%;
            // width: 100%;
            display: flex;
            flex-direction: column;

            margin-top: 380px;
            margin-bottom: 10vh;
            
            
            .how__right {
                height: 50%;
                display: flex;
                flex-direction: column;
                width: 100%;
                text-align: center;
                padding-left: 7%;

                .StBtn{
                    width: 65vw;
                    margin-left: 8vw;
                    height: 6vh;
                    border: none;
        
                    background-color: #FCEE21;
                    border-radius: 10px;
                    z-index: 8;
                    margin-top: 5vh;
                    .link{
                        text-decoration: none;
                        color:black;
                    }
                }

            }
        }
    }

}



@media only screen and (min-width: 410px) and (max-width: 768px) {
    .homepage{
        .rocket{
            position: absolute;
            top: 113vh;
            left: 60vw;
            z-index: 3;
            img{
                width: 16vw;
            }
        }
    }
    .how {
        display: flex;
        height: 100%;
        width: 100%;
        background-color: #000000;
        // background-color: #171717;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .how__left {
          
            width: 100vw;

            

            
        }
        .container{
            height: 100%;
            // width: 100%;
            display: flex;
            flex-direction: column;

            margin-top: 380px;
            margin-bottom: 10vh;
            
            
            .how__right {
                height: 50%;
                display: flex;
                flex-direction: column;
                width: 100%;
                text-align: center;
                padding-left: 7%;
                
                .StBtn{
                    width: 65vw;
                    margin-left: 8vw;
                    height: 6vh;
                    border: none;
        
                    background-color: #FCEE21;
                    border-radius: 10px;
                    z-index: 8;
                    margin-top: 5vh;
                    .link{
                        text-decoration: none;
                        color:black;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px)  {
    .homepage{
        .slider{
            height: fit-content;
            padding-bottom: 0%;
            z-index: 5;
        }
        .rocket{
            width: 20%;
            position: absolute;
            top: 106vh;
            left: 65vw;
            z-index: 3;
        }
    }
    .how {
        display: flex;
        height: 100%;
        width: 100%;
        background-color: #000000;
        // background-color: #171717;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .how__left {
          
            width: 100vw;

            
        }
        .container{
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 500px;
            margin-bottom: 10vh;
            
            
            .how__right {
                margin-top: 5vh;
                display: flex;
                flex-direction: row;
                width: 100%;
            }
        }
    }
}
@media only screen and (min-width: 1024px) {
    .how {
        display: flex;
        height: max-content;
        min-height: 400vh;
        background-color: #171717;

        .StBtn{
            position: absolute;
            width: 20vw;
            height: 8vh;
            border: none;

            background-color: #FCEE21;
            border-radius: 10px;
            z-index: 8;
            top:83vh;
            .link{
                text-decoration: none;
                color:black;
            }
        }

        .how__left{
            background-color:#262525;
            overflow-y: hidden;
            display: flex;
            position: absolute;
            z-index: 5;
            justify-content: center;
            align-items: center;
            height: 100vh;
            background-color: #171717;
            width: 45vw;

            .StBtn{
                position: absolute;
                width: 20vw;
                height: 8vh;
                border: none;

                background-color: #FCEE21;
                border-radius: 10px;
                z-index: 8;
                top:83vh;
                .link{
                    text-decoration: none;
                    color:black;
                }
            }

            .ncert-image {
                min-width: 20vw;
                max-width: 20vw;
                margin-top: -13vh;
                height: 55vh;
                transform: rotate(1.9deg);
            }
            .how-left__title {
                position: absolute;
                bottom: 0;
                padding-bottom: 24px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 108.4%;
                /* or 17px */
                text-align: center;
                color: #3E3E3E;
            }
            .how-left__main_title {
               display: none;
            }
            .divider {
                opacity: 100%;
                width: 0px;
                border: 0.1px solid #262525;
            }
        }
        
        .how__right {
            width: 55vw;
            position: absolute;
            left: 45vw;
            z-index: 4;
        }
    }
}


.mentalHealth {
    width: 100vw;
    overflow: hidden;

    .mental-health__title {
        position: relative;
        // opacity: 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 58px;
        line-height: 126.4%;
        margin-left: 10vw;
        color: #000000;
        // display: hidden;
    }

    .bottom-text {
        position: absolute;
        bottom: -25vh;
        width: 60vw;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        width: 80vw;

        color: #000000;
    }
}


.rocket{
    position: absolute;
    top: 30vh;
    left: 26vw;
    z-index: 3;
}

