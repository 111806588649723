.primary-button {
    width: 10vw;
    height: 7vh;
    display: flex;
    font-family: 'Manrope';
    font-style: normal;
    justify-content: center;
    align-items: center;
    background: #FCEE21;
    border-radius: 10px;
    font-weight: 300;
    font-size: 1.7vh;
    line-height: 17px;
    letter-spacing: 0.115em;
    text-decoration: none;
    transition: background-color 0.4s ease-in-out;
    &:hover {
        background-color: white;
    }
    border: none;
    color: #000000;
}
@media only screen and (max-width: 768px){
    .primary-button{
        min-width: 37vw;
        width: max-content;
        height: 7vh;
        font-size: 13px;
        font-weight: 500;
    }
}

@media only screen and (min-width: 768px) and (max-width:1024px){
    .primary-button{
        width: 23vw;
        height: 72px;
        font-size: 1.4vh;
    }
}