.center{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0a0a0a;
}

.loader {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    --book-color: #fcee21;
    --book-cover-color: white;
  }
  .book {
    width: 150px;
    height: 13px;
    background-color: var(--book-color);
    border-bottom: 2px solid var(--book-cover-color);
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
  }
  .page {
    width: 50%;
    height: 2px;
    background-color: var(--book-color);
    animation: paging 0.8s ease-out infinite;
    transform-origin: left;
  }
  .page2 {
    width: 50%;
    height: 2px;
    background-color: var(--book-color);
    animation: paging 0.9s ease-out infinite;
    transform-origin: left;
    position: absolute;
  }
  @keyframes paging {
    10% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(-180deg);
    }
  }
  