@media only screen and (max-width: 768px) {
    .howRight {
        display: flex;
        flex-direction: column;
        background-color: #000000;
        height: max-content;
        justify-content: center;
        width: 80vw;
        margin-top: 40px;
        .row{
            display: flex;
            flex-direction: row;
            height: 50%;

            .number {
                font-family: 'Montserrat';
                height: min-content;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                // line-height: 135.9%;
                // text-align: center;
                // padding: 0vh 0 0 6vw;
                // letter-spacing: 0.085em;
                color: #FCEE21;
                display: flex;
                width: 100%;
                justify-content: center;

                .circle {
                    margin-top: 6%;
                    margin-left: 1%;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    display: inline-block;
                }
                .heading {
                    display: flex;
                    margin-left: 2%;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    padding-top: 3px;
                    color: #FCEE21;
                    // text-align: center;
                }
                
            }
        }
        .paratext {
            
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            // width: 281px;
            padding: auto;
            font-size: 13px;
            line-height: 135.9%;
            letter-spacing: 0.085em;
            text-align: center;
            color: #AFAFAF;
        }
        .column{
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            .images {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 22px;
                margin-bottom: 22px;
                width: 290px;
                height: 145px;

                .gif{
                    height: 100%;
                    width: 100%;
                }
            }
        }
    
        .divider {
            opacity: 100%;
            width: 0px;
            border: 0.1px solid #262525;
        }
    }
    }

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
.howRight {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    height: min-content;
    justify-content: center;
    width: 45vw;
    .row{
        display: flex;
        flex-direction: row;
        height: 50%;
        min-width: 80%;
        width: max-content;
        justify-content: center;
        margin-left: 1.5%;

        .number {
            font-family: 'Montserrat';
            height: min-content;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 135.9%;
            text-align: start;
            padding: 0vh 0 0 6vw;
            letter-spacing: 0.085em;
            color: #FCEE21;
            display: flex;
            justify-content: center;
            .heading {
                display: flex;
                height: max-content;
                width: max-content;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 135.9%;
                text-align: center;
                letter-spacing: 0.025em;
                padding-top: 4px;
                color: #FCEE21;
                padding-left: 2%;
            }
            .circle {
                margin-top: 5%;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                display: inline-block;
            }
        }
    }
    .paratext {
        
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        // width: 281px;
        font-size: 13px;
        line-height: 135.9%;
        letter-spacing: 0.085em;
        text-align: center;
        color: #AFAFAF;
        padding: 3%;
    }
    .column{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        .images {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 22px;
            margin-bottom: 22px;
            width: 200px;
            height: 100px;

            .gif{
                height: 100%;
                width: 100%;
            }
        }
    }

    .divider {
        opacity: 100%;
        width: 0px;
        border: 0.1px solid #262525;
    }
}
}

@media only screen and (min-width: 1024px) {
.howRight {
    display: flex;
    flex-direction: column;
    background-color: #0E0E0E;
    min-height: 100vh;
    height: max-content;
    .circle {
        height: 15.94px;
        width: 15.74px;
        border-radius: 50%;
        display: inline-block;
    }
    .number {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 135.9%;
        /* or 65px */
        text-align: start;
        padding: 8vh 0 0 6vw;
        letter-spacing: 0.085em;
        color: #FCEE21;
    }
    .heading {
        display: flex;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 4.5vh;
        line-height: 135.9%;
        /* or 43px */
        padding-bottom: 10vh;
        text-align: center;
        letter-spacing: 0.025em;
        color: #FCEE21;
    }
    .paratext {
        position:  relative;
        bottom: -5vh;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 2.3vh;
        line-height: 150%;
        /* or 30px */
        text-align: center;
        padding: 0 4vw 0 4vw;
        letter-spacing: 0.085em;
        color: #AFAFAF;
    }
    .column{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .images {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 400px;
            height: 280px;

            .gif{
                height: 100%;
                width: 100%;
            }
        }
    }

    .divider {
        opacity: 100%;
        width: 0px;
        border: 0.1px solid #262525;
    }
}
}