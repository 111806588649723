.text-button {
    width: 9vw;
    height: 7vh;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 1.7vh;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    letter-spacing: 0.115em;
    padding-left: 5%;
    padding-right: 5%;
    color: black;
    background-color: transparent;
    border: none;
    text-decoration:underline;
    
}
@media only screen and (max-width: 768px){
    .text-button{
        width: 36vw;
        height: 7vh;
        font-size: 13px;
        font-weight: 500;
        
    }
}
@media only screen and (min-width: 768px) and (max-width:1024px){
    .text-button{
        width: 250px;
        height: 72px;
        font-size: 18px;
    }
}