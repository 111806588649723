@media only screen and (max-width: 768px) {
    .login {
        display: flex;
        flex-direction: column;
        .learnwithone{
            position: absolute;
            left: 10%;
            top:7%;
            .Link{
                background-color: #FCEE21;
                color: #000000;
                border-radius: 15px;
                padding: 3%;
                font-size: 12px;
                text-decoration: none;
                font-weight: 500;
            }
            .p1{
                margin-top: 8%;
                border-radius: 25px;
                padding: 3%;
                padding-left: 5%;
                padding-right: 5%;
                font-size: 14px;
                color: #D9D9D9;
                background-color: #00000088;
                width: max-content;
            }
            .p2{
                margin-top: 24%;
                border-radius: 25px;
                padding: 3%;
                padding-left: 5%;
                padding-right: 5%;
                font-size: 14px;
                color: #D9D9D9;
                font-weight: 500;

                width: max-content;
            }

        }
        .left-content {
            order: 2;
            flex: 5;
            margin: 0 6vw;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            text-align: center;
            padding-bottom: 12%;

            .selector{
                display: flex;
                height: 6vh;
                width: 100%;
                background-color: #DFDEDE;
                margin-top: 4vh;
                border-radius: 50px;
                margin-bottom: 3vh;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                button{
                    width: 50%;
                    height: 100%;
                    font-size: 2.3vh;
                    border-radius: 50px;
                    border: none;
                    background-color: #DFDEDE;
                }
            }

            .logo {
                position: relative;
                top: 10%;
                height: 36.94px;
            }
            .title {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 133.52%;
                letter-spacing: 0.03em;
                color: #000000;
                margin-top: 10%;
            }
            .continue-with-google {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background: #F0F0F0;
                border: 1px solid #343434;
                margin-top: 12%;
                border-radius: 16px;
                width: 100%;
                height: 45px;
                gap: 16px;
                cursor: pointer;
                img
                {
                    height: 15px;
                    width: 15px;
                }
            }
            .or {
                width: 100%;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 700;
                text-align: center;
                font-size: 16px;
                margin: 4% 0;
                line-height: 22px;
                /* identical to box height */
                color: #000000;
            }
            form {
                width: 100%;
                 ::placeholder {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* identical to box height */
                    color: #353535;
                }
                .name-section {
                    display: flex;
                    gap: 10%;
                    .input-feild {
                        width: 45%;
                    }
                }
                .input-feild {
                    flex: 1;
                    background: #F0F0F0;
                    border: 1px solid #D9D9D9;
                    border-radius: 16px;
                    height: 47px;
                    width: 100%;
                    margin: 16px 0;
                    padding: 8px;
                    font-size: 16px;
                    color: #343434;
                    text-indent: 20px;
                    box-sizing: border-box;
                    &:focus {
                        padding: 0.5rem;
                        border: double 1px transparent;
                        border-radius: 16px;
                        background-image: linear-gradient(#F0F0F0, #F0F0F0), linear-gradient(91.81deg, #FF174F 3.9%, #9999FF 98.88%);
                        background-origin: border-box;
                        background-clip: padding-box, border-box;
                        outline: none;
                    }
                }
                .login-button {
                    display: flex;
                    border: none;
                    background: #FCEE21;
                    border-radius: 12px;
                    width: 100%;
                    height: 48px;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Manrope';
                    margin: 10% 0 18px 0;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    color: black;
                }
                .forgot-button{
                    display: flex;
                    border: none;
                    background: #FCEE21;
                    border-radius: 12px;
                    width: 100%;
                    height: 48px;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Manrope';
                    margin: 10% 0 18px 0;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    color: black;
                }
                .password-section {
                    position: relative;
                    .password-visiblity {
                        position: 'absolute';
                        top: 0;
                        right: 0;
                    }
                }
               
            }
            .row-container
            {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                text-align: center;
                
                .dont-have-account{
                    justify-content: center;
                    margin-top: 10px;
                    margin-bottom: 60px;
                }
            }
            .center{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                .dont-have-an-account {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 133.52%;
                    /* or 21px */
        
                    letter-spacing: 0.03em;
        
                    color: #000000;
                    .signup-text-button{
                        color: #FFA854;
                        padding-left: 8px;
        
                    }
                }
            }
            
        }
        .right-content {
            order: 1;
            flex: 7;
            padding: 16px;
            .login-display {
                object-fit: cover;
                border-radius: 25px;
                height: 70vw;
                width: 100%;
            }
        }
    }
  }

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
    
    .login {
        display: flex;
        flex-direction: column;
        margin-top: 8%;

        .learnwithone{
            position: absolute;
            left: 10%;
            top:7%;
            .Link{
                background-color: #FCEE21;
                color: #000000;
                border-radius: 25px;
                padding: 4%;
                font-size: 15px;
                text-decoration: none;
                font-weight: 500;
            }
            .p1{
                margin-top: 8%;
                border-radius: 25px;
                padding: 3%;
                padding-left: 5%;
                padding-right: 5%;
                font-size: 25px;
                color: #D9D9D9;
                background-color: #00000088;
                width: max-content;
            }
            .p2{
                margin-top: 15%;
                text-align: center;
                border-radius: 25px;
                padding: 3%;
                padding-left: 5%;
                padding-right: 5%;
                font-size: 25px;
                color: #D9D9D9;
                font-weight: 500;

                width: max-content;
            }

        }

        .left-content {
            // position: absolute;
            // top:60%;
            // margin: 0 6vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-top: 60%;
            .row{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: start;
                justify-content: start;
        
                .logo {
                    // margin-top: 50%;
                    height: 36.94px;
                    position: absolute;
                    top:48%;
                    left:5%;
                }
                
            
        }
            .title {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 133.52%;
                    letter-spacing: 0.03em;
                    color: #000000;
                    margin-top: 5%;
                    text-align: start;
                    width: 70%;
                }
                .selector{
                    display: flex;
                    height: 8vh;
                    width: 100%;
                    background-color: #DFDEDE;
                    margin-top: 4vh;
                    border-radius: 50px;
                    margin-bottom: 3vh;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    button{
                        width: 50%;
                        height: 100%;
                        font-size: 3vh;
                        border-radius: 50px;
                        border: none;
                        background-color: #DFDEDE;
                    }
                }
            .continue-with-google {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background: #F0F0F0;
                border: 1px solid #343434;
                margin-top: 5%;
                border-radius: 16px;
                width: 70%;
                height: 45px;
                gap: 16px;
                cursor: pointer;
                img
                {
                    height: 15px;
                    width: 15px;
                }
            }
            .or {
                width: 100%;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 700;
                text-align: center;
                font-size: 16px;
                margin: 4% 0;
                line-height: 22px;
                /* identical to box height */
                color: #000000;
            }
            form {
                width: 70%;
                 ::placeholder {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* identical to box height */
                    color: #353535;
                }
                .name-section {
                    display: flex;
                    gap: 10%;
                    .input-feild {
                        width: 45%;
                    }
                }
                .input-feild {
                    flex: 1;
                    background: #F0F0F0;
                    border: 1px solid #D9D9D9;
                    border-radius: 16px;
                    height: 47px;
                    width: 100%;
                    margin: 16px 0;
                    padding: 8px;
                    font-size: 16px;
                    color: #343434;
                    text-indent: 20px;
                    box-sizing: border-box;
                    &:focus {
                        padding: 0.5rem;
                        border: double 1px transparent;
                        border-radius: 16px;
                        background-image: linear-gradient(#F0F0F0, #F0F0F0), linear-gradient(91.81deg, #FF174F 3.9%, #9999FF 98.88%);
                        background-origin: border-box;
                        background-clip: padding-box, border-box;
                        outline: none;
                    }
                }
                .login-button {
                    display: flex;
                    border: none;
                    background: #FCEE21;
                    border-radius: 12px;
                    width: 100%;
                    height: 48px;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Manrope';
                    margin: 5% 0 18px 0;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    color: black;
                }
                .forgot-button{
                    display: flex;
                    border: none;
                    background: #FCEE21;
                    border-radius: 12px;
                    width: 100%;
                    height: 48px;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Manrope';
                    margin: 5% 0 18px 0;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    color: black;
                }
                .password-section {
                    position: relative;
                    .password-visiblity {
                        position: 'absolute';
                        top: 0;
                        right: 0;
                    }
                }
               
            }
            .row-container
            {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                text-align: center;
                
                .dont-have-account{
                    justify-content: center;
                    margin-top: 10px;
                    margin-bottom: 60px;
                }
            }
            .center{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                .dont-have-an-account {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 133.52%;
                    /* or 21px */
        
                    letter-spacing: 0.03em;
        
                    color: #000000;
                    .signup-text-button{
                        color: #FFA854;
                        padding-left: 8px;
        
                    }
                }
            }
            
        }
        .right-content {
            img {
                box-sizing: content-box;
                // object-fit: cover;
                border-radius: 50px;
                width: 98vw;
                height: 35vh;
                margin-left: 1vw;
                position: absolute;
                object-fit: cover;
                top:1%;
                z-index: -1;
            }
        }
    }

  }
  
  @media only screen and (min-width: 1024px) {
    
    .login {
        display: flex;
        background-color: #F7F7F7;
        .learnwithone{
            position: absolute;
            left: 53%;
            top:7%;
            .Link{
                background-color: #FCEE21;
                color: #000000;
                border-radius: 25px;
                padding: 3%;
                font-size: 2.2vh;
                text-decoration: none;
                font-weight: 500;
            }
            .p1{
                margin-top: 7vh;
                border-radius: 40px;
                padding: 3%;
                padding-left: 5%;
                padding-right: 5%;
                font-size: 2.4vh;
                color: #D9D9D9;
                background-color: #00000088;
                width: max-content;
            }
            .p2{
                margin-left: 3vw;
                border-radius: 30px;
                font-size: 3.5vh;
                color: #D9D9D9;
                font-weight: 500;
                margin-top: 53vh;
                margin-left: 4vw;
                text-align: center;
                width: max-content;
            }

        }

        flex-direction: row;
        .left-content {
            flex: 5;
            margin: 0 6vw;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            text-align: center;
            margin-top: -5%;
            .logo {
                // position: absolute;
                // top: 15%;
                margin-top: 50%;
                height: 36.94px;
            }
            .title {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 133.52%;
                letter-spacing: 0.03em;
                color: #000000;
                margin-top: 8%;
            }
            .selector{
                display: flex;
                height: 8vh;
                width: 100%;
                background-color: #DFDEDE;
                margin-top: 4vh;
                border-radius: 50px;
                margin-bottom: 3vh;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                button{
                    width: 50%;
                    height: 100%;
                    font-size: 3vh;
                    border-radius: 50px;
                    border: none;
                    background-color: #DFDEDE;
                }
            }
            .continue-with-google {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background: #F0F0F0;
                border: 1px solid #343434;
                margin-top: 6%;
                border-radius: 16px;
                width: 100%;
                font-weight: 500;
                height: 45px;
                gap: 16px;
                cursor: pointer;
                img
                {
                    height: 3vh;
                    width: 3vh;
                }
            }
            .or {
                width: 100%;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 700;
                text-align: center;
                font-size: 16px;
                margin: 4% 0;
                line-height: 22px;
                /* identical to box height */
                color: #000000;
            }
            form {
                width: 100%;
                ::placeholder {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* identical to box height */
                    color: #353535;
                }
                .name-section {
                    display: flex;
                    gap: 10%;
                    .input-feild {
                        width: 45%;
                    }
                }
                .input-feild {
                    flex: 1;
                    background: #F0F0F0;
                    border: 1px solid #D9D9D9;
                    border-radius: 16px;
                    height: 50px;
                    width: 100%;
                    margin: 10px 0;
                    padding: 8px;
                    font-size: 16px;
                    color: #343434;
                    text-indent: 20px;
                    box-sizing: border-box;
                    &:focus {
                        padding: 0.5rem;
                        border: double 1px transparent;
                        border-radius: 16px;
                        background-image: linear-gradient(#F0F0F0, #F0F0F0), linear-gradient(91.81deg, #FF174F 3.9%, #9999FF 98.88%);
                        background-origin: border-box;
                        background-clip: padding-box, border-box;
                        outline: none;
                    }
                }
                .login-button {
                    display: flex;
                    border: none;
                    background: #FCEE21;
                    border-radius: 12px;
                    width: 100%;
                    height: 57px;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Manrope';
                    margin: 10% 0 18px 0;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    color: black;
                }
                .forgot-button{
                    display: flex;
                    border: none;
                    background: #FCEE21;
                    border-radius: 12px;
                    width: 100%;
                    height: 57px;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Manrope';
                    margin: 10% 0 18px 0;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    color: black;
                }
                .password-section {
                    position: relative;
                    .password-visiblity {
                        position: 'absolute';
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
        .row-container
        {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            
            .dont-have-account{
                justify-content: center;
                margin-top: 10px;
                margin-bottom: 60px;
            }
        }
        .center{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .dont-have-an-account {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 133.52%;
                /* or 21px */
    
                letter-spacing: 0.03em;
    
                color: #000000;
                .signup-text-button{
                    color: #FFA854;
                    padding-left: 8px;
    
                }
            }
        }
        .right-content {
            flex: 7;
            padding: 16px;
            .login-display {
                height: calc(100vh - 36px);
                width: 50vw;
                object-fit: cover;
                border-radius: 5%;
            }
        }
    }

  }