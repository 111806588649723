// desktop
@media only screen and (min-width: 1024px) {
  .navbar {
    background: #0A0A0A;
    box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    height: 12vh;
    width: 100%;

    .logo-container {
      display: flex;
      align-items: center;
      margin-left: 4.5%;
      height: 8vh;
      width: 3vw;
      margin-top: -1vh;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6vh;
        letter-spacing: 0.115em;
        text-transform: uppercase;
        color: #FCEE21;
        margin-left: 0.5vh;
      }
    }
  }

  .nav-links {
    display: flex;
    list-style: none;
    justify-content: center;
    position: absolute;
    height: 6.25vh;
    left: 17%;
    top: 3.125vh;
    margin-left: 10%;
    padding: 0;

    li {

      .link {
        color: #FCEE21;
        padding-left: 1.3vh;
        padding-right: 1.3vh;
        font-weight: 400;
        font-size: 2vh;
        line-height: 1.5vh;
        margin-left: 0.5vw;
        /* identical to box height */
        letter-spacing: 0.115em;
        text-transform: uppercase;
        text-decoration: none;
        transition: color 0.2s ease-in-out;
        cursor: pointer;
        display: flex;
        width: max-content;
        padding-top: 2.22vh;
        height: 5vh;
        text-align: center;

        img {
          height: 3.5vh;
          margin-top: -0.8vh;
          margin-left: 0.4vw;
        }

        // &:hover {
        //   color: white;

        // }
      }
    }
  }

  ul li:first-child {
    order: 1;
  }

  ul li:nth-child(2) {
    order: 2;
  }

  ul li:nth-child(3) {
    order: 3;
  }

  ul li:nth-child(4) {
    order: 4;
  }

  ul li:nth-child(5) {
    order: 3;
  }

  .register-buttons {
    display: flex;
    list-style: none;
    margin-right: 4%;
    margin-top: 0.5%;

    .login {
      color: #FCEE21;
      padding: 1vh;
      font-weight: 400;
      font-size: 2vh;
      line-height: 1vh;
      /* identical to box height */
      letter-spacing: 0.115em;
      text-transform: uppercase;
      text-decoration: none;
      transition: color 0.2s ease-in-out;
      width: 8vw;
      height: 8vh;
      justify-content: center;
      align-items: center;
      background-color: transparent;

      &:hover {
        color: white;
      }

      &:active {
        color: white;
        text-decoration: underline;
      }
    }

    .signup {
      width: 10vw;
      height: 8vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FCEE21;
      border-radius: 6px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 2vh;
      line-height: 1vh;
      letter-spacing: 0.115em;
      text-transform: uppercase;
      text-decoration: none;
      transition: background-color 0.4s ease-in-out;
      // padding: 2px 4px ;

      &:hover {
        background-color: white;
      }

      color: #000000;
    }
  }

  .image-wrapper {
    background-color: red;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    background: #0A0A0A;
    box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    justify-content: center;
    width: 100%;

    .popupNavbar {
      position: absolute;
      height: 50vh;
      width: 100vw;
      top: 98%;
      background: #0A0A0A;
      z-index: 2;

      .nav-links {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        li {
          div {
            padding: 3%;
            width: 100%;
            height: 15%;
            font-size: 18px;
            font-weight: 300;
            text-align: center;
            font-family: 'Inter';
            // background-color: #FCEE21;
          }
        }
      }
    }

    .more {
      position: absolute;
      z-index: 3;
      top: 20%;
      right: 5%;
      width: fit-content;
      font-size: 25px;
      color: #FCEE21;
      background-color: transparent;
      border: none;
    }

    .logo-container {
      position: absolute;
      left: 0%;
      display: flex;
      align-items: center;
      margin-left: 3%;
      height: 42px;
      width: 40px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-transform: uppercase;
        color: #FCEE21;
        margin-left: 10px;
      }
    }
  }

  .nav-links {
    position: absolute;
    left: 0%;
    display: flex;
    margin-left: 0%;
    margin: 0;
    padding: 0;
    width: 80%;
    // height: 100%;

    li {

      .link {
        color: #FCEE21;
        font-weight: 400;
        font-size: 18px;
        line-height: 17px;
        text-transform: uppercase;
        text-decoration: none;
        transition: color 0.2s ease-in-out;
        margin-top: 2%;
        cursor: pointer;

        &:hover {
          color: white;

        }
      }
    }
  }

  ul li:first-child {
    order: 1;
  }

  ul li:nth-child(2) {
    order: 2;
  }

  ul li:nth-child(3) {
    order: 3;
  }

  ul li:nth-child(4) {
    order: 4;
  }

  ul li:nth-child(5) {
    order: 3;
  }

  .register-buttons {
    display: flex;
    position: absolute;
    left: 40%;
    margin-top: 1.6%;


    .login {
      color: #FCEE21;
      width: 150px;
      font-weight: 400;
      font-size: 14px;
      height: 52px;
      /* identical to box height */
      letter-spacing: 0.115em;
      text-transform: uppercase;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      transition: color 0.2s ease-in-out;
      justify-self: center;
      align-self: center;
      line-height: 17px;
      margin-top: 0%;

      &:hover {
        color: white;
      }

      &:active {
        color: white;
        text-decoration: underline;
      }
    }

    .signup {
      width: 150px;
      height: 52px;
      display: flex;
      background: #FCEE21;
      border-radius: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.115em;
      text-transform: uppercase;
      text-decoration: none;
      transition: background-color 0.4s ease-in-out;
      justify-content: center;
      align-items: center;
      margin-left: 5%;
      justify-self: center;
      align-self: center;

      &:hover {
        background-color: white;
      }

      color: #000000;
    }
  }

  .image-wrapper {
    background-color: red;
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    height: 60px;
    width: 100%;
    background: #0A0A0A;

    .popupNavbar {
      position: absolute;
      height: 35vh;
      width: 100vw;
      top: 98%;
      background: #0A0A0A;

      .popupNavbar {
        position: absolute;
        height: 35vh;
        width: 100vw;
        top: 98%;
        background: #0A0A0A;
        z-index: 2;


        .nav-links {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 80%;
          margin-top: 0%;

          li {
            div {
              padding-bottom: 8%;
              width: 100%;
              height: 15%;
              font-size: 13px;
              font-weight: 300;
              text-align: center;
              // background-color: #FCEE21;
            }
          }
        }
      }

      .more {
        position: absolute;
        z-index: 3;
        top: 20%;
        right: 5%;
        width: fit-content;
        font-size: 25px;
        color: #FCEE21;
        background-color: transparent;
        border: none;

      }

      .logo-container {
        display: flex;
        align-items: center;
        margin-left: 5%;
        height: 100%;
        width: 44px;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }

        span {
          font-size: 8px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0.115em;
          text-transform: uppercase;
          color: #FCEE21;
          margin-left: 10px;
        }
      }
    }

    .nav-links {
      display: flex;
      list-style: none;
      justify-content: center;
      margin: 0;
      padding: 0;

      li {

        .link {
          color: #FCEE21;
          padding: 5px;
          font-weight: 400;
          font-size: 8px;
          line-height: 5px;
          /* identical to box height */
          letter-spacing: 0.115em;
          text-transform: uppercase;
          text-decoration: none;
          transition: color 0.2s ease-in-out;
          cursor: pointer;

          &:hover {
            color: white;

          }
        }

        .register-btns {
          display: flex;
          position: absolute;
          bottom: 7%;
          left: 20%;
          width: 50%;

          .login {
            width: 80px;
            color: #FCEE21;
            padding: 5px;
            font-weight: 400;
            font-size: 11px;
            line-height: 17px;
            margin-right: 10%;
            /* identical to box height */
            letter-spacing: 0.115em;
            text-transform: uppercase;
            text-decoration: none;
            justify-content: center;
            align-items: center;
            transition: color 0.2s ease-in-out;

            &:hover {
              color: white;
            }

            &:active {
              color: white;
              text-decoration: underline;
            }
          }

          .signup {
            width: 80px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FCEE21;
            border-radius: 6px;
            font-weight: 400;
            font-size: 11px;
            line-height: 17px;
            letter-spacing: 0.115em;
            text-transform: uppercase;
            text-decoration: none;
            transition: background-color 0.4s ease-in-out;

            &:hover {
              background-color: white;
            }

            color: #000000;
          }
        }
      }
    }

    ul li:first-child {
      order: 1;
    }

    ul li:nth-child(2) {
      order: 2;
    }

    ul li:nth-child(3) {
      order: 3;
    }

    ul li:nth-child(4) {
      order: 4;
    }

    ul li:nth-child(5) {
      order: 3;
    }

    .register-buttons {
      position: absolute;
      left: 40%;
      display: flex;
      list-style: none;
      margin-right: 5%;

      .login {
        color: #FCEE21;
        padding: 5px;
        font-weight: 400;
        font-size: 8px;
        line-height: 17px;
        margin-right: 10%;
        /* identical to box height */
        letter-spacing: 0.115em;
        text-transform: uppercase;
        text-decoration: none;
        justify-content: center;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: white;
        }

        &:active {
          color: white;
          text-decoration: underline;
        }
      }

      .signup {
        width: 60px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FCEE21;
        border-radius: 6px;
        font-weight: 400;
        font-size: 8px;
        line-height: 17px;
        letter-spacing: 0.115em;
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color 0.4s ease-in-out;

        &:hover {
          background-color: white;
        }

        color: #000000;
      }
    }

    .image-wrapper {
      background-color: red;
    }
  }
}