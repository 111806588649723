@media only screen and (max-width:380px){
    .why {
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        margin-top: 5vh;
        .whycard-title{
            p{
                padding-top: 2%;
            }
            .Stbtn{
                padding: 3%;
                width: 32vw;
                margin-left: 14vw;
                margin-top: 2vh;
                border: none;
                background-color: black;
                border-radius: 10px;
                position: absolute;
                margin-left: 60vw;
                margin-top: -8vh;
                font-size: 10px;
    
                .link{
                    text-decoration: none;
                    color: white;
                }
            }
        }

        .whysection {

            .rocket-bg{
                position: absolute;
                top: 35vh;
                left: 20vw;
                z-index: 2;
                display: none;
            }
            
            align-items: center;
            p {
                margin-left: 14vw;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                width: 290px;
                line-height: 9px;
                letter-spacing: 0.025em;
                justify-content: center;
                align-items: center;
            }
            .card-1 {
                display: flex;
                flex-direction: column;
                background: #FCEE21;
                border-radius: 42px;
                height: 95vw;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                justify-content: center;
                align-items: start;
                .img-card {
                    background: #F3EFB5;
                    border-radius: 82px;
                    width: 50vw;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    height: 30vw;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                }
                .description {
                    margin-top: 5vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: min(24px, 2.9vw);
                    line-height: 133.52%;
                    text-align: center;
                    /* or 32px */
                    
                    letter-spacing: 0.045em;
                    color: #232839;
                    // text-align: justify;
                }
            }
            .card-2 {

                display: flex;
                flex-direction: column;
                background: #F3EFB5;
                border-radius: 42px;
                height: 95vw;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                justify-content: center;
                align-items: center;

                .img-card {
                    background: #FCEE21;
                    border-radius: 82px;
                    width: 50vw;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    height: 30vw;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
      
                }
                .description {
                    margin-top: 10px;
                    margin-bottom: 8vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: min(37px, 4vw);
                    line-height: 129.52%;
                    /* or 52px */

                    letter-spacing: 0.045em;
                    color: #000000;
                    text-align: start;
                }
            }
            .card-3 {

                display: flex;
                flex-direction: column;
                background: #232839;
                border-radius: 42px;
                height: 74vw;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                margin-bottom: 16vw;
                justify-content: center;
                align-items: center;
                
                img{
                    z-index: 2;
                    width: 20vw;
                    margin-top: 8vh;
                    margin-left: 34vw;
                }

                .description {
                    margin-top: 5vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 5vw;
                    line-height: 120.52%;
                    /* or 52px */

                    letter-spacing: 0.030em;
                    color: #F7F7F7;
                    text-align: start;
                }
            }
        }
    }
}
@media only screen and (min-width:380px) and (max-width: 400px) {
    .why {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0vh;
        .whycard-title{
            p{
                line-height: 5vh;
            }
            .Stbtn{
                padding: 3%;
                width: 32vw;
                margin-left: 14vw;
                margin-top: 2vh;
                border: none;
                background-color: black;
                border-radius: 10px;
                position: absolute;
                margin-left: 60vw;
                margin-top: -8vh;
                font-size: 10px;
    
                .link{
                    text-decoration: none;
                    color: white;
                }
            }
        }

        .whysection {

            .rocket-bg{
                position: absolute;
                top: 35vh;
                left: 20vw;
                z-index: 2;
                display: none;
            }
            
            align-items: center;
            p {
                margin-left: 14vw;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                width: 290px;
                line-height: 9px;
                letter-spacing: 0.025em;
                justify-content: center;
                align-items: center;
            }
            .card-1 {
                display: flex;
                flex-direction: column;
                background: #FCEE21;
                border-radius: 42px;
                height: 95vw;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                justify-content: center;
                align-items: start;
                .img-card {
                    background: #F3EFB5;
                    border-radius: 82px;
                    width: 50vw;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    height: 30vw;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                }
                .description {
                    margin-top: 5vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: min(24px, 2.9vw);
                    line-height: 133.52%;
                    text-align: center;
                    /* or 32px */
                    
                    letter-spacing: 0.045em;
                    color: #232839;
                    // text-align: justify;
                }
            }
            .card-2 {

                display: flex;
                flex-direction: column;
                background: #F3EFB5;
                border-radius: 42px;
                height: 95vw;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                justify-content: center;
                align-items: center;

                .img-card {
                    background: #FCEE21;
                    border-radius: 82px;
                    width: 50vw;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    height: 30vw;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
      
                }
                .description {
                    margin-top: 10px;
                    margin-bottom: 8vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: min(37px, 4vw);
                    line-height: 129.52%;
                    /* or 52px */

                    letter-spacing: 0.045em;
                    color: #000000;
                    text-align: start;
                }
            }
            .card-3 {

                display: flex;
                flex-direction: column;
                background: #232839;
                border-radius: 42px;
                height: 74vw;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                margin-bottom: 16vw;
                justify-content: center;
                align-items: center;
                
                img{
                    z-index: 2;
                    width: 20vw;
                    margin-top: 6vh;
                    margin-left: 34vw;
                }

                .description {
                    margin-top: 6vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: min(43px, 4.5vw);
                    line-height: 129.52%;
                    /* or 52px */

                    letter-spacing: 0.045em;
                    color: #F7F7F7;
                    text-align: start;
                }
            }
        }
    }
}
@media only screen and (min-width:400px) and (max-width: 768px) {
    .why {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0vh;

        .whycard-title{
            p{
                font-weight: 700;
                padding-top: 1%;
                margin-top: 5%;
            }
            .Stbtn{
                padding: 3%;
                width: 32vw;
                margin-left: 14vw;
                margin-top: 2vh;
                border: none;
                background-color: black;
                border-radius: 10px;
                position: absolute;
                margin-left: 60vw;
                margin-top: -7vh;
                font-size: 10px;
    
                .link{
                    text-decoration: none;
                    color: white;
                }
            }

        }

        .whysection {

            .rocket-bg{
                position: absolute;
                top: 35vh;
                left: 20vw;
                z-index: 2;
                display: none;
            }
            
            align-items: center;
            p {
                margin-left: 14vw;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                width: 290px;
                line-height: 9px;
                letter-spacing: 0.025em;
                justify-content: center;
                align-items: center;
            }
            .card-1 {
                display: flex;
                flex-direction: column;
                background: #FCEE21;
                border-radius: 42px;
                height: 95vw;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                justify-content: center;
                align-items: start;
                .img-card {
                    background: #F3EFB5;
                    border-radius: 82px;
                    width: 50vw;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    height: 30vw;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                }
                .description {
                    margin-top: 5vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: min(24px, 2.9vw);
                    line-height: 133.52%;
                    text-align: center;
                    /* or 32px */
                    
                    letter-spacing: 0.045em;
                    color: #232839;
                    // text-align: justify;
                }
            }
            .card-2 {

                display: flex;
                flex-direction: column;
                background: #F3EFB5;
                border-radius: 42px;
                height: 95vw;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                justify-content: center;
                align-items: center;

                .img-card {
                    background: #FCEE21;
                    border-radius: 82px;
                    width: 50vw;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    height: 30vw;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
      
                }
                .description {
                    margin-top: 10px;
                    margin-bottom: 8vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: min(37px, 4vw);
                    line-height: 129.52%;
                    /* or 52px */

                    letter-spacing: 0.045em;
                    color: #000000;
                    text-align: start;
                }
            }
            .card-3 {

                display: flex;
                flex-direction: column;
                background: #232839;
                border-radius: 42px;
                height: 74vw;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                margin-bottom: 16vw;
                justify-content: center;
                align-items: center;
                
                img{
                    z-index: 2;
                    width: 20vw;
                    margin-top: 8vh;
                    margin-left: 34vw;
                }

                .description {
                    margin-top: 5vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: min(43px, 4.5vw);
                    line-height: 120.52%;
                    height: fit-content;
                    /* or 52px */

                    letter-spacing: 0.045em;
                    color: #F7F7F7;
                    text-align: start;
                }
            }
        }
    }
    }



@media only screen and (min-width: 768px) and (max-width: 1024px)  {
    .why {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10%;
        .whycard-title{
            p{
                font-weight: 700;
                padding-top: 1%;
                margin-top: 5%;
            }
            .Stbtn{
                padding: 3%;
                width: 32vw;
                margin-left: 14vw;
                margin-top: 2vh;
                border: none;
                background-color: black;
                border-radius: 10px;
                position: absolute;
                margin-left: 60vw;
                margin-top: -8vh;
                font-size: 10px;
    
                .link{
                    text-decoration: none;
                    color: white;
                }
            }

        }

        .whysection {

            .rocket-bg{
                position: absolute;
                top: 35vh;
                left: 20vw;
                z-index: 2;
                // display: none;
            }

            align-items: center;
            p {
                margin: 0;
                margin-left: 120px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 48px;
                line-height: 59px;
                letter-spacing: 0.025em;
                justify-content: center;
                align-items: center;
            }
            .card-1 {
                display: flex;
                flex-direction: column;
                background: #FCEE21;
                border-radius: 82px;
                height: 748px;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                justify-content: center;
                align-items: start;
                .img-card {
                    background: #F3EFB5;
                    border-radius: 82px;
                    width: 475.1px;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    height: 244.57px;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                }
                .description {
                    margin-top: 5vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 133.52%;
                    /* or 32px */
                    
                    letter-spacing: 0.045em;
                    color: #232839;
                    // text-align: justify;
                }
            }
            .card-2 {

                display: flex;
                flex-direction: column;
                background: #F3EFB5;
                border-radius: 82px;
                height: 700px;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                justify-content: center;
                align-items: center;

                .img-card {
                    background: #FCEE21;
                    border-radius: 82px;
                    width: 558px;
                    height: 276px;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
      
                }
                .description {
                    margin-top: 10px;
                    margin-bottom: 8vh;
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 37px;
                    line-height: 129.52%;
                    /* or 52px */

                    letter-spacing: 0.045em;
                    color: #000000;
                    text-align: start;
                }
            }
            .card-3 {

                display: flex;
                flex-direction: column;
                background: #232839;
                border-radius: 82px;
                height: 630px;
                width: 84vw;
                margin-left: 8vw;
                margin-top: 8vw;
                margin-bottom: 16vw;
                justify-content: center;
                align-items: center;
                
                .padL{
                    position: absolute;
                    top:292vh;
                    left:60%;
                }

                .description {
            
                    margin-left: 10vw;
                    margin-right: 10vw;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 43px;
                    line-height: 129.52%;
                    /* or 52px */

                    letter-spacing: 0.045em;
                    color: #F7F7F7;
                    text-align: start;
                }
            }
        }
    }
    }

@media only screen and (min-width: 1024px) {
.why {
    width: 360vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    .whycard-title{
        p{
            font-weight: 700;
            padding-top: 1%;
        }
        .Stbtn{
            padding: 3%;
            width: 20vw;
            margin-left: 14vw;
            margin-top: 2vh;
            border: none;
            background-color: #FCEE21;
            border-radius: 10px;

            .link{
                text-decoration: none;
                color: black;
            }
        }
    }
    background-color:white;
    .rocket-bg{
        position: absolute;
        top: 35vh;
        left: 24vw;
        z-index: 2;
    }
    .whysection {
        max-width: 73vw;
        height: 100vh;
        display: flex;
        align-items: center;
        p {
            margin: 0;
            margin-left: 225px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            line-height: 78px;
            letter-spacing: 0.025em;
            letter-spacing: 0.025em;
            justify-content: center;
            align-items: center;
        }
        .card-1 {
            display: flex;
            flex-direction: column;
            background: #FCEE21;
            border-radius: 82px;
            height: 90vh;
            width: 80vw;
            margin-left: 5vw;
            justify-content: center;
            align-items: center;
            .img-card {
                background: #F3EFB5;
                border-radius: 82px;
                width: 40vw;
                display: flex;
                height: 40vh;
                justify-content: center;
                align-items: baseline;
            }
            .description {
                margin-top: 7vh;
                margin-left: 10vw;
                margin-right: 10vw;
                font-family: 'Montserrat';
                font-style: normal;
                padding-left: 3.5vw;
                padding-right: 3.5vw;
                width:85%;

                font-size: 2.8vh;
                line-height: 4vh;
                letter-spacing: 0.025em;
                font-weight: 500;
                color: #232839;
                text-align: center;
            }
        }
        .card-2 {
            display: flex;
            flex-direction: column;
            background: #F3EFB5;
            border-radius: 82px;
            height: 90vh;
            width: 80vw;
            margin-left: 5vw;
            justify-content: center;
            align-items: start;
            .img-card {
                background: #FCEE21;
                border-radius: 82px;
                width: 40vw;
                height: 40vh;
                display: flex;
                justify-content: center;
                margin-left: 10vw;
            }
            .description {
                margin-bottom: 8vh;
                margin-left: 10vw;
                margin-right: 10vw;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 5vh;
                line-height: 129.52%;
                /* or 52px */
                letter-spacing: 0.045em;
                color: #000000;
                text-align: start;
            }
        }
        .card-3 {
            display: flex;
            flex-direction: column;
            background: #232839;
            border-radius: 82px;
            height: 90vh;
            width: 80vw;
            margin-left: 5vw;
            justify-content: center;
            align-items: start;

            .padL{
                position: absolute;
                top:40%;
                left:80%;
            }
            .description {
                margin-bottom: 8vh;
                margin-left: 7vw;
                margin-right: 10vw;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 2.6vw;
                height: 60%;
                margin-top: 13%;
                line-height: 7vh;
                width: 68%;
                /* or 52px */
                letter-spacing: 0.045em;
                color: #F7F7F7;
                text-align: start;
            }
        }
    }
}
}