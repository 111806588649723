@media only screen and (min-width: 300px) and (max-width: 400px) {
    .reports {
        display: flex;
        flex-direction: column;
        background-color: #0E0E0E;
        width: 77.3%;
        height: 150dvh;
        width: 100vw;

        .left-content {
            // padding: 0px 0px 0px 154px;
            width: 100%;
            height: 57%;
            background-color: #0E0E0E;

            .card {
                height: calc(45vh);
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                padding: 3vh 7vw;
                background-color: #0E0E0E;

                .title {
                    margin-left: 1vw;
                    max-width: 498px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 100%;
                    color: #F7F7F7;
                    letter-spacing: 4%;
                }

                .row {
                    display: flex;
                    flex-direction: row;
                    width: 100%;

                    .reports-bar {
                        width: 10vw;
                        margin-top: 2vh;
                        left: 10%;
                        height: 55%;
                        position: absolute;
                    }

                    .column {
                        margin-left: 16%;
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        justify-content: start;

                        .subtitle {
                            margin-top: 8vh;
                            margin-left: 0px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 100%;

                            /* or 24px */
                            width: 90%;
                            letter-spacing: 0.03em;
                            color: #FFFFFF;
                        }

                        .subtitle1 {
                            margin-top: 5vh;
                            margin-left: 0px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 100%;
                            width: 85%;
                            /* or 24px */
                            letter-spacing: 0.03em;
                            color: #FFFFFF;
                        }

                        .subtitle2 {
                            margin-top: 7px;
                            margin-left: 0px;
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 100%;
                            width: 90%;
                            /* or 24px */
                            letter-spacing: 4%;
                            color: rgb(184, 182, 182);
                        }
                    }
                }
            }
        }

        .right-content {
            flex: 5;
            margin: 0 0vw;
            height: 100dvh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: #0E0E0E;

            .circular-slider-container {
                position: relative;
                width: 95%;
                height: 100dvh;
                /* Adjust height as per your design */
                overflow: hidden;
                margin: auto;
                background: #ffe700;
            }
            
            .Upper-section {
                text-align: center;
                /* margin-bottom: 10px; */
            }
            
            .heading {
                padding: 10px;
                font-size: 2.5rem;
                font-weight: 600;
                margin-top: 40px;
                visibility: collapse;
            }
            
            .title {
                /* margin-left: 1vw;
                max-width: 498px; */
                font-weight: 400;
                font-size: 20px;
                line-height: 100%;
                color: #0a0a0a;
                letter-spacing: 4%;
            }
            
            .circular-slider {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 80%;
            }
            
            .slider-item {
                position: absolute;
                width: 90dvw;
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: transform 0.5s ease, opacity 0.5s ease;
                opacity: 0.5;
                z-index: 1;
            }
            
            .slider-item img {
                width: 50dvw;
                height: 150px;
                object-fit: contain;
                border-radius: 10px;
                margin-bottom: 10px;
            }
            
            .slider-item.middle {
                transform: scale(1.5) translateY(-20px);
                /* Enlarging the middle one */
                opacity: 1;
                z-index: 3;
            }
            
            .slider-item.left {
                transform: translateX(-150px) translateY(10px);
                /* Positioning left */
                z-index: 2;
                opacity: 0.8;
            }
            
            .slider-item.right {
                transform: translateX(150px) translateY(10px);
                /* Positioning right */
                z-index: 2;
                opacity: 0.8;
            }
            
            .slider-item.far-left {
                transform: translateX(-300px) translateY(40px) scale(0.8);
                /* Smaller and further left */
                opacity: 0.5;
                z-index: 1;
            }
            
            .slider-item.far-right {
                transform: translateX(300px) translateY(40px) scale(0.8);
                /* Smaller and further right */
                opacity: 0.5;
                z-index: 1;
            }
            
            .slider-item.behind {
                transform: scale(0.5) translateY(60px);
                /* Smaller, further back */
                opacity: 0.3;
                z-index: 0;
            }
            
            .slider-text {
                font-size: 14px;
                text-align: center;
                margin-top: 5px;
                color: #333;
                visibility: collapse;
            }

        }
    }
}

@media only screen and (min-width: 400px) and (max-width: 768px) {
    .reports {
        display: flex;
        flex-direction: column;
        background-color: #0E0E0E;
        width: 77.3%;
        height: 150dvh;
        width: 100vw;

        .left-content {
            // padding: 0px 0px 0px 154px;
            width: 100%;
            height: 60%;
            background-color: #0E0E0E;

            .card {
                height: calc(45vh);
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                padding: 3vh 7vw;
                background-color: #0E0E0E;

                .title {
                    margin-left: 1vw;
                    max-width: 498px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 100%;
                    color: #F7F7F7;
                    letter-spacing: 4%;
                }

                .row {
                    display: flex;
                    flex-direction: row;
                    width: 100%;

                    .reports-bar {
                        width: 10vw;
                        margin-top: 2vh;
                        left: 10%;
                        height: 55%;
                        position: absolute;
                    }

                    .column {
                        margin-left: 16%;
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        justify-content: start;

                        .subtitle {
                            margin-top: 50px;
                            margin-left: 0px;
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 100%;

                            /* or 24px */
                            width: 90%;
                            letter-spacing: 0.03em;
                            color: #FFFFFF;
                        }

                        .subtitle1 {
                            margin-top: 13%;
                            margin-left: 0px;
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 100%;
                            width: 85%;
                            /* or 24px */
                            letter-spacing: 0.03em;
                            color: #FFFFFF;
                        }

                        .subtitle2 {
                            margin-top: 10px;
                            margin-left: 0px;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 100%;
                            width: 90%;
                            /* or 24px */
                            letter-spacing: 4%;
                            color: rgb(184, 182, 182);
                        }
                    }
                }
            }
        }

        .right-content {
            flex: 5;
            // margin: 0 0vw;
            height: 100dvh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: #0E0E0E;

            .circular-slider-container {
                position: relative;
                width: 100%;
                height: 100dvh;
                /* Adjust height as per your design */
                overflow: hidden;
                margin: auto;
                background: #ffe700;
            }
            
            .Upper-section {
                text-align: center;
                /* margin-bottom: 10px; */
            }
            
            .heading {
                padding: 10px;
                font-size: 2.5rem;
                font-weight: 600;
                margin-top: 40px;
                visibility: collapse;
            }
            
            .title {
                /* margin-left: 1vw;
                max-width: 498px; */
                font-weight: 400;
                font-size: 20px;
                line-height: 100%;
                color: #0a0a0a;
                letter-spacing: 4%;
            }
            
            .circular-slider {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 80%;
            }
            
            .slider-item {
                position: absolute;
                width: 90dvw;
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: transform 0.5s ease, opacity 0.5s ease;
                opacity: 0.5;
                z-index: 1;
            }
            
            .slider-item img {
                width: 100vw;
                height: 150px;
                object-fit: contain;
                border-radius: 10px;
                margin-bottom: 10px;
            }
            
            .slider-item.middle {
                transform: scale(1.5) translateY(-20px);
                /* Enlarging the middle one */
                opacity: 1;
                z-index: 3;
            }
            
            .slider-item.left {
                transform: translateX(-150px) translateY(10px);
                /* Positioning left */
                z-index: 2;
                opacity: 0.8;
            }
            
            .slider-item.right {
                transform: translateX(150px) translateY(10px);
                /* Positioning right */
                z-index: 2;
                opacity: 0.8;
            }
            
            .slider-item.far-left {
                transform: translateX(-300px) translateY(40px) scale(0.8);
                /* Smaller and further left */
                opacity: 0.5;
                z-index: 1;
            }
            
            .slider-item.far-right {
                transform: translateX(300px) translateY(40px) scale(0.8);
                /* Smaller and further right */
                opacity: 0.5;
                z-index: 1;
            }
            
            .slider-item.behind {
                transform: scale(0.5) translateY(60px);
                /* Smaller, further back */
                opacity: 0.3;
                z-index: 0;
            }
            
            .slider-text {
                font-size: 14px;
                text-align: center;
                margin-top: 5px;
                color: #333;
                visibility: collapse;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .reports {
        display: flex;
        flex-direction: column;
        background-color: #0E0E0E;
        width: 77.3%;
        height: 200vh;
        width: 100vw;

        .left-content {
            // padding: 0px 0px 0px 154px;
            width: 100%;
            height: 55%;
            background-color: #0E0E0E;

            .card {
                height: calc(50vh);
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                padding: 3vh 7vw;
                background-color: #0E0E0E;

                .title {
                    margin-left: 1vw;
                    max-width: 598px;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 100%;
                    color: #FCEE21;
                    letter-spacing: 4%;
                }

                .row {
                    display: flex;
                    flex-direction: column;

                    .reports-bar {
                        width: 10vw;
                        margin-top: 1vh;
                        height: 30%;
                        margin-left: 15%;
                    }

                    .column {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        margin-left: 10%;

                        .subtitle {
                            margin-top: 80px;
                            margin-left: 0px;
                            font-weight: 400;
                            font-size: 25px;
                            line-height: 100%;
                            /* or 24px */
                            width: 80%;
                            letter-spacing: 0.03em;
                            color: #FFFFFF;
                        }

                        .subtitle1 {
                            margin-top: 75px;
                            margin-left: 0px;
                            font-weight: 400;
                            font-size: 25px;
                            line-height: 100%;
                            width: 85%;
                            /* or 24px */
                            letter-spacing: 0.03em;
                            color: #FFFFFF;
                        }

                        .subtitle2 {
                            margin-top: 10px;
                            margin-left: 0px;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 100%;
                            width: 80%;
                            /* or 24px */
                            letter-spacing: 4%;
                            color: #a5a5a5;
                        }

                    }
                }
            }
        }

        .right-content {
            flex: 5;
            margin: 0 0vw;
            height: 45vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: #0E0E0E;

            .circular-slider-container {
                position: relative;
                width: 100%;
                height: 100dvh;
                overflow: hidden;
                margin: auto;
                background: #ffe700;
            }
            
            .Upper-section {
                text-align: center;
                /* margin-bottom: 10px; */
            }
            
            .heading {
                padding: 10px;
                font-size: 2.5rem;
                font-weight: 600;
                margin-top: 40px;
                // visibility: collapse;
            }
            
            .title {
                margin-left: 1vw;
                max-width: 498px; 
                font-weight: 400;
                font-size: 20px;
                line-height: 100%;
                color: #0a0a0a;
                letter-spacing: 4%;
            }
            
            .circular-slider {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 80%;
            }
            
            .slider-item {
                position: absolute;
                width: 90dvw;
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: transform 0.5s ease, opacity 0.5s ease;
                opacity: 0.5;
                z-index: 1;
            }
            
            .slider-item img {
                width: 100vw;
                height: 150px;
                object-fit: contain;
                border-radius: 10px;
                margin-bottom: 10px;
            }
            
            .slider-item.middle {
                transform: scale(1.5) translateY(-20px);
                /* Enlarging the middle one */
                opacity: 1;
                z-index: 3;
            }
            
            .slider-item.left {
                transform: translateX(-150px) translateY(10px);
                /* Positioning left */
                z-index: 2;
                opacity: 0.8;
            }
            
            .slider-item.right {
                transform: translateX(150px) translateY(10px);
                /* Positioning right */
                z-index: 2;
                opacity: 0.8;
            }
            
            .slider-item.far-left {
                transform: translateX(-300px) translateY(40px) scale(0.8);
                /* Smaller and further left */
                opacity: 0.5;
                z-index: 1;
            }
            
            .slider-item.far-right {
                transform: translateX(300px) translateY(40px) scale(0.8);
                /* Smaller and further right */
                opacity: 0.5;
                z-index: 1;
            }
            
            .slider-item.behind {
                transform: scale(0.5) translateY(60px);
                /* Smaller, further back */
                opacity: 0.3;
                z-index: 0;
            }
            
            .slider-text {
                font-size: 14px;
                text-align: center;
                margin-top: 5px;
                color: #333;
                // visibility: collapse;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .reports {
        display: flex;
        flex-direction: row;
        background-color: #0E0E0E;
        width: 77.3%;
        height: 100%;
        height: 100vh;
        width: 100vw;

        .left-content {
            flex: 7;
            // padding: 0px 0px 0px 154px;
            width: 100%;
            height: 100%;
            background-color: #0E0E0E;

            .card {
                height: calc(74vh);
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                padding: 13vh 7vw;
                background-color: #0E0E0E;

                .title {
                    max-width: 36vw;
                    font-weight: 450;
                    font-size: 4vh;
                    line-height: 100%;
                    color: #FCEE21;
                    letter-spacing: 4%;
                }

                .row {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: start;

                    .reports-bar {
                        width: 5vw;
                        top: 26vh;
                        height: 36vh;
                        position: absolute;
                        left: 16%
                    }

                    .column {
                        margin-left: 13%;
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        justify-content: start;
                        width: 40vw;

                        .subtitle {
                            margin-top: 13.5vh;
                            margin-left: 23px;
                            font-weight: 400;
                            font-size: 2.7vh;
                            line-height: 100%;
                            text-indent: 2px;
                            /* or 24px */
                            letter-spacing: 1px;
                            color: #FFFFFF;
                        }

                        .subtitle1 {
                            margin-top: 10vh;
                            margin-left: 23px;
                            font-weight: 400;
                            font-size: 2.7vh;
                            line-height: 100%;
                            /* or 24px */
                            letter-spacing: 1px;
                            color: #FFFFFF;
                        }

                        .subtitle2 {
                            margin-top: 10px;
                            margin-left: 23px;
                            font-weight: 200;
                            font-size: 2.3vh;
                            width: 33vw;
                            line-height: 120%;
                            letter-spacing: 2px;
                            /* or 24px */
                            letter-spacing: 4%;
                            color: #cbc9c9;
                        }
                    }

                }
            }
        }

        .right-content {
            flex: 5;
            margin: 0 0vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: #0E0E0E;
            margin-right: 50px;

            .circular-slider-container {
                position: relative;
                width: 100%;
                height: 100dvh;
                /* Adjust height as per your design */
                overflow: hidden;
                margin: auto;
                background: #ffe700;
            }

            .Upper-section {
                text-align: center;
                /* margin-bottom: 10px; */
            }

            .heading {
                padding: 10px;
                font-size: 2.5rem;
                font-weight: 600;
                margin-top: 40px;
            }

            .title {
                /* margin-left: 1vw;
                max-width: 498px; */
                font-weight: 400;
                font-size: 20px;
                line-height: 100%;
                color: #0a0a0a;
                letter-spacing: 4%;
            }

            .circular-slider {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 80%;
            }

            .slider-item {
                position: absolute;
                width: 90dvw;
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: transform 0.5s ease, opacity 0.5s ease;
                opacity: 0.5;
                z-index: 1;
            }

            .slider-item img {
                width: 100vw;
                height: 250px;
                object-fit: contain;
                border-radius: 10px;
                margin-bottom: 10px;
            }

            .slider-item.middle {
                transform: scale(1.5) translateY(-20px);
                /* Enlarging the middle one */
                opacity: 1;
                z-index: 3;
            }

            .slider-item.left {
                transform: translateX(-150px) translateY(10px);
                /* Positioning left */
                z-index: 2;
                opacity: 0.8;
            }

            .slider-item.right {
                transform: translateX(150px) translateY(10px);
                /* Positioning right */
                z-index: 2;
                opacity: 0.8;
            }

            .slider-item.far-left {
                transform: translateX(-300px) translateY(40px) scale(0.8);
                /* Smaller and further left */
                opacity: 0.5;
                z-index: 1;
            }

            .slider-item.far-right {
                transform: translateX(300px) translateY(40px) scale(0.8);
                /* Smaller and further right */
                opacity: 0.5;
                z-index: 1;
            }

            .slider-item.behind {
                transform: scale(0.5) translateY(60px);
                /* Smaller, further back */
                opacity: 0.3;
                z-index: 0;
            }

            .slider-text {
                font-size: 14px;
                text-align: center;
                margin-top: 5px;
                color: #333;
            }
        }
    }
}
