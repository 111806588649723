.dashboard{
        display: flex;
        flex-direction: column;
        background-color: #161616;
        height: 100vh;
        color: white;
        width: 100%;
        overflow: hidden;
        .dashboard-content{
                width: 95%;
                height: 100%;
                margin-left: 5%;
                overflow-y: scroll;
                .userInfo{
                        margin-top: 5%;
                        width: 100%;
                        padding-left: 8%;
                        display: flex;
                        height: 25%;
                        img{
                                height: 90%;
                                margin-top: 1%;
                                border-radius: 20px;
                        }
                        .details{
                                margin-left: 4%;
                                height: 50%;
                                padding-top: 3.5%;
                                .name{
                                        font-weight: 600;
                                        font-size: 36px;
                                        letter-spacing: 0.1px;
                                }
                                .class{
                                        font-size:16px;
                                        color:#BDBDBD;
                                        margin-top: -6%;
                                        padding-left: 2%;

                                }
                        }
                }
                .intro{
                        padding-left: 8%;
                        margin-top: 4%;
                        margin-bottom: 3%;

                        .heading{
                                font-size: 28px;
                                font-weight: 500;
                                letter-spacing: 0.3px;
                        }
                        .desc{
                                font-size: 16px;
                                font-weight: 400;
                                color:#BDBDBD;
                                margin-top: -1%;
                        }
                }
                .hr{
                        width: 84%;
                        margin-left: 8%;
                        height: 0.4%;
                        border-radius: 15px;
                        background-color: #353535;
                }
                .dts{
                        width: 100%;

                        .hr{
                                min-width: 84%;
                                margin-left: 8%;
                                padding: 0.11%;
                                border-radius: 15px;
                                background-color: #353535;
                                border: none;
                        }
                        .detailsList{
                                width: 100%;
                                display: flex;
                                height: 400px;
                                .intro{
                                        width: 40%;
                                        padding-top: 0%;
                                        padding-bottom: 0%;
                                        background-color: #161616;
                
                                        .heading{
                                                font-size: 20px;
                                                font-weight: 500;
                                                letter-spacing: 0.3px;
                                        }
                                        .desc{
                                                font-size: 16px;
                                                font-weight: 400;
                                                color:#BDBDBD;
                                                margin-top: -3%;
                                        }
                                }
                                .col2{
                                        width: 60%;
                                        padding-top: 4%;

                                        .dtImg{
                                                height: 200%;
                                                display: flex;
                                                padding-bottom: 4%;
                                                img{
                                                        height: 40%;
                                                        margin-right: 10%;
                                                        border-radius: 20px;
                                                }
                                                label{
                                                        height: 30%;
                                                        border-color: #353535;
                                                        border-style: solid;
                                                        border-width: 2px;
                                                        padding: 2%;
                                                        border-radius: 10px;
                                                        cursor: pointer;

                                                        img{
                                                                height: 20%;
                                                                width: 40%;
                                                                margin-left: 30%;
                                                                margin-top: 10%;
                                                                margin-bottom: 10%;

                                                        }
                                                }
                                        }
                                        .dt{
                                                display: flex;
                                                height: max-content;
                                                margin: 1%;
                                                font-weight: 300;
                                                .DH{
                                                        padding-left: 2%;
                                                        padding-right: 2%;
                                                        width: 10%;
                                                        height: 6vh;
                                                        border-width: 2px ;
                                                        border-color: #353535;
                                                        border-style: solid;
                                                        padding-top: 1vh;
                                                        border-top-left-radius: 5px;
                                                        border-bottom-left-radius: 5px;
                                                }
                                                input{
                                                        padding-left: 1%;
                                                        padding-right: 1%;
                                                        min-width:50%;
                                                        border-width: 2px ;
                                                        border-color: #353535;
                                                        border-style: solid;
                                                        border-top-right-radius: 5px;
                                                        border-bottom-right-radius: 5px;
                                                        border-left: none;
                                                        background-color: transparent;
                                                        height: 6vh;
                                                        color: #BDBDBD;
                                                        // padding-bottom: 5%;
                                                }
                                                .val{
                                                        padding-left: 1%;
                                                        padding-right: 1%; 
                                                        min-width:50%;
                                                        border-width: 2px ;
                                                        border-color: #353535;
                                                        border-style: solid;
                                                        padding-top: 1vh;
                                                        border-top-right-radius: 5px;
                                                        border-bottom-right-radius: 5px;
                                                        border-left: none;
                                                }
                                                .val2{
                                                        padding-left: 1%;
                                                        padding-right: 1%; 
                                                        min-width:50%;
                                                        border-width: 2px ;
                                                        border-color: #353535;
                                                        border-style: solid;
                                                        padding-top: 1vh;
                                                        border-radius: 5px;
                                                }
                                                button{
                                                        padding-left: 1%;
                                                        padding-right: 1%;
                                                        width: 5%;
                                                        background-color: transparent;
                                                        border-width: 2px ;
                                                        border-color: #353535;
                                                        border-style: solid;
                                                        height: 6vh;
                                                        margin-left: 0.5vw;
                                                        padding-top: 1vh;
                                                        padding-bottom: 1vh;
                                                        border-radius: 5px;
                                                        padding-bottom: 2vh;

                                                }
                                        }
                                        .subBtnP{
                                                width: 20%;
                                                height: 15%;
                                                margin-left: 16%;
                                                background-color: #A1991D;
                                                border-radius: 5px;
                                                border: none;
                                                margin-top: 2%;
                                                margin-bottom: 4%;
                                        }
                                }
                        }

                }

        }
    
}
@media only screen and (max-width: 768px) {
        .dashboard{
                display: flex;
                flex-direction: column;
                background-color: #161616;
                height: 100vh;
                color: white;
                width: 100%;
                overflow-y: hidden;
                overflow-x: hidden;
                .dashboard-content{
                        margin-top: 20%;
                        width: 95%;
                        height: 100%;
                        margin-left: 5%;
                        overflow-y: scroll;
                        .userInfo{
                                margin-top: 5%;
                                margin-bottom: 10%;
                                width: 100%;
                                padding-left: 8%;
                                display: flex;
                                height: 10%;
                                img{
                                        height: 90%;
                                        margin-top: 1%;
                                        border-radius: 20px;
                                }
                                .details{
                                        margin-left: 2%;
                                        .name{
                                                padding-top: 5%;
                                                font-weight: 600;
                                                font-size: 20px;
                                                letter-spacing: 0.1px;
                                        }
                                        .class{
                                                font-size:10px;
                                                color:#BDBDBD;
                                                margin-top: -8%;
        
                                        }
                                }
                        }
                        .intro{
                                padding-left: 4%;
                                margin-top: 4%;
                                margin-bottom: 5%;
        
                                .heading{
                                        font-size: 20px;
                                        font-weight: 500;
                                        letter-spacing: 0.3px;
                                }
                                .desc{
                                        margin-top: -2%;
                                        font-size: 12px;
                                        font-weight: 400;
                                        color:#BDBDBD;
                                }
                        }
                        .hr{
                                width: 84%;
                                margin-left: 8%;
                                height: 0.4%;
                                border-radius: 15px;
                                background-color: #353535;
                        }
                        .dts{
                                width: 100%;
        
                                .hr{
                                        min-width: 84%;
                                        margin-left: 8%;
                                        padding: 0.11%;
                                        border-radius: 15px;
                                        background-color: #656565;
                                        border: none;
                                }
                                .detailsList{
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        width: 100%;
                                        height: max-content;
                                        .intro{
                                                width: 100%;
                                                padding-top: 1%;
                                                padding-bottom: 3%;
                                                background-color: #161616;
                        
                                                .heading{
                                                        font-size: 20px;
                                                        font-weight: 500;
                                                        letter-spacing: 0.3px;
                                                }
                                                .desc{
                                                        font-size: 12px;
                                                        font-weight: 400;
                                                        color:#BDBDBD;
                                                }
                                        }
                                        .col2{
                                                width: 100%;
                                                padding-top: 4%;
        
                                                .dtImg{
                                                        height: 100%;
                                                        display: flex;
                                                        flex-direction: column;
                                                        padding-bottom: 4%;

                                                        .profilePic{
                                                                height: 20vh;
                                                                // width: 10vw;
                                                                align-self: center;
                                                                margin-right: 10%;
                                                                border-radius: 15px;
                                                        }
        
                                                        img{
                                                                height: 75px;
                                                                margin-right: 10%;
                                                        }
                                                        label{
                                                                height: 150px;
                                                                width: 90%;
                                                                border-color: #353535;
                                                                border-style: solid;
                                                                border-width: 2px;
                                                                padding: 2%;
                                                                margin-left: 3%;
                                                                border-radius: 10px;
                                                                margin-top: 5%;
                                                                cursor: pointer;
        
                                                                img{
                                                                        height: 20%;
                                                                        width: 40%;
                                                                        margin-left: 30%;
                                                                        margin-top: 10%;
                                                                        margin-bottom: 10%;
        
                                                                }
                                                                p{
                                                                        font-size: 10px;
                                                                        width: 100%;
                                                                        text-align: center;
                                                                }
                                                        }
                                                }
                                                .dt{
                                                        display: flex;
                                                        height: max-content;
                                                        margin: 1%;
                                                        margin-left: 2%;
                                                        font-weight: 300;
                                                        .DH{
                                                                padding-left: 3%;
                                                                padding-right: 2%;
                                                                width: 20%;
                                                                height: 6vh;
                                                                border-width: 2px ;
                                                                border-color: #353535;
                                                                border-style: solid;
                                                                padding-top: 1.2vh;
                                                                border-top-left-radius: 5px;
                                                                border-bottom-left-radius: 5px;
                                                                font-size: 18px;
                                                        }
                                                        input{
                                                                padding-left: 3%;
                                                                padding-right: 1%;
                                                                min-width:60%;
                                                                border-width: 2px ;
                                                                border-color: #353535;
                                                                border-style: solid;
                                                                border-top-right-radius: 5px;
                                                                border-bottom-right-radius: 5px;
                                                                border-left: none;
                                                                background-color: transparent;
                                                                height: 6vh;
                                                                color: #BDBDBD;
                                                        }
                                                        .val{
                                                                padding-left: 3%;
                                                                padding-right: 1%; 
                                                                min-width:60%;
                                                                border-width: 2px ;
                                                                border-color: #353535;
                                                                border-style: solid;
                                                                padding-top: 1.2vh;
                                                                border-top-right-radius: 5px;
                                                                border-bottom-right-radius: 5px;
                                                                border-left: none;
                                                                font-size: 18px;
                                                        }
                                                        .val2{
                                                                padding-left: 3%;
                                                                padding-right: 1%; 
                                                                min-width:80%;
                                                                margin-left: 5%;
                                                                border-width: 2px ;
                                                                border-color: #353535;
                                                                border-style: solid;
                                                                padding-top: 0.8vh;
                                                                border-radius: 5px;
                                                                font-size: 18px;
                                                                margin-top: 2%;
                                                        }
                                                        button{
                                                                padding-left: 1%;
                                                                padding-right: 1%;
                                                                width: 10%;
                                                                background-color: transparent;
                                                                border-width: 2px ;
                                                                border-color: #353535;
                                                                border-style: solid;
                                                                height: 6vh;
                                                                margin-left: 1vw;
                                                                padding-top: 1vh;
                                                                padding-bottom: 1vh;
                                                                border-radius: 5px;
                                                        }
                                                }
                                                .subBtnP{
                                                        margin-top: 3vh;
                                                        width: 40%;
                                                        height: 5vh;
                                                        margin-left: 28%;
                                                }
                                        }
                                }
        
                        }
        
                }
            
        }
}

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
        .dashboard-content{
                .NonAvailabilityMsg{
                        margin-left: 20%;
                        font-size: 27px;
                }
        }
}