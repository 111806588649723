
@media only screen and (max-width: 400px) {   
    .profile{
        display: flex;
        flex-direction: column;
        background-color: #161616;
        height: 100vh;
        color: white;
        overflow-y: scroll;
        width: 100vw;

        .howAreYou{
            background-color: rgba(0, 0, 0, 0.512);
            height: 100vh;
            width: 100vw;
            position: fixed;
            z-index: 12;
            left: 0;
            top:0;

            .content{
                position: absolute;
                left: 10vw;
                width: 80vw;
                height: 45vh;
                background-color: #232323;
                border-radius: 10px;
                top: 10vh;
                padding: 3vh;
                display: flex;
                flex-direction: column;

                .Title{
                    font-size: 2vh;
                    width: 100%;
                    padding-left: 2vw;
                }
                .info{
                    text-align: center;
                    font-size: 1.3vh;
                    width: 85%;
                    margin-left: 7.5%;
                    color: #cecece;
                }
                .img1{
                    margin-left: 35%;
                    width: 30%;
                    margin-bottom: 3vh;
                }
                .btn{
                    width: 80%;
                    left: 10%;
                    padding: 1vh;
                    font-size: 1.5vh;
                    background-color: #F4E61B;
                    border-radius: 10px;
                    margin-top: 2vh;
                    position: absolute;
                    bottom: 5vh;
                }
                .main{
                    width: 85%;
                    margin-left: 7.5%;
                    background-color: #2C2C2C;
                    border-radius: 10px;
                    padding-top: 4%;
                    padding-bottom: 4%;
                    margin-bottom: 3vh;

                    .t2{
                        width:100%;
                        text-align: center;

                    }
                    .m2{
                        width: 90%;
                        margin-left: 5%;
                        padding: 4%;

                        .rng{
                            appearance: none;
                            width: 100%;
                            height: 10px;
                            background:linear-gradient(to right, #CC2334 0%, #EF4B3F 20%, #EF8035 40%, #EFB025 60%, #6EBF43 80%);
                            border-radius: 5px;
                        }

                        .imgs{
                            width: 100%;
                            img{
                                width: 16%;
                                margin-left: 2%;
                                margin-right: 2%;
                            }

                        }
                        .rng{
                            width: 100%;
                            margin-top: 3vh;
                        }
                    }
                }

                .why{
                    height: 20%;
                    width: 30%;
                    margin-left: 35%;
                    margin-top: 0%;
                    background-color: transparent;
                    img{
                        width: 55%;
                        margin-left: 22.5%;
                    }
                    p{
                        width: 100%;
                        text-align: center;
                        font-size: 1.2vh;
                        margin-top: 1vh;
                    }
                }
                .inpBox{
                    background-color: #2C2C2C;
                    border-color: #6C6C6C;
                    border-width: 2px;
                    border-style: solid;
                    width: 85%;
                    margin-left: 7.5%;
                    height: 35%;
                    margin-top: 5%;
                    border-radius: 10px;
                    color: #ffffff;
                    font-size: 1.4vh;
                }
                .con{
                    max-height: 35%;
                    width: 60%;
                    margin-left: 20%;
                    display: flex;
                    flex-direction: column;

                    img{
                        height: 90%;

                        margin-top: 5%;
                    }
                    .p1{
                        font-size: 2.5vh;
                        width: 100%;
                        text-align: center;
                    }
                }

            }

        }

        .analyticsPopUp{
            position: fixed;
            z-index: 4;
            height: 100vh;
            width: 100vw;
            background-color: #1616169e;
            overflow-y: scroll;
            padding-bottom: 5%;

            .content{
                display: flex;
                flex-direction: column;
                position: absolute;
                height: 90%;
                top: 5%;
                width: 90%;
                left: 5%;
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                padding: 2%;
                overflow-y: scroll;
                padding-bottom: 5%;

                .heading{
                    font-size: 2.5vh;
                    position: absolute;
                    width: 80%;
                    text-align: center;
                    display: flex;
                    padding-top: 2%;
                    
                    img{
                        margin-right: 3%;
                        margin-left: 20%;
                    }
                    button{
                        background-color: transparent;
                        border: none;
                        color: #CED8E1;
                        margin-top: -2%;
                        margin-left: 5%;
                    }
                }
                .graph{
                    
                    border-radius: 20px;
                    border: 1px solid #333;
                    background: #1E1E1E;
                    width: 95%;
                    margin: 2%;
                    padding-top: 8%;
                    padding-bottom: 2%;
                    height: 45%;
                    margin-top: 25%;
                    p{
                        width: 100%;
                        text-align: center;
                        color:#CED8E1;
                        margin-top: 4%;
                        font-size: 8px;
                    }
                }
                .strengthweakness{
                    width: 100%;
                    height: max-content;
                    margin-top: 5%;
                    margin-bottom: 5%;

                    .points{
                        border-radius: 20px;
                        border: 1px solid #333;
                        background: #1E1E1E;
                        width: 95%;
                        margin: 2%;
                        padding-top: 3%;
                        padding-bottom: 2%;
                        min-height: 100%;
                        max-height: 100%;
                        overflow-y: scroll;
                        .heading1{
                            font-size: 2.5vh;
                            width: 100%;
                            text-align: left;
                            font-weight: 500;
                            padding-left: 5%;
                            letter-spacing: -0.5px;
                        }
                        .allTps{
                            overflow-y: scroll;
                            height: 70%;

                            .topic{
                                padding-left: 5%;
                                .ts{
                                    padding: 2%;
                                    padding-left: 10%;
                                    margin-top:2%;
                                }
                                .div1{
                                    display: flex;
                                    border-radius: 15px;
                                    background: #232323;
                                    // display: flex;
                                    padding: 1.5%;
                                    margin-top: 2%;
                                    width: 90%;
                                    margin-left: 5%;
                                    font-size: 1.6vh;
                                    padding-top: 5%;
                                    padding-left: 5%;
                                }
                                p{
                                    width: 90%;
                                    overflow-x: hidden;

                                }
                                button{
                                    width: 15%;
                                    border-radius: 10px;
                                    background-color: #FCEE2173;
                                    border: none;
                                    font-size: 20px;
                                    font-weight: 600;
                                    height: 80%;
                                    margin-top: -2%;
                                    margin-right: 2%;
                                }
                            }
                        }
                        .allTps::-webkit-scrollbar {
                            width: 12px;               /* width of the entire scrollbar */
                          }
                          
                          .allTps::-webkit-scrollbar-track {
                            background:transparent;        /* color of the tracking area */
                          }
                          
                          .allTps::-webkit-scrollbar-thumb {
                            background-color:#FCEE2173;    /* color of the scroll thumb */
                            border-radius: 20px;       /* roundness of the scroll thumb */
                          }
                    }
                }
            }
            .content::-webkit-scrollbar {
                width: 0;               /* width of the entire scrollbar */
              }
              
              .content::-webkit-scrollbar-track {
                background:transparent;        /* color of the tracking area */
              }
              
              .content::-webkit-scrollbar-thumb {
                background-color:#FCEE2173;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
              }
        }

        .mainP{
            position: fixed;
            background-color: #16161686;
            height: 100vh;
            width: 100vw;

            .contentP{
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                width: 80%;
                margin-left: 10%;
                padding: 5%;
                margin-top: 30%;

                .heading{
                    color: #C2B825;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
                img{
                    width: 60%;
                    margin-left: 20%;
                }
                .p1{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .p2{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                button{
                    border-radius: 6px;
                    background-color: #F4E61B;
                    border: none;
                    padding: 2%;
                    width: 50%;
                    margin-left: 25%;
                    font-size: 10px;
                }
            }
        }
        
        .profileLeft{
            height: 100vh;
            width: 100vw;
            background-color: transparent;
            
            .userGreetings{
                margin-top: 30vh;
    
                height: 10vh;
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
    
                .text{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 2%;
                    .P1{
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 12px;
                        font-weight: 400;
                        width: 100%;
                        text-align: center;
                    }
                    .P2{
                        font-size:30px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        text-align: center;
                    }
                }
    
               .img{
                display: flex;
                    .mainImg{
                        height: 90%;
                        width: 20%;
                        margin-top: 2%;
                        margin-bottom: 2%;
                        border-radius: 15px;
                        margin-left: 40%;
                    }
                    #badge{
                        height:1vh;
                        border-radius:0px;
                        margin-left: -3vw;

                    }
                    #badge2{
                        height:3vh;
                        border-radius:0px;
                        margin-left: -5vw;
                        margin-top: -2vh;
                    }
                }
            }
            .cont1{
                display: flex;
                flex-direction: column;
                .quickStart{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    min-height: 26vh;
                    max-height: max-content;
                    width: 98%;
                    margin-left: 1%;
                    .heading{
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                    }
                    .chapDiv{
                        background-color: #1B1B1B;
                        border-radius: 10px;
                        width: 90%;
                        margin-left: 5%;
                        min-height: 80%;
                        max-height: max-content;
                        padding: 3%;
                        padding-bottom: 7%;
                        display: flex;
        
                        .info{
                            width: 85%;
                            height: 100%;
                            padding-left: 3%;
                            .name{
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .desc{
                                font-size: 10px;
                                font-weight: 400;
                                color: rgb(179, 185, 191);
                            }
                            .outer{
                                min-width: 100%;
                                padding: 0.2vh;
                                min-height: 1vh;
                                margin-top: 0;
                                background-color: #2B2B2B;
                                border-radius: 15px;
                                .inner{
                                    height: 0.8vh;
                                    background-color: #F3E61B;
                                    border-radius: 15px;
                                }
                            }
                        }
        
                        img{
                            height: 25%;
                            cursor: pointer;
                            margin-left: 10%;
                        }
                    }
                }
                .recommendedChaps{
        
                    height: 50vh;
                    width: 90%;
                    margin-left: 5%;
        
                    margin-top: 2vh;
                    .heading{
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                    }
                    .RecChaps{
                        width: 95%;
                        display: flex;
                        overflow-x: scroll;
                        width: 100% ;
                        height: 80%;
                        padding-bottom: 5%;
        
                        .RecChap{
                            margin-right: 4%;
                            height: 100%;
                            min-width: 70%;
                            background-color: #1B1B1B;
                            padding-top: 3%;
                            border-radius:15px;
        
                            .chapName{
                                margin-top: 2%;
                                width: 100%;
                                text-align: center;
                            }
                            img{
                                height: 30%;
                                width: 60%;
                                margin-left: 20%;
                                padding-top: 2%;
                                padding-bottom: 2%;
                                margin-top: 3%;
                                margin-bottom: 3%;
                                border: 1px solid #333;
                                background: #232323;
                                border-radius: 14px;
                            }
                            .topicName{
                                width: 100%;
                                text-align: center;
                                margin-top: 8%;
                            }
                            .Chapdesc{
                                width: 100%;
                                font-size: 8px;
                                text-align: center;
                            }
                            .start{
                                background-color: #F4E61B;
                                width: 50%;
                                margin-left: 25%;
                                height: 12%;
                                font-size: 1.8vh;
                                border: none;
                                border-radius: 12px;
                            }
                        }
                    }
                    .RecChaps::-webkit-scrollbar {
                        width: 7px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background:transparent;
                        border-radius: 11px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-thumb {
                        max-height: 50px;
                        background:yellow;
                        border-radius: 11px;
                      }
                }
        
            }
            
    
        }
    
        .profileRight{
            margin-top: 25vh;
            height: 100vh;
            width: 100vw;
    
    
    
            .performanceGraph{
                padding: 2%;
                padding-top: 5%;
                height: 40vh;
                width: 90%;
                margin-left: 5%;
                margin-top: 3vh;
                border-radius: 15px;
                background-color: #252525;
    
                .heading{
                    font-size: 2vh;
                    margin-left: 8%;
                    img{
                        margin-right: 1%;
                    }
                }
                .charts{
                    height: 80%;
                    width: 95%;
                    margin-left: 2.5%;
                    display: flex;
                    .y-axis{
                        display: flex;
                        flex-direction: column-reverse;
                        width: 2%;
                        margin-right: 5%;
                        margin-top: 5vh;
                        height: 80%;
                        font-size: 1.7vh;
                        color: rgb(179, 185, 191);
                        // background-color: #F4E61B;
                    }
    
                    .barComponent{
                        height: 95%;
                        margin-top: 1vh;
                        width: 8%;
                        border-radius: 20px;
                        .barOuter{
                            background-color: #34352D;
                            height: 90%;
                            width: 50%;
                            margin-left: 25%;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column-reverse;
        
                            .barInner{
                                background-color: #F4E61B;
                                border-radius: 20px;
                            }
                        }
                        p{
                            font-family: Roboto;
                            margin: 0;
                            margin-top: 2vh;
                            font-size: 1vh;
                            text-align: center;
                            font-style: normal;
                            font-weight: 400;
                            color: rgb(175, 180, 184);
                            // writing-mode: vertical-rl;
                        }
                        p:hover{
                            color: white;
                            cursor: pointer;
                        }
                    }
                }
            }
    
            .rightBottomSection{
    
                display: flex;
                flex-direction: column;
                width: 100%;
                height: max-content;
                padding-bottom: 8vh;
    
                .mentalHealth{
                    width: 90vw;
                    margin-right: 2%;
                    height: 40vh;
                    margin-top: 3.5vh;
                    margin-left: 5vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    .p1{
                        font-size: 2.2vh;
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        margin-top: 2%;
                    }
                    .p2{
                        font-size: 1.5vh;
                        width: 100%;
                        text-align: center;
                        margin: 0%;
                        color: #F3E61B;
                    }
                    .arc-circle {
                        width: 55%; /* Set the width and height as needed */
                        height: 62.5%;
                        margin-left: 25%;
                        padding-top: 2.5%;
                        padding-left: 2.5%;
                        margin-bottom: 2vh;
                        margin-top: 3vh;
                        background: conic-gradient(
                            #F3E61B 0% 25%, /* Starting and ending points of the arc */
                            transparent 25% 50%, /* Remaining part of the circle */
                            #F3E61B 50% 100%
                          );
                          border-radius: 50%; /* Make it a circle */
                          transform: rotate(180deg); /* Rotate the arc to start from the bottom */
                        .inner-circle {
                            background-color:rgba(231, 231, 231, 1);; /* Set the inner circle color */
                            border-radius: 50%; /* Make it a circle */
                            height: 95%;
                            width: 95%;
                            padding-top: 10%;
                            padding-left: 10%;
                            border-color: #161616;
                            border-width: 4%;
                            border-style: solid;
                            .inner-circle {
                                background-color:#222222; /* Set the inner circle color */
                                border-radius: 50%; /* Make it a circle */
                                height: 90%;
                                width: 90%;
                                padding-top: 25%;
                                transform: rotate(180deg); /* Rotate the arc to start from the bottom */
                                img{
                                    height: 20%;
                                    width: 20%;
                                    margin-left: 35%;
                                }
                                p{
                                    width: 90%;
                                    text-align: center;
                                    font-size: 2vh;
                                }
                                .score{
                                    color: #F4E61B;
                                    font-size: 3vh;
                                    margin-bottom: 0;
                                }
                              }
                          }
                    }
                    .CS{
                        font-size: 5vh;
                        margin-top: 20%;
                        text-align: center;
                        color: #F4E61B;
                        
                    }
                    .heading{
                        font-size: 2.4vh;
                        width: 100%;
                        text-align: center;
                        
                        img{
                            margin-right: 2%;
                            padding-bottom: 1%;
                        }
                    }
                }
                        
                .analytics{
                    width: 90vw;
                    margin-right: 2%;
                    height: 40vh;
                    margin-top: 3.5vh;
                    margin-left: 5vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    padding-top: 4%;
                    .heading{
                        font-size: 2.5vh;
                        display: flex;
                        padding-left: 5%;
                        
                        img{
                            margin-right: 3%;
                            padding-bottom: 4%;
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            color: #CED8E1;
                            margin-left: 40%;
                            font-size: 3vh;
                            margin-top: -5%;
                        }
                    }
                }
                .subscribeSection{
                    // background-color: blue;
                    height: 40vh;
                    width: 90vw;
                    margin-top: 1.5vh;
                    margin-left: 5vw;

                    img{
                        // position: absolute;
                        // height: 32%;
                        // margin-left: 4%;
                        // margin-top: 10%;
                        height: 60%;
                        margin-left: 22%;
                        margin-bottom: -60%;
                    }
                    .gradientDiv{
                        height: 70%;
                        margin-top: 25%;
                        border-radius: 20px;
                        background: linear-gradient(135deg, rgba(137, 121, 39, 0.25) 0%, rgba(189, 204, 148, 0.02) 100%);
    
                        p{
                            width: 70%;
                            margin-left: 15%;
                            padding-top: 17%;
                            font-size: 1.8vh;
                            text-align: center;
                        }
    
                        button{
                            height: 20%;
                            border-radius: 15px;
                            background-color: #F4E61B;
                            border: none;
                            font-weight: 500;
                            width: 40%;
                            margin-left: 30%;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:400px) and (max-width: 768px) {   
    .profile{
        display: flex;
        flex-direction: column;
        background-color: #161616;
        height: 100vh;
        color: white;
        overflow-y: scroll;
        width: 100vw;

        .howAreYou{
            background-color: rgba(0, 0, 0, 0.512);
            height: 100vh;
            width: 100vw;
            position: fixed;
            z-index: 12;
            left: 0;
            top:0;

            .content{
                position: absolute;
                left: 10vw;
                width: 80vw;
                height: 45vh;
                background-color: #232323;
                border-radius: 10px;
                top: 10vh;
                padding: 3vh;
                display: flex;
                flex-direction: column;

                .Title{
                    font-size: 2vh;
                    width: 100%;
                    padding-left: 2vw;
                }
                .info{
                    text-align: center;
                    font-size: 1.3vh;
                    width: 85%;
                    margin-left: 7.5%;
                    color: #cecece;
                }
                .img1{
                    margin-left: 35%;
                    width: 30%;
                    margin-bottom: 3vh;
                }
                .btn{
                    width: 80%;
                    left: 10%;
                    padding: 1vh;
                    font-size: 1.5vh;
                    background-color: #F4E61B;
                    border-radius: 10px;
                    margin-top: 2vh;
                    position: absolute;
                    bottom: 5vh;
                }
                .main{
                    width: 85%;
                    margin-left: 7.5%;
                    background-color: #2C2C2C;
                    border-radius: 10px;
                    padding-top: 4%;
                    padding-bottom: 4%;
                    margin-bottom: 3vh;

                    .t2{
                        width:100%;
                        text-align: center;

                    }
                    .m2{
                        width: 90%;
                        margin-left: 5%;
                        padding: 4%;

                        .rng{
                            appearance: none;
                            width: 100%;
                            height: 10px;
                            background:linear-gradient(to right, #CC2334 0%, #EF4B3F 20%, #EF8035 40%, #EFB025 60%, #6EBF43 80%);

                            border-radius: 5px;
                        }

                        .imgs{
                            width: 100%;
                            img{
                                width: 16%;
                                margin-left: 2%;
                                margin-right: 2%;
                            }

                        }
                        .rng{
                            width: 100%;
                            margin-top: 3vh;
                        }
                    }
                }

                .why{
                    height: 20%;
                    width: 30%;
                    margin-left: 35%;
                    margin-top: 0%;
                    background-color: transparent;
                    img{
                        width: 55%;
                        margin-left: 22.5%;
                    }
                    p{
                        width: 100%;
                        text-align: center;
                        font-size: 1.2vh;
                        margin-top: 1vh;
                    }
                }
                .inpBox{
                    background-color: #2C2C2C;
                    border-color: #6C6C6C;
                    border-width: 2px;
                    border-style: solid;
                    width: 85%;
                    margin-left: 7.5%;
                    height: 35%;
                    margin-top: 5%;
                    border-radius: 10px;
                    color: #ffffff;
                    font-size: 1.7vh;
                }
                .con{
                    max-height: 35%;
                    width: 60%;
                    margin-left: 20%;
                    display: flex;
                    flex-direction: column;

                    img{
                        height: 90%;

                        margin-top: 5%;
                    }
                    .p1{
                        font-size: 2.5vh;
                        width: 100%;
                        text-align: center;
                    }
                }

            }

        }

        .analyticsPopUp{
            position: fixed;
            z-index: 4;
            height: 100vh;
            width: 100vw;
            background-color: #1616169e;
            overflow-y: scroll;

            .content{
                display: flex;
                flex-direction: column;
                position: absolute;
                height: 90%;
                top: 5%;
                width: 90%;
                left: 5%;
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                padding: 2%;
                overflow-y: scroll;
                padding-bottom: 5%;

                .heading{
                    font-size: 2.5vh;
                    position: absolute;
                    width: 80%;
                    text-align: center;
                    display: flex;
                    padding-top: 2%;
                    
                    img{
                        margin-right: 3%;
                        margin-left: 20%;
                    }
                    button{
                        background-color: transparent;
                        border: none;
                        color: #CED8E1;
                        margin-top: -2%;
                        margin-left: 5%;
                    }
                }
                .graph{
                    
                    border-radius: 20px;
                    border: 1px solid #333;
                    background: #1E1E1E;
                    width: 95%;
                    margin: 2%;
                    padding-top: 8%;
                    padding-bottom: 2%;
                    height: 45%;
                    margin-top: 25%;
                    p{
                        width: 100%;
                        text-align: center;
                        color:#CED8E1;
                        margin-top: 4%;
                        font-size: 8px;
                    }
                }
                .strengthweakness{
                    width: 100%;
                    height: 40%;
                    margin-top: 5%;
                    // overflow-y: scroll;
                    height: max-content;

                    .points{
                        border-radius: 20px;
                        border: 1px solid #333;
                        background: #1E1E1E;
                        width: 95%;
                        margin: 2%;
                        padding-top: 3%;
                        padding-bottom: 2%;
                        min-height: 100%;
                        max-height: 100%;
                        overflow-y: scroll;
                        .heading1{
                            font-size: 2.5vh;
                            width: 100%;
                            text-align: left;
                            font-weight: 500;
                            padding-left: 5%;
                            letter-spacing: -0.5px;
                        }
                        .allTps{
                            overflow-y: scroll;
                            height: 70%;

                            .topic{
                                padding-left: 5%;
                                .ts{
                                    padding: 2%;
                                    padding-left: 10%;
                                    margin-top:2%;
                                }
                                .div1{
                                    display: flex;
                                    border-radius: 15px;
                                    background: #232323;
                                    // display: flex;
                                    padding: 1.5%;
                                    margin-top: 2%;
                                    width: 90%;
                                    margin-left: 5%;
                                    font-size: 1.6vh;
                                    padding-top: 5%;
                                    padding-left: 5%;
                                }
                                p{
                                    width: 90%;
                                    overflow-x: hidden;

                                }
                                button{
                                    width: 15%;
                                    border-radius: 10px;
                                    background-color: #FCEE2173;
                                    border: none;
                                    font-size: 20px;
                                    font-weight: 600;
                                }
                            }
                        }
                        .allTps::-webkit-scrollbar {
                            width: 12px;               /* width of the entire scrollbar */
                          }
                          
                          .allTps::-webkit-scrollbar-track {
                            background:transparent;        /* color of the tracking area */
                          }
                          
                          .allTps::-webkit-scrollbar-thumb {
                            background-color:#FCEE2173;    /* color of the scroll thumb */
                            border-radius: 20px;       /* roundness of the scroll thumb */
                          }
                    }
                }
            }
            .content::-webkit-scrollbar {
                width: 0;               /* width of the entire scrollbar */
              }
              
              .content::-webkit-scrollbar-track {
                background:transparent;        /* color of the tracking area */
              }
              
              .content::-webkit-scrollbar-thumb {
                background-color:#FCEE2173;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
              }
        }

        .mainP{
            position: fixed;
            background-color: #16161686;
            height: 100vh;
            width: 100vw;

            .contentP{
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                width: 80%;
                margin-left: 10%;
                padding: 5%;
                margin-top: 30%;

                .heading{
                    color: #C2B825;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
                img{
                    width: 60%;
                    margin-left: 20%;
                }
                .p1{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .p2{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                button{
                    border-radius: 6px;
                    background-color: #F4E61B;
                    border: none;
                    padding: 2%;
                    width: 50%;
                    margin-left: 25%;
                    font-size: 12px;
                }
            }
        }
        
        .profileLeft{
            height: 100vh;
            width: 100vw;
            background-color: transparent;
            
            .userGreetings{
                margin-top: 30vh;
    
                height: 10vh;
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
    
                .text{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 2%;
                    .P1{
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 12px;
                        font-weight: 400;
                        width: 100%;
                        text-align: center;
                    }
                    .P2{
                        font-size:30px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        text-align: center;
                    }
                }
               .img{
                display: flex;
                    .mainImg{
                        height: 90%;
                        width: 20%;
                        margin-top: 2%;
                        margin-bottom: 2%;
                        border-radius: 15px;
                        margin-left: 40%;
                    }
                    #badge{
                        height:1vh;
                        border-radius:0px;
                        margin-left: -3vw;
                    }
                    #badge2{
                        height:3vh;
                        border-radius:0px;
                        margin-left: -5vw;
                        margin-top: -2vh;
                    }
                }
            }
            .cont1{
                display: flex;
                flex-direction: column;
                .quickStart{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    min-height: 22vh;
                    max-height: max-content;
                    width: 98%;
                    margin-left: 1%;
                    .heading{
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                    }
                    .chapDiv{
                        background-color: #1B1B1B;
                        border-radius: 10px;
                        width: 90%;
                        margin-left: 5%;
                        padding: 3%;
                        display: flex;
                        min-height: 80%;
                        max-height: max-content;
                        padding-bottom: 6%;
        
                        .info{
                            width: 85%;
                            height: 100%;
                            padding-left: 3%;
                            .name{
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .desc{
                                font-size: 10px;
                                font-weight: 400;
                                color: rgb(179, 185, 191);
                            }
                            .outer{
                                min-width: 100%;
                                padding: 0.2vh;
                                min-height: 1vh;
                                margin-top: 0;
                                background-color: #2B2B2B;
                                border-radius: 15px;
                                .inner{
                                    height: 0.8vh;
                                    background-color: #F3E61B;
                                    border-radius: 15px;
                                }
                            }
                        }
        
                        img{
                            height: 25%;
                            cursor: pointer;
                            margin-left: 10%;
                        }
                    }
                }
                .recommendedChaps{
        
                    height: 50vh;
                    width: 90%;
                    margin-left: 5%;
        
                    margin-top: 2vh;
                    .heading{
                        font-size: 2vh;
                        width: 100%;
                        text-align: center;
                        justify-content: center;
                    }
                    .RecChaps{
                        width: 95%;
                        display: flex;
                        overflow-x: scroll;
                        width: 100% ;
                        height: 80%;
                        padding-bottom: 5%;
        
                        .RecChap{
                            margin-right: 4%;
                            height: 100%;
                            min-width: 70%;
                            background-color: #1B1B1B;
                            padding-top: 3%;
                            border-radius:15px;
        
                            .chapName{
                                margin-top: 2%;
                                width: 100%;
                                text-align: center;
                            }
                            img{
                                height: 30%;
                                width: 60%;
                                margin-left: 20%;
                                padding-top: 2%;
                                padding-bottom: 2%;
                                margin-top: 3%;
                                margin-bottom: 3%;
                                border: 1px solid #333;
                                background: #232323;
                                border-radius: 14px;
                            }
                            .topicName{
                                width: 100%;
                                text-align: center;
                                margin-top: 8%;
                            }
                            .Chapdesc{
                                width: 100%;
                                font-size: 8px;
                                text-align: center;
                            }
                            .start{
                                background-color: #F4E61B;
                                width: 50%;
                                margin-left: 25%;
                                height: 12%;
                                font-size: 1.8vh;
                                border: none;
                                border-radius: 12px;
                            }
                        }
                    }
                    .RecChaps::-webkit-scrollbar {
                        width: 7px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background:transparent;
                        border-radius: 11px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-thumb {
                        max-height: 50px;
                        background:yellow;
                        border-radius: 11px;
                      }
                }
        
            }
            
    
        }
    
        .profileRight{
            margin-top: 25vh;
            height: 100vh;
            width: 100vw;
    
    
    
            .performanceGraph{
                padding: 2%;
                padding-top: 5%;
                height: 40vh;
                width: 90%;
                margin-left: 5%;
                margin-top: 3vh;
                border-radius: 15px;
                background-color: #252525;
    
                .heading{
                    font-size: 2vh;
                    margin-left: 8%;
                    img{
                        margin-right: 1%;
                    }
                }
                .charts{
                    height: 80%;
                    width: 95%;
                    margin-left: 2.5%;
                    display: flex;
                    .y-axis{
                        display: flex;
                        flex-direction: column-reverse;
                        width: 2%;
                        margin-right: 5%;
                        margin-top: 5vh;
                        height: 80%;
                        font-size: 1.7vh;
                        color: rgb(179, 185, 191);
                        // background-color: #F4E61B;
                    }
    
                    .barComponent{
                        height: 95%;
                        margin-top: 1vh;
                        width: 8%;
                        border-radius: 20px;
                        .barOuter{
                            background-color: #34352D;
                            height: 90%;
                            width: 50%;
                            margin-left: 25%;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column-reverse;
        
                            .barInner{
                                background-color: #F4E61B;
                                border-radius: 20px;
                            }
                        }
                        p{
                            font-family: Roboto;
                            margin: 0;
                            margin-top: 2vh;
                            font-size: 1vh;
                            text-align: center;
                            font-style: normal;
                            font-weight: 400;
                            color: rgb(175, 180, 184);
                            // writing-mode: vertical-rl;
                        }
                        p:hover{
                            color: white;
                            cursor: pointer;
                        }
                    }
                }
            }
    
            .rightBottomSection{
    
                display: flex;
                flex-direction: column;
                width: 100%;
                height: max-content;
                padding-bottom: 8vh;
    
                .mentalHealth{
                    width: 90vw;
                    margin-right: 2%;
                    height: 40vh;
                    margin-top: 3.5vh;
                    margin-left: 5vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    .p1{
                        font-size: 2.2vh;
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        margin-top: 2%;
                    }
                    .p2{
                        font-size: 1.5vh;
                        width: 100%;
                        text-align: center;
                        margin: 0%;
                        color: #F3E61B;
                    }
                    .arc-circle {
                        width: 55%; /* Set the width and height as needed */
                        height: 55%;
                        margin-left: 22.5%;
                        padding-top: 2.5%;
                        padding-left: 2.5%;
                        margin-bottom: 2vh;
                        margin-top: 3vh;
                        background: conic-gradient(
                          #F3E61B 0% 25%, /* Starting and ending points of the arc */
                          transparent 25% 50%, /* Remaining part of the circle */
                          #F3E61B 50% 100%
                        );
                        border-radius: 50%; /* Make it a circle */
                        transform: rotate(180deg); /* Rotate the arc to start from the bottom */
                        .inner-circle {
                            background-color:rgba(231, 231, 231, 1);; /* Set the inner circle color */
                            border-radius: 50%; /* Make it a circle */
                            height: 95%;
                            width: 95%;
                            padding-top: 10%;
                            padding-left: 10%;
                            border-color: #161616;
                            border-width: 4%;
                            border-style: solid;
                            .inner-circle {
                                background-color:#222222; /* Set the inner circle color */
                                border-radius: 50%; /* Make it a circle */
                                height: 90%;
                                width: 90%;
                                padding-top: 25%;
                                transform: rotate(180deg); /* Rotate the arc to start from the bottom */
                                img{
                                    height: 20%;
                                    width: 20%;
                                    margin-left: 35%;
                                }
                                p{
                                    width: 90%;
                                    text-align: center;
                                    font-size: 2vh;
                                }
                                .score{
                                    color: #F4E61B;
                                    font-size: 3vh;
                                    margin-bottom: 0;
                                }
                              }
                          }
                    }
                    .CS{
                        font-size: 5vh;
                        margin-top: 20%;
                        text-align: center;
                        color: #F4E61B;
                        
                    }
                    .heading{
                        font-size: 2.4vh;
                        width: 100%;
                        text-align: center;
                        
                        img{
                            margin-right: 2%;
                            padding-bottom: 1%;
                        }
                    }
                }
                        
                .analytics{
                    width: 90vw;
                    margin-right: 2%;
                    height: 40vh;
                    margin-top: 3.5vh;
                    margin-left: 5vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    padding-top: 4%;
                    .heading{
                        font-size: 2.5vh;
                        display: flex;
                        padding-left: 5%;
                        
                        img{
                            margin-right: 3%;
                            padding-bottom: 4%;
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            color: #CED8E1;
                            margin-left: 40%;
                            font-size: 3vh;
                            margin-top: -5%;
                        }
                    }
                }
                .subscribeSection{
                    // background-color: blue;
                    height: 40vh;
                    width: 90vw;
                    margin-top: 1.5vh;
                    margin-left: 5vw;

                    img{
                        // position: absolute;
                        // height: 32%;
                        // margin-left: 4%;
                        // margin-top: 10%;
                        height: 60%;
                        margin-left: 22%;
                        margin-bottom: -60%;
                    }
                    .gradientDiv{
                        height: 70%;
                        margin-top: 25%;
                        border-radius: 20px;
                        background: linear-gradient(135deg, rgba(137, 121, 39, 0.25) 0%, rgba(189, 204, 148, 0.02) 100%);
    
                        p{
                            width: 70%;
                            margin-left: 15%;
                            padding-top: 17%;
                            font-size: 1.8vh;
                            text-align: center;
                        }
    
                        button{
                            height: 20%;
                            border-radius: 15px;
                            background-color: #F4E61B;
                            border: none;
                            font-weight: 500;
                            width: 40%;
                            margin-left: 30%;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
    .profile{
        display: flex;
        flex-direction: column;
        background-color: #161616;
        height: 100vh;
        color: white;
        overflow-y: scroll;
        width: 100vw;

        .howAreYou{
            background-color: rgba(0, 0, 0, 0.512);
            height: 100vh;
            width: 100vw;
            position: fixed;
            z-index: 12;
            left: 0;
            top:0;

            .content{
                position: absolute;
                left: 35vw;
                width: 30vw;
                height: 55vh;
                background-color: #232323;
                border-radius: 10px;
                top: 10vh;
                padding: 3vh;
                display: flex;
                flex-direction: column;

                .Title{
                    font-size: 3vh;
                    width: 100%;
                    padding-left: 2vw;
                }
                .info{
                    text-align: center;
                    font-size: 1.6vh;
                    width: 85%;
                    margin-left: 7.5%;
                    color: #cecece;
                }
                .img1{
                    margin-left: 35%;
                    width: 30%;
                    margin-bottom: 5vh;
                }
                .btn{
                    width: 80%;
                    left: 10%;
                    padding: 1vh;
                    font-size: 1.5vh;
                    background-color: #F4E61B;
                    border-radius: 10px;
                    margin-top: 2vh;
                    position: absolute;
                    bottom: 5vh;
                }
                .main{
                    width: 85%;
                    margin-left: 7.5%;
                    background-color: #2C2C2C;
                    border-radius: 10px;
                    padding-top: 4%;
                    padding-bottom: 4%;
                    margin-bottom: 3vh;

                    .t2{
                        width:100%;
                        text-align: center;

                    }
                    .m2{
                        width: 90%;
                        margin-left: 5%;
                        padding: 4%;

                        .rng{
                            appearance: none;
                            width: 100%;
                            height: 10px;
                            background:linear-gradient(to right, #CC2334 0%, #EF4B3F 20%, #EF8035 40%, #EFB025 60%, #6EBF43 80%);
                        }

                        .imgs{
                            width: 100%;
                            img{
                                width: 16%;
                                margin-left: 2%;
                                margin-right: 2%;
                            }

                        }
                        .rng{
                            width: 100%;
                            margin-top: 3vh;
                        }
                    }
                }

                .why{
                    height: 25%;
                    width: 30%;
                    margin-left: 35%;
                    background-color: transparent;
                    img{
                        width: 55%;
                        margin-left: 22.5%;
                    }
                    p{
                        width: 100%;
                        text-align: center;
                        margin-top: 1vh;
                    }
                }
                .inpBox{
                    background-color: #2C2C2C;
                    border-color: #6C6C6C;
                    border-width: 4px;
                    border-style: solid;
                    width: 85%;
                    margin-left: 7.5%;
                    height: 35%;
                    margin-top: 5%;
                    border-radius: 10px;
                    color: #ffffff;
                    font-size: 1.4vh;
                }
                .con{
                    max-height: 50%;
                    width: 60%;
                    margin-left: 20%;
                    display: flex;
                    flex-direction: column;

                    img{
                        height: 90%;

                        margin-top: 5%;
                    }
                    .p1{
                        font-size: 2.5vh;
                        width: 100%;
                        text-align: center;
                    }
                }

            }

        }

        .analyticsPopUp{
            position: fixed;
            z-index: 4;
            height: 100vh;
            width: 100vw;
            background-color: #1616169e;

            .content{
                display: flex;
                flex-direction: column;
                position: absolute;
                height: 90%;
                top: 5%;
                width: 80%;
                left: 5%;
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                padding: 2%;
                overflow-y: hidden;
                padding-bottom: 5%;

                .heading{
                    font-size: 3.5vh;
                    position: absolute;
                    width: 80%;
                    text-align: left;
                    display: flex;
                    
                    img{
                        margin-right: 3%;
                        margin-left: 30%;
                    }
                    button{
                        background-color: transparent;
                        border: none;
                        color: #CED8E1;
                    }
                }
                .graph{
                    
                    border-radius: 20px;
                    border: 1px solid #333;
                    background: #1E1E1E;
                    width: 55%;
                    margin: 2%;
                    padding-top: 4%;
                    padding-bottom: 2%;
                    height: 45%;
                    margin-top: 15%;
                    p{
                        width: 100%;
                        text-align: center;
                        color:#CED8E1;
                        margin-top: 4%;
                    }
                }
                .strengthweakness{
                    display: flex;
                    width: 100%;
                    height: 40%;
                    margin-top: 5%;

                    .points{
                        border-radius: 20px;
                        border: 1px solid #333;
                        background: #1E1E1E;
                        width: 100%;
                        margin: 2%;
                        padding-top: 3%;
                        padding-bottom: 2%;
                        min-height: 100%;
                        max-height: 100%;
                        .heading1{
                            font-size: 2.5vh;
                            width: 100%;
                            text-align: left;
                            font-weight: 500;
                            padding-left: 5%;
                            letter-spacing: -0.5px;
                        }
                        .allTps{
                            overflow-y: scroll;
                            height: 70%;
                            .topic{
                                width: 100%;
                                .ts{
                                    padding: 2%;
                                    padding-left: 10%;
                                    margin-top: 2%;
                                }
                                .div1{
                                    display: flex;
                                    border-radius: 15px;
                                    background: #232323;
                                    // display: flex;
                                    padding: 1.5%;
                                    margin-top: 2%;
                                    width: 90%;
                                    margin-left: 5%;
                                    font-size: 1.3vh;
                                }
                                p{
                                    width: 90%;
                                    overflow-x: hidden;
                                }
                                button{
                                    width: 15%;
                                    border-radius: 10px;
                                    background-color: #FCEE2173;
                                }
                            }
                        }
                        .allTps::-webkit-scrollbar {
                            width: 12px;               /* width of the entire scrollbar */
                          }
                          
                          .allTps::-webkit-scrollbar-track {
                            background:transparent;        /* color of the tracking area */
                          }
                          
                          .allTps::-webkit-scrollbar-thumb {
                            background-color:#FCEE2173;    /* color of the scroll thumb */
                            border-radius: 20px;       /* roundness of the scroll thumb */
                          }
                    }
                }
            }
            .content::-webkit-scrollbar {
                width: 12px;               /* width of the entire scrollbar */
              }
              
              .content::-webkit-scrollbar-track {
                background:transparent;        /* color of the tracking area */
              }
              
              .content::-webkit-scrollbar-thumb {
                background-color:#FCEE2173;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
              }
        }

        .mainP{
            position: fixed;
            background-color: #16161686;
            height: 100vh;
            width: 100vw;

            .contentP{
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                width: 80%;
                margin-left: 10%;
                padding: 5%;
                margin-top: 30%;

                .heading{
                    color: #C2B825;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
                img{
                    width: 60%;
                    margin-left: 20%;
                }
                .p1{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .p2{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                button{
                    border-radius: 6px;
                    background-color: #F4E61B;
                    border: none;
                    padding: 2%;
                    width: 50%;
                    margin-left: 25%;
                }
            }
        }
        
        .profileLeft{
            height: 100vh;
            width: 100vw;
            background-color: transparent;
            
            .userGreetings{
                margin-top: 20vh;
    
                height: 10vh;
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
    
                .text{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 2%;
                    .P1{
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 12px;
                        font-weight: 400;
                        width: 100%;
                        text-align: center;
                    }
                    .P2{
                        font-size:30px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        text-align: center;
                    }
                }
    
                img{
                    height: 90%;
                    width: 20%;
                    margin-top: 2%;
                    margin-bottom: 2%;
                    border-radius: 15px;
                    margin-left: 40%;
               }
            }
    
            .cont1{
                display: flex;
                width: 100vw;
                .quickStart{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    margin-left: 12vw;
                    min-height: 22vh;
                    max-height: max-content;
                    width: 50%;
                    .heading{
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                        margin-top: 20%;
                    }

                    .chapDiv{
                        background-color: #1B1B1B;
                        border-radius: 10px;
                        width: 97.5%;
                        margin-left: 5%;
                        min-height: 35%;
                        padding: 3%;
                        display: flex;
        
                        .info{
                            width: 85%;
                            height: 100%;
                            padding-left: 4%;
                            .name{
                                font-size: 14px;
                                font-weight: 600;
                            }
                            .desc{
                                font-size: 10px;
                                font-weight: 400;
                                color: rgb(179, 185, 191);
                            }
                            .outer{
                                min-width: 100%;
                                padding: 0.2vh;
                                min-height: 1vh;
                                margin-top: 0;
                                background-color: #2B2B2B;
                                border-radius: 15px;
                                .inner{
                                    height: 0.8vh;
                                    background-color: #F3E61B;
                                    border-radius: 15px;
                                }
                            }
                        }
        
                        img{
                            height: 25%;
                            cursor: pointer;
                            margin-left: 10%;
                        }
                    }
                }
                .recommendedChaps{
        
                    height: 40vh;
                    width: 50%;
                    margin-left: 5%;
        
                    margin-top: 2vh;
                    .heading{
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                    }
                    .RecChaps{
                        width: 95%;
                        display: flex;
                        overflow-x: scroll;
                        width: 100% ;
                        height: 80%;
                        padding-bottom: 5%;
        
                        .RecChap{
                            margin-right: 4%;
                            height: 100%;
                            min-width: 70%;
                            background-color: #1B1B1B;
                            padding-top: 3%;
                            border-radius:15px;
        
                            .chapName{
                                margin-top: 2%;
                                width: 100%;
                                text-align: center;
                            }
                            img{
                                height: 30%;
                                width: 60%;
                                margin-left: 20%;
                                padding-top: 2%;
                                padding-bottom: 2%;
                                margin-top: 3%;
                                margin-bottom: 3%;
                                border: 1px solid #333;
                                background: #232323;
                                border-radius: 14px;
                            }
                            .topicName{
                                width: 80%;
                                margin-left: 10%;
                                text-align: center;
                                margin-top: 8%;
                                font-size: 12px;
                            }
                            .Chapdesc{
                                width: 100%;
                                font-size: 8px;
                                text-align: center;
                            }
                            .start{
                                background-color: #F4E61B;
                                width: 50%;
                                margin-left: 25%;
                                height: 12%;
                                font-size: 1.8vh;
                                border: none;
                                border-radius: 12px;
                            }
                        }
                    }
                    .RecChaps::-webkit-scrollbar {
                        width: 7px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background:transparent;
                        border-radius: 11px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-thumb {
                        max-height: 50px;
                        background:yellow;
                        border-radius: 11px;
                      }
                }
            }
    
        }
    
        .profileRight{
            margin-top: 1vh;
            height: 100vh;
            width: 90vw;
            margin-left: 5vw;
    
    
    
            .performanceGraph{
                padding: 2%;
                padding-top: 5%;
                height: 40vh;
                width: 95%;
                margin-left: 7.5%;
                margin-top: 3vh;
                border-radius: 15px;
                background-color: #252525;
    
                .heading{
                    font-size: 2vh;
                    margin-left: 8%;
                    img{
                        margin-right: 1%;
                    }
                }
                .charts{
                    height: 80%;
                    width: 80%;
                    margin-left: 10%;
                    display: flex;
                    .y-axis{
                        display: flex;
                        flex-direction: column-reverse;
                        width: 2%;
                        margin-right: 10%;
                        margin-top: 5vh;
                        height: 80%;
                        font-size: 1.7vh;
                        color: rgb(179, 185, 191);
                        // background-color: #F4E61B;
                    }
    
                    .barComponent{
                        height: 95%;
                        margin-top: 1vh;
                        width: 8%;
                        border-radius: 20px;
                        margin-right: 3%;
                        .barOuter{
                            background-color: #34352D;
                            height: 90%;
                            width: 50%;
                            margin-left: 25%;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column-reverse;
        
                            .barInner{
                                background-color: #F4E61B;
                                border-radius: 20px;
                            }
                        }
                        p{
                            font-family: Roboto;
                            margin-top: 2vh;
                            font-size: 1.4vh;
                            text-align: center;
                            font-style: normal;
                            font-weight: 400;
                            color: rgb(179, 185, 191);
                        }
                        p:hover{
                            color: white;
                            cursor: pointer;
                        }
                    }
                }
            }
    
            .rightBottomSection{
    
                display: flex;
                flex-direction: row;
                width: 100%;
                height: max-content;
                padding-bottom: 8vh;
    
                .mentalHealth{
                    width: 46%;
                    margin-right: 4%;
                    height: 35vh;
                    margin-top: 3.5vh;
                    margin-left: 8vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    .p1{
                        font-size: 2.5vh;
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        margin-top: 2%;
                    }
                    .p2{
                        font-size: 1.75vh;
                        width: 100%;
                        text-align: center;
                        margin: 0%;
                        color: #F3E61B;
                    }
                    .arc-circle {
                        width: 75%; /* Set the width and height as needed */
                        height: 60%;
                        margin-left: 12.5%;
                        padding-top: 2.5%;
                        padding-left: 2.5%;
                        background: conic-gradient(
                            #F3E61B 0% 25%, /* Starting and ending points of the arc */
                            transparent 25% 50%, /* Remaining part of the circle */
                            #F3E61B 50% 100%
                          );
                          border-radius: 50%; /* Make it a circle */
                          transform: rotate(180deg); /* Rotate the arc to start from the bottom */
                        border-radius: 50%; /* Make it a circle */
                        .inner-circle {
                            background-color:rgba(231, 231, 231, 1);; /* Set the inner circle color */
                            border-radius: 50%; /* Make it a circle */
                            height: 95%;
                            width: 95%;
                            padding-top: 10%;
                            padding-left: 10%;                            
                            border-color: #161616;
                            border-width: 4%;
                            border-style: solid;

                            .inner-circle {
                                background-color:#222222; /* Set the inner circle color */
                                border-radius: 50%; /* Make it a circle */
                                height: 90%;
                                width: 90%;
                                padding-top: 25%;
                                transform: rotate(180deg); /* Rotate the arc to start from the bottom */
                                img{
                                    height: 20%;
                                    width: 20%;
                                    margin-left: 35%;
                                }
                                p{
                                    width: 90%;
                                    text-align: center;
                                    font-size: 2vh;
                                }
                                .score{
                                    color: #F4E61B;
                                    font-size: 3vh;
                                    margin-bottom: 0;
                                }
                              }
                          }
                    }
                    .CS{
                        font-size: 5vh;
                        margin-top: 20%;
                        text-align: center;
                        color: #F4E61B;
                        
                    }
                    .heading{
                        font-size: 2.5vh;
                        
                        img{
                            margin-right: 3%;
                        }
                    }
                }
                .analytics{
                    width: 46%;
                    height: 35vh;
                    margin-top: 3.5vh;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    .heading{
                        font-size: 2.5vh;
                        display: flex;
                        margin-bottom: 15%;
                        
                        img{
                            margin-right: 3%;
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            color: #CED8E1;
                            margin-left: 30%;
                            font-size: 2vh;
                        }
                    }
                }
                .subscribeSection{
                    // background-color: blue;
                    height: 35vh;
                    width: 90vw;
                    margin-top: 1.5vh;
                    margin-left: 5vw;

                    img{
                        // position: absolute;
                        // height: 32%;
                        // margin-left: 4%;
                        // margin-top: 10%;
                        height: 60%;
                        margin-left: 22%;
                        margin-bottom: -60%;
                    }
                    .gradientDiv{
                        height: 70%;
                        margin-top: 25%;
                        border-radius: 20px;
                        background: linear-gradient(135deg, rgba(137, 121, 39, 0.25) 0%, rgba(189, 204, 148, 0.02) 100%);
    
                        p{
                            width: 70%;
                            margin-left: 15%;
                            padding-top: 17%;
                            font-size: 1.4vh;
                            text-align: center;
                        }
    
                        button{
                            height: 20%;
                            border-radius: 15px;
                            background-color: #F4E61B;
                            border: none;
                            font-weight: 500;
                            width: 40%;
                            margin-left: 30%;
                        }
                    }
                }
            }
        }
    }

}
@media only screen and (min-width: 1024px){
    .profile{
        display: flex;
        direction: column;
        padding-left: 100px;
        background-color: #161616;
        height: 100vh;
        color: white;

        .howAreYou{
            background-color: rgba(0, 0, 0, 0.512);
            height: 100vh;
            width: 100vw;
            position: fixed;
            z-index: 12;
            left: 0;
            top:0;

            .content{
                position: absolute;
                left: 35vw;
                width: 30vw;
                height: 55vh;
                background-color: #232323;
                border-radius: 10px;
                top: 10vh;
                padding: 3vh;
                display: flex;
                flex-direction: column;

                .Title{
                    font-size: 3vh;
                    width: 100%;
                    padding-left: 2vw;
                }
                .info{
                    text-align: center;
                    font-size: 1.6vh;
                    width: 85%;
                    margin-left: 7.5%;
                    color: #cecece;
                }
                .img1{
                    margin-left: 35%;
                    width: 30%;
                    margin-bottom: 5vh;
                }
                .btn{
                    width: 80%;
                    left: 10%;
                    padding: 1vh;
                    font-size: 1.5vh;
                    background-color: #F4E61B;
                    border-radius: 10px;
                    margin-top: 2vh;
                    position: absolute;
                    bottom: 5vh;
                }
                .main{
                    width: 85%;
                    margin-left: 7.5%;
                    background-color: #2C2C2C;
                    border-radius: 10px;
                    padding-top: 4%;
                    padding-bottom: 4%;
                    margin-bottom: 3vh;

                    .t2{
                        width:100%;
                        text-align: center;

                    }
                    .m2{
                        width: 90%;
                        margin-left: 5%;
                        padding: 4%;

                        .rng{
                            appearance: none;
                            width: 100%;
                            height: 10px;
                            background: linear-gradient(to right, #CC2334 0%, #EF4B3F 20%, #EF8035 40%, #EFB025 60%, #6EBF43 80%);
                            border-radius: 5px;
                        }

                        .imgs{
                            width: 100%;
                            img{
                                width: 16%;
                                margin-left: 2%;
                                margin-right: 2%;
                            }

                        }
                        .rng{
                            width: 100%;
                            margin-top: 3vh;
                        }
                    }
                }

                .why{
                    height: 25%;
                    width: 30%;
                    margin-left: 35%;
                    background-color: transparent;
                    img{
                        width: 55%;
                        margin-left: 22.5%;
                    }
                    p{
                        width: 100%;
                        text-align: center;
                        margin-top: 1vh;
                    }
                }
                .inpBox{
                    background-color: #2C2C2C;
                    border-color: #6C6C6C;
                    border-width: 4px;
                    border-style: solid;
                    width: 85%;
                    margin-left: 7.5%;
                    height: 35%;
                    margin-top: 5%;
                    border-radius: 10px;
                    color: #ffffff;
                    font-size: 2.5vh;
                }
                .con{
                    max-height: 50%;
                    width: 60%;
                    margin-left: 20%;
                    display: flex;
                    flex-direction: column;

                    img{
                        height: 90%;

                        margin-top: 5%;
                    }
                    .p1{
                        font-size: 2.5vh;
                        width: 100%;
                        text-align: center;
                    }
                }

            }

        }

        .analyticsPopUp{
            position: fixed;
            z-index: 4;
            height: 100vh;
            width: 100vw;
            background-color: #1616169e;

            .content{
                display: flex;
                position: absolute;
                height: 90%;
                top: 5%;
                width: 80%;
                left: 5%;
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                padding: 2%;
                overflow-y: hidden;
                padding-bottom: 5%;

                .heading{
                    font-size: 3.5vh;
                    position: absolute;
                    width: 80%;
                    text-align: left;
                    display: flex;
                    
                    img{
                        margin-right: 3%;
                        margin-left: 40%;
                    }
                    button{
                        background-color: transparent;
                        border: none;
                        color: #CED8E1;
                    }
                }
                .graph{
                    
                    border-radius: 20px;
                    border: 1px solid #333;
                    background: #1E1E1E;
                    width: 30%;
                    margin: 2%;
                    padding-top: 4%;
                    padding-bottom: 2%;
                    height: 65%;
                    margin-top: 10%;
                    p{
                        width: 100%;
                        text-align: center;
                        color:#CED8E1;
                        margin-top: 4%;
                    }
                }
                .strengthweakness{
                    display: flex;
                    flex-direction: column;
                    width: 35%;
                    height: 90%;
                    margin-top: 5%;

                    .points{
                        border-radius: 20px;
                        border: 1px solid #333;
                        background: #1E1E1E;
                        width: 90%;
                        margin: 2%;
                        padding-top: 2%;
                        padding-bottom: 2%;
                        min-height: 50%;
                        max-height: 50%;
                        .heading1{
                            font-size: 3vh;
                            width: 100%;
                            text-align: left;
                            padding-left: 4%;
                            font-weight: 500;
                            letter-spacing: -0.5px;
                        }
                        .allTps{
                            overflow-y: scroll;
                            height: 70%;
                            width: 90%;
                            margin-left: 5%;
                            .topic{
                                width: 100%;
                                .div1{
                                    display: flex;
                                    border-radius: 15px;
                                    background: #232323;
                                    // display: flex;
                                    padding: 0%;
                                    margin-top: 2%;
                                    width: 90%;
                                    margin-left: 5%;
                                    font-size: 2.2vh;
                                    height: 6vh;
                                }
                                .ts{
                                    padding: 0%;
                                    padding-left: 10%;
                                    margin-top: 2%;
                                    height: max-content;
                                }
                                p{
                                    height: 3.4vh;
                                    margin-top: 1.2vh;
                                    padding-left: 5%;
                                    width: 90%;

                                    overflow-x: hidden;
                                }
                                button{
                                    width: 15%;
                                    height: 4vh;

                                    margin-top: 1vh;
                                    margin-right: 0.6vw;
                                    border-radius: 15px;
                                    background-color: #FCEE2173;
                                    border: none;
                                    font-size: 3vh;
                                    padding: 0%;
                                    img{
                                        width: 40%;
                                        height: 50%;
                                        margin-top: -14%;
                                    }
                                }
                            }
                        }
                        .allTps::-webkit-scrollbar {
                            width: 12px;               /* width of the entire scrollbar */
                          }
                          
                          .allTps::-webkit-scrollbar-track {
                            background:#2B2B2B;
                            border-radius: 20px;        /* color of the tracking area */
                          }
                          
                          .allTps::-webkit-scrollbar-thumb {
                            background-color:#FCEE2173;    /* color of the scroll thumb */
                            border-radius: 20px;       /* roundness of the scroll thumb */
                          }
                    }
                }
            }
            .content::-webkit-scrollbar {
                width: 12px;               /* width of the entire scrollbar */
              }
              
              .content::-webkit-scrollbar-track {
                background:transparent;        /* color of the tracking area */
              }
              
              .content::-webkit-scrollbar-thumb {
                background-color:#FCEE2173;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
              }
        }

        
        .profileLeft{
            height: 100vh;
            width: 35vw;
            background-color: transparent;
            

            .userGreetings{
                margin-top: 6%;
    
                height: 10vh;
                width: 92%;
                margin-left: 8%;
                display: flex;
                flex-direction: row;
    
                .text{
                    display: flex;
                    flex-direction: column;
                    width: 65%;
                    padding: 2%;
                    .P1{
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 2vh;
                        font-weight: 400;
                    }
                    .P2{
                        font-size:4.5vh;
                        font-weight: 400;
                        letter-spacing: 2px;
                    }
                }
                .img{
                    display: flex;
                    .mainImg{
                        height: 90%;
                        margin-top: 2%;
                        border-radius: 15px;
                        margin-left: 10%;
                    }
                    #badge{
                        // position:absolute;
                        height:1vh;
                        border-radius:0px;
                        // left:25vw;
                        // top:2vh;
                    }
                    #badge2{
                        // position:absolute;
                        height:3vh;
                        border-radius:0px;
                        margin-top: -2.2vh;
                        margin-left: -0.25vw;
                        // left:24vw;
                        // top:-2vh;
                    }
                }
            }
    
            .quickStart{
                margin-top: 4vh;
                margin-bottom: 2vh;
                height: 30vh;
                width: 90%;
                margin-left: 5%;
                .heading{
                    font-size: 3.5vh;
                    width: 90%;
                    margin-left: 5%;
                }
                .chapDiv{
                    background-color: #1B1B1B;
                    border-radius: 10px;
                    width: 90%;
                    margin-left: 5%;
                    height: 70%;
                    padding: 3%;
                    display: flex;
    
                    .info{
                        width: 80%;
                        height: 100%;
                        padding-left: 4%;
                        .name{
                            font-size: 1.8vh;
                            font-weight: 600;
                        }
                        .desc{
                            font-size: 1.4vh;
                            font-weight: 400;
                            color: rgb(179, 185, 191);
                        }
                        .outer{
                            min-width: 100%;
                            padding: 0.5%;
                            height: 8%;
                            margin-top: 0;
                            background-color: #2B2B2B;
                            border-radius: 15px;
                            .inner{
                                height: 90%;
                                background-color: #F3E61B;
                                border-radius: 15px;
                            }
                        }
                    }
    
                    img{
                        height: 40%;
                        cursor: pointer;
                        margin-left: 15%;
                    }
                }
            }
            .recommendedChaps{
    
                max-height: 45vh;
                width: 80%;
                margin-left: 10%;
    
                // margin-top: 1vh;
                .heading{
                    font-size: 2.5vh;
                    height: 8%;
                }
                .RecChaps{
                    width: 95%;
                    display: flex;
                    overflow-x: scroll;
                    width: 100% ;
                    height: 85%;
                    padding-bottom: 5%;
                    // background-color: antiquewhite;
    
                    .RecChap{
                        margin-right: 4%;
                        height: 100%;
                        max-width: 45%;
                        background-color: #1B1B1B;
                        padding-top: 3%;
                        border-radius: 25px;
    
                        .chapName{
                            margin-top: 2%;
                            width: 100%;
                            text-align: center;
                            font-size: 2.3vh;
                        }
                        img{
                            height: 34%;
                            width: 70%;
                            margin-left: 15%;
                            margin-top: 3%;
                            margin-bottom: 3%;
                            border: 1px solid #333;
                            background: #232323;
                            border-radius: 14px;
                        }
                        .topicName{
                            width: 100%;
                            text-align: center;
                            margin-top: 4%;
                            width: 85%;
                            margin-left: 7.5%;
                            font-size: 1.5vh;
                            font-weight: 400;
                        }
                        // .Chapdesc{
                        //     width: 100%;
                        //     font-size: 12px;
                        //     text-align: center;
                        // }
                        .start{
                            background-color: #F4E61B;
                            width: 40%;
                            margin-left: 30%;
                            height: 14%;
                            font-size: 1.6vh;
                            margin-top: -1%;
                            border: none;
                            border-radius: 20px;
                        }
                    }
                }
                .RecChaps::-webkit-scrollbar {
                    width: 7px;
                  }
                   
                .RecChaps::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background:transparent;
                    border-radius: 11px;
                  }
                   
                .RecChaps::-webkit-scrollbar-thumb {
                    max-height: 25px;
                    background:yellow;
                    border-radius: 11px;
                  }
            }
    
        }
    
        .profileRight{
            background: #1C1C1C;
            height: 100vh;
            width: 60vw;
    
    
    
            .performanceGraph{
                padding: 2%;
                padding-top: 3%;
                height: 50vh;
                width: 95%;
                margin-left: 2.5%;
                margin-top: 3vh;
                border-radius: 25px;
                background-color: #252525;
    
                .heading{
                    font-size: 3vh;
                    margin-left: 8%;
                    img{
                        margin-right: 1%;
                    }
                }
                .charts{
                    height: 80%;
                    width: 80%;
                    margin-left: 10%;
                    display: flex;
                    .y-axis{
                        display: flex;
                        flex-direction: column-reverse;
                        width: 2%;
                        margin-right: 5%;
                        margin-top: 1vh;
                        height: 95%;
                        color: rgb(179, 185, 191);
                        p{
                            height: 16.66667%;
                            font-size: 2.10vh;
                        }
                        // background-color: #F4E61B;
                    }
    
                    .barComponent{
                        height: 95%;
                        margin-top: 1.5vh;
                        width: 5%;
                        border-radius: 20px;
                        margin-right: 4%;
                        .barOuter{
                            background-color: #34352D;
                            height: 90%;
                            width: 50%;
                            margin-left: 25%;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column-reverse;
        
                            .barInner{
                                background-color: #F4E61B;
                                border-radius: 20px;
                            }
                        }
                        p{
                            // font-family: Roboto;
                            margin-top: 2vh;
                            font-size: 2vh;
                            text-align: center;
                            font-style: normal;
                            font-weight: 400;
                            color: rgb(179, 185, 191);
                        }
                        p:hover{
                            color: white;
                            cursor: pointer;
                        }
                    }
                }
            }
    
            .rightBottomSection{
    
                display: flex;
                width: 100%;
                height: 50%;
    
                .mentalHealth{
                    width: 45%;
                    margin-right: 2%;
                    height: 85%;
                    margin-top: 2.5%;
                    margin-left: 3%;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    .p1{
                        font-size: 3vh;
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        margin-top: 2%;
                    }
                    .p2{
                        font-size: 2vh;
                        width: 100%;
                        text-align: center;
                        margin: 0%;
                        color: #F3E61B;
                    }
                    .arc-circle {
                        width: 10vh; /* Set the width and height as needed */
                        height: 10vh;
                        align-self: center;
                        padding-top: 2.5%;
                        padding-left: 2.5%;
                        transform: rotate(180deg); /* Rotate the arc to start from the bottom */
                        border-radius: 50%; /* Make it a circle */
                        .inner-circle {
                            background-color:rgba(231, 231, 231, 1);; /* Set the inner circle color */
                            border-radius: 50%; /* Make it a circle */
                            height: 95%;
                            width: 95%;
                            padding-top: 10%;
                            padding-left: 10%;
                            border-color: #161616;
                            border-width: 4%;
                            border-style: solid;
                            
                            .inner-circle {
                                background-color:#222222; /* Set the inner circle color */
                                border-radius: 50%; /* Make it a circle */
                                height: 90%;
                                width: 90%;
                                padding-top: 25%;
                                transform: rotate(180deg); /* Rotate the arc to start from the bottom */
                                img{
                                    height: 20%;
                                    width: 20%;
                                    margin-left: 35%;
                                }
                                p{
                                    width: 90%;
                                    text-align: center;
                                    font-size: 2vh;
                                }
                                .score{
                                    color: #F4E61B;
                                    font-size: 3.5vh;
                                    margin-bottom: 1%;
                                    margin-top: -1.6vh;
                                    margin-bottom: 0;
                                }
                              }
                          }
                    }
                    .CS{
                        font-size: 5vh;
                        margin-top: 20%;
                        text-align: center;
                        color: #F4E61B;
                        
                    }
                    .heading{
                        font-size: 2.5vh;
                        
                        img{
                            margin-right: 3%;
                        }
                        
                    }
                }
                .analytics{
                    width: 45%;
                    margin-right: 2%;
                    height: 85%;
                    margin-top: 2.5%;
                    margin-left: 1%;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    .heading{
                        font-size: 2.5vh;
                        display: flex;
                        
                        img{
                            margin-right: 5%;
                            padding-bottom: 2%;
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            color: #CED8E1;
                            margin-left: 48%;
                            margin-top: -4%;
                        }
                    }
                }
                .subscribeSection{
                    // background-color: blue;
                    height: 85%;
                    width: 40%;
                    margin-top: 2.5%;
                    margin-left: 4%;
                    img{
                        position: absolute;
                        height: 32%;
                        margin-left: 4%;
                        margin-top: -1%;
                    }
                    .gradientDiv{
                        height: 70%;
                        margin-top: 25%;
                        border-radius: 20px;
                        background: linear-gradient(135deg, rgba(137, 121, 39, 0.25) 0%, rgba(189, 204, 148, 0.02) 100%);
    
                        p{
                            width: 70%;
                            margin-left: 15%;
                            padding-top: 17%;
                            font-size: 1.8vh;
                            text-align: center;
                        }
    
                        button{
                            height: 20%;
                            border-radius: 15px;
                            background-color: #F4E61B;
                            border: none;
                            font-weight: 500;
                            width: 40%;
                            margin-left: 30%;
                        }
                    }
                }
            }
        }
    }
}

