.errorPage{
    height: 100vh;
    width:100vw;
    background-color:#fbf5f5;

    img{
        position: absolute;
        left: 55%;
        height: 60%;
        width: 40%;
        padding-left: 10%;
        top:20%;
        border-width: 2px;
        border-style: solid;
        border-right: none;
        border-top: none;
        border-bottom: none;
    }

    .content{
        position: absolute;
        height: 50%;
        width: 25%;
        top:25%;
        left: 15%;
        .heading{
            font-size: 23px;
            padding-right: 2%;
            display: flex;
            margin-top: 0%;
            margin-bottom: 7%;
        }
        .link_main{
            background-color: rgb(23, 23, 23);
            text-decoration: none;
            color: #fbf5f5;
            padding: 4%;
            font-size: 13px;
            border-radius: 12px;
            margin-top: 10%;
            margin-left: 33%;
        }
        p{
            margin-top: 8%;
            font-size: 18px;
            margin-bottom: 3%;
        }
        .other_links{
            display: flex;
            flex-direction: column;

            .link{
                text-decoration: none;
                color: black;
            }

        }

    }
}

@media only screen and (max-width: 768px) {
    .errorPage{
    
        img{
            position: absolute;
            left: 5%;
            height: 40%;
            width: 90%;
            padding-left: 10%;
            top:5%;
            border-width: 2px;
            border-style: solid;
            border-right: none;
            border-top: none;
            border-left: none;
        }
    
        .content{
            height: 50%;
            width: 80%;
            top:50%;
            left: 10%;
            .heading{
                text-align: center;
            }
            .link_main{
                margin-left: 30%;
            }
    
        }
    }

}

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
    .errorPage{
    
        img{
            position: absolute;
            left: 25%;
            height: 32%;
            width: 50%;
            padding-left: 10%;
            top:5%;
            border-width: 2px;
            border-style: solid;
            border-right: none;
            border-top: none;
            border-left: none;
        }
    
        .content{
            height: 50%;
            width: 50%;
            top:50%;
            left: 25%;
            .heading{
                text-align: center;
            }
            .link_main{
                margin-left: 29%;
                font-size: 18px;
            }
    
        }
    }

}
