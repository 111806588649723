
@media only screen and (max-width: 768px) {
    .recognition {
    display: flex;
    flex-direction: column;
    background-color: #FCEE21;
    height: 100vh;
    .left-content {
        // padding: 0px 0px 0px 154px;
        background-color: #FCEE21;
        .card {
            height: calc(50vh);
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding: 0 11vw;
            background-color:transparent;
            border: none;
            .title {
                font-weight: 600;
                font-size: 8vw;
                line-height: 108.4%;
                max-width: 50vw;
                color: #000000;
                padding: 3%;
            }
            .subtitle {
              
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 3.4vw;
                line-height: 17.07px;
                max-width: 355vw;
                color: #252424;
                padding: 3%;
                width: 75%;
            }
        }
    }
    .right-content {
        height: 60vh;
        margin: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #F1E422;
        .startup-logo {
            width: 45vw;
            height: 141px;
        }
        .NIT{
            width: 100%;
            display: flex;
            img{
                width: 30%;
                margin-left: 10%;
            }
            .desc{
                width:50%;
                height: 100%;
                margin-left: 2%;
                height: 20%;
                margin-top: 9%;
                font-weight: 600;
            }
        }
    }
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
.recognition {
    display: flex;
    flex-direction: column;
    background-color: #FCEE21;
    height: 100vh;
    .left-content {
        background-color: #FCEE21;
        border: none;
        // padding: 0px 0px 0px 154px;
        .card {
            height: calc(50vh);
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding: 0 11vw;
            background-color:transparent;
            border: none;
            .title {
                font-weight: 600;
                font-size: 6vw;
                line-height: 108.4%;
                max-width: 400px;
                color: #000000;
                padding: 3%;
            }
            .subtitle {
              
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 2.4vw;
                line-height: 24px;
                max-width: 405px;
                color: #252424;
                padding: 3%;
            }
        }
    }
    .right-content {
        height: 40vh;
        margin: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #F1E422;
        .startup-logo {
            width: 331px;
            height: 141px;
        }
    }
}
}

@media only screen and (min-width: 1024px) {
.recognition {
display: flex;
flex-direction: row;
background-color: #FCEE21;
    .left-content {
        width: 50vw;
        background-color: #FCEE21;
        // flex: 7;
        // padding: 0px 0px 0px 154px;
        .card {
            height: calc(100vh);
            border: none;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding: 0 11vw;
            background-color:transparent;
                .title {
                    font-weight: 650;
                    font-size: 6vh;
                    line-height: 6.5vh;
                    color: #000000;
                    padding: 3%;
                    padding-left: 0%;
                    margin-left: 3%;
                }
                .subtitle {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2.2vh;
                    width: 80%;
                    line-height: 100%;
                    /* or 24px */
                    letter-spacing: 0.015em;
                    color: #252424;
                    padding: 2%;
                    padding-left: 0%;
                    margin-left: 3%;
                    margin-top: 3%;
                    margin-bottom: 5%;
                }
            }
        }
        .right-content {
            // flex: 5;
            width: 35vw;
            margin-left: 5vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: #F1E422;
            .startup-logo {
                width: 331px;
                height: 141px;
            }
            .NIT{
                width: 100%;
                display: flex;
                margin-top: 15%;
                img{
                    width: 20%;
                    margin-left: 20%;
                }
                .desc{
                    width:50%;
                    height: 100%;
                    margin-left: 0%;
                    height: 20%;
                    margin-top: 4%;
                    font-weight: 600;
                    font-size: 3vh;
                }
            }
        }
    }
}