// input[type=number]::-moz-number-spinbox {
//   /* Add your custom styles here */
//   /* For example, you can modify the background color and size */
//   background-color:transparent;
//   border: none;
//   /* You can also add additional styling properties like padding, border-radius, etc. */
// }
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
@media only screen and (max-width: 380px){
  .chapters{
    background-color: #1A1A1A;
    height: 100vh;
    width: 100vw;
    display: flex;
    .NavbarMob2{
      width:70%;
      height: 6%;
      left: 15%;
      top:10%;
      background-color: #1C1C1C;
      display: flex;
      margin-bottom: 5%;
      border-radius: 8px;
      z-index:2;
      position: fixed;

      button{
          width: 45%;
          margin-left: 3%;
          height: 80%;
          margin-top: 1.5%;
          background-color: #0E0E0E;
          color: #979797;
          border: none;
          border-radius: 8px;
          font-size: 13px;

      }
    }
    .container{
        width:100%;
        height: 40%;
        position: absolute;
        left: 0%;
        top:20%;

        h1{
            text-align: center;
            color: yellow;
        }

        .frame{
            width: 100%;
            height: 100%;
            border-radius: 25px;
            padding: 2%;

            .cover{
              width: 100vw;
              left: 0;
              position: fixed;
              // background-color: #161616;
              z-index: 6;
              height: 15vh;
            }

        }
        .controls{
            display: flex;
            width: 100%;
            margin-top: 35%;
            align-content: center;
            .nav-btn{
                background: #A1991D;
                width: 52%;
                height: 5vh;
                border-radius: 15px;
                font-size: 15px;
                margin-right: 1.5%;
                border:none;
                font-size: 600;
            }
        }
        .checkpoint {
          position: fixed;
          width: 100vw;
          height: 100vh;
          background-color:#151515;
          z-index: 3;
          left: 0%;
          top: 0%;
          overflow-y: scroll;

          img{
            margin-top: 1%;
            width: 98%;
            margin-left: 1%;
            height: 10%;
            border-radius: 15px;
          }
  
          .main {
            background-color: #1a1a1a;
            width: 90vw;
            margin-left: 5vw;
            height: max-content;
            padding-bottom: 4%;
            min-height: 80%;
            margin-top: 10vh;
            padding-left: 4%;
            padding-right: 4%;
            padding-top: 3vh;
            border-radius: 15px;
            overflow-y: hidden;
            margin-bottom: 3%;
  
            .submit-btn {
              background: #a1991d;
              margin-left: 35%;
              width: 30%;
              height: 6vh;
              border-radius: 15px;
              font-size: 17px;
              margin-right: 3%;
              border: none;
              font-size: 600;
            }
            .final {
              display: flex;
              p {
                color: #a8a6a6;
                font-size: 12px;
                margin-left: 3%;
                padding-top: 2%;
              }
              .nav-btn {
                background: #a1991d;
                width: 30%;
                height: 5vh;
                border-radius: 15px;
                font-size: 15px;
                margin-right: 3%;
                border: none;
                font-size:12px;
              }
            }
  
            .heading {
              padding-left: 3%;
              padding-top: 3%;
              width: 90vw;
              position: absolute;
              top: 15vh;
              left: 5vw;
              height: 8vh;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              background-color:#1f1f1f;
              color: azure;
              font-weight: 300;
              font-size: 25px;
            }
  
            .question-box {
              width: 90%;
              height: max-content;
              padding-bottom: 4%;
              margin-top: 7%;
              margin-left: 5%;
              padding-left: 4%;
              padding-right: 4%;
              border: none;
  
              .question {
                width: 100%;
                text-align: left;
                color: azure;
                font-size: 20px;
              }
              .options {
                width: 85%;
                margin-left: 7.5%;
                margin-top: 5%;
                height: max-content;
                background-color: transparent;
                cursor: pointer;
  
                p {
                  margin-top: 2%;
                  background-color: #3c3c3c;
                  padding: 2%;
                  padding-left: 4%;
                  border-radius: 7px;
                  color: azure;
                }
              }
              .options2 {
                width: 30%;
                margin-left: 35%;
                margin-top: 5%;
                height: max-content;
                background-color: transparent;
                cursor: pointer;
                form{
                  p{
                    input{
                      background-color: #A1991D;
                      margin-left: 10%;
                      width: 70%;
                      border: none;
                      border-radius: 15px;
                      height: 90%;
                      padding: 2%;
                      padding-left: 10%;
                    }
                    input:focus{
                      border: none;
                    }
                  }
                }
                p {
                  margin-top: 2%;
                  background-color: #3c3c3c;
                  padding: 2%;
                  padding-left: 4%;
                  border-radius: 15px;
                  color: azure;
                }
              }
              .option.selected {
                background-color: #a1991d; /* Apply yellow color to selected option */
                color: #1a1a1a;
              }
              .option.correct {
                background-color: limegreen; /* Apply green color to correct answer */
              }
              .option.wrong {
                background-color: red; /* Apply red color to wrong answer */
              }
              .answer {
                margin-top: 2%;
                background-color: rgb(9, 82, 9);
                padding: 2%;
                padding-left: 4%;
                border-radius: 7px;
                color: azure;
              }
            }
            .question-box::-webkit-scrollbar {
              width: 7px;
            }
  
            .question-box::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background: #2b2b2b;
              border-radius: 11px;
            }
  
            .question-box::-webkit-scrollbar-thumb {
              max-height: 50px;
              background: #a8a6a6;
              border-radius: 11px;
            }
          }
      }
    }
}

}

@media only screen and (min-width: 380px) and (max-width: 768px){
  .chapters{
    background-color: #1A1A1A;
    height: 100vh;
    width: 100vw;
    display: flex;
    .NavbarMob2{
      width:70%;
      height: 6%;
      left: 15%;
      top:10%;
      background-color: #1C1C1C;
      display: flex;
      margin-bottom: 5%;
      border-radius: 8px;
      z-index:2;
      position: fixed;

      button{
          width: 45%;
          margin-left: 3%;
          height: 80%;
          margin-top: 1.5%;
          background-color: #0E0E0E;
          color: #979797;
          border: none;
          border-radius: 8px;
          font-size: 13px;

      }
    }
    .container{
        width:100%;
        height: 40%;
        position: absolute;
        left: 0%;
        top:20%;

        h1{
            text-align: center;
            color: yellow;
        }

        .frame{
            width: 100%;
            height: 100%;
            border-radius: 25px;
            padding: 2%;

            .cover{
              width: 100vw;
              left: 0;
              position: fixed;
              // background-color: #161616;
              z-index: 6;
              height: 15vh;
            }

        }
        .controls{
            display: flex;
            width: 100%;
            margin-top: 25%;
            align-content: center;
            .nav-btn{
                background: #A1991D;
                width: 52%;
                height: 5vh;
                border-radius: 15px;
                font-size: 15px;
                margin-right: 1.5%;
                border:none;
                font-size: 600;
            }
        }
        .checkpoint {
          position: fixed;
          width: 100vw;
          height: 100vh;
          background-color:#151515;
          z-index: 3;
          left: 0%;
          top: 0%;
          overflow-y: scroll;

          img{
            margin-top: 1%;
            width: 98%;
            margin-left: 1%;
            height: 10%;
            border-radius: 15px;
          }
  
          .main {
            background-color: #1a1a1a;
            width: 90vw;
            margin-left: 5vw;
            height: max-content;
            padding-bottom: 4%;
            min-height: 80%;
            margin-top: 10vh;
            padding-left: 4%;
            padding-right: 4%;
            padding-top: 3vh;
            border-radius: 15px;
            overflow-y: hidden;
            margin-bottom: 3%;
  
            .submit-btn {
              background: #a1991d;
              margin-left: 35%;
              width: 30%;
              height: 6vh;
              border-radius: 15px;
              font-size: 17px;
              margin-right: 3%;
              border: none;
              font-size: 600;
            }
            .final {
              display: flex;
              p {
                color: #a8a6a6;
                font-size: 12px;
                margin-left: 6%;
                padding-top: 2%;
              }
              .nav-btn {
                background: #a1991d;
                width: 32%;
                height: 5vh;
                border-radius: 15px;
                font-size: 15px;
                margin-right: 3%;
                border: none;
                font-size: 12px;
              }
            }
  
            .heading {
              padding-left: 3%;
              padding-top: 3%;
              width: 90vw;
              position: absolute;
              top: 15vh;
              left: 5vw;
              height: 8vh;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              background-color:#1f1f1f;
              color: azure;
              font-weight: 300;
              font-size: 25px;
            }
  
            .question-box {
              width: 90%;
              height: max-content;
              padding-bottom: 4%;
              margin-top: 7%;
              margin-left: 5%;
              padding-left: 4%;
              padding-right: 4%;
              border: none;
  
              .question {
                width: 100%;
                text-align: left;
                color: azure;
                font-size: 20px;
              }
              .options {
                width: 85%;
                margin-left: 7.5%;
                margin-top: 5%;
                height: max-content;
                background-color: transparent;
                cursor: pointer;
  
                p {
                  margin-top: 2%;
                  background-color: #3c3c3c;
                  padding: 2%;
                  padding-left: 4%;
                  border-radius: 7px;
                  color: azure;
                }
              }
              .options2 {
                width: 30%;
                margin-left: 35%;
                margin-top: 5%;
                height: max-content;
                background-color: transparent;
                cursor: pointer;
                form{
                  p{
                    input{
                      background-color: #A1991D;
                      margin-left: 10%;
                      width: 70%;
                      border: none;
                      border-radius: 15px;
                      height: 90%;
                      padding: 2%;
                      padding-left: 10%;
                    }
                    input:focus{
                      border: none;
                    }
                  }
                }
                p {
                  margin-top: 2%;
                  background-color: #3c3c3c;
                  padding: 2%;
                  padding-left: 4%;
                  border-radius: 15px;
                  color: azure;
                }
              }
              .option.selected {
                background-color: #a1991d; /* Apply yellow color to selected option */
                color: #1a1a1a;
              }
              .option.correct {
                background-color: limegreen; /* Apply green color to correct answer */
              }
              .option.wrong {
                background-color: red; /* Apply red color to wrong answer */
              }
              .answer {
                margin-top: 2%;
                background-color: rgb(9, 82, 9);
                padding: 2%;
                padding-left: 4%;
                border-radius: 7px;
                color: azure;
              }
            }
            .question-box::-webkit-scrollbar {
              width: 7px;
            }
  
            .question-box::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background: #2b2b2b;
              border-radius: 11px;
            }
  
            .question-box::-webkit-scrollbar-thumb {
              max-height: 50px;
              background: #a8a6a6;
              border-radius: 11px;
            }
          }
      }
    }
}

}
@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .progressTab{ 
    margin-left: 13%;
    margin-top: 1%;
    width:85%;
    height: 25%;
    padding: 1%;
    border: 1px solid #252525;
    border-radius: 35px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    // padding: 20px 21px;
    // padding: 2%;
    gap: 19px;
    background-color: #161616;
    z-index: 1;
}
    .chapters{
        background-color: #1A1A1A;
        height: 100vh;
        width: 100vw;
        display: flex;
        .container{
            width:90%;
            height: 50%;
            position: absolute;
            left: 10%;
            top:25%;
    
            h1{
                text-align: center;
                color: yellow;
            }
    
            .frame{
                width: 100%;
                height: 80%;
                border-radius: 25px;
                padding: 2%;

                .cover{
                  width: 70vw;
                  position: fixed;
      
                  z-index: 6;
                  height: 15vh;
                }

            }

            .controls{
                display: flex;
                width: 70%;
                margin-left: 17%;
                align-content: center;
                margin-top: 13%;
                .nav-btn{
                    background: #A1991D;
                    width: 32%;
                    height: 5vh;
                    border-radius: 15px;
                    font-size: 15px;
                    margin-right: 3%;
                    border:none;
                    font-size: 600;
                }
            }


            .checkpoint {
              position: fixed;
              width: 100vw;
              height: 100vh;
              background-color:#151515;
              z-index: 3;
              left: 0%;
              top: 0%;
              overflow-y: scroll;
    
              img{
                margin-top: 1%;
                width: 98%;
                margin-left: 1%;
                height: 10%;
                border-radius: 15px;
              }
      
              .main {
                background-color: #1a1a1a;
                width: 90vw;
                margin-left: 6vw;
                height: max-content;
                padding-bottom: 4%;
                min-height: 80%;
                margin-top: 10vh;
                padding-left: 4%;
                padding-right: 4%;
                padding-top: 3vh;
                border-radius: 15px;
                overflow-y: hidden;
                margin-bottom: 3%;
      
                .submit-btn {
                  background: #a1991d;
                  margin-left: 35%;
                  width: 35%;
                  height: 6vh;
                  border-radius: 15px;
                  font-size: 17px;
                  margin-right: 3%;
                  border: none;
                  font-size: 600;
                }
                .final {
                  display: flex;
                  p {
                    color: #a8a6a6;
                    font-size: 16px;
                    margin-left: 6%;
                    padding-top: 2%;
                  }
                  .nav-btn {
                    background: #a1991d;
                    width: 32%;
                    height: 5vh;
                    border-radius: 15px;
                    font-size: 15px;
                    margin-right: 3%;
                    border: none;
                    font-size: 600;
                  }
                }
      
                .heading {
                  padding-left: 3%;
                  padding-top: 3%;
                  width: 90vw;
                  position: absolute;
                  top: 15vh;
                  left: 5vw;
                  height: 8vh;
                  border-top-left-radius: 15px;
                  border-top-right-radius: 15px;
                  background-color:#1f1f1f;
                  color: azure;
                  font-weight: 300;
                  font-size: 25px;
                }
      
                .question-box {
                  width: 90%;
                  height: max-content;
                  padding-bottom: 4%;
                  margin-top: 7%;
                  margin-left: 5%;
                  padding-left: 4%;
                  padding-right: 4%;
                  border: none;
      
                  .question {
                    width: 100%;
                    text-align: left;
                    color: azure;
                    font-size: 20px;
                  }
                  .options {
                    width: 85%;
                    margin-left: 7.5%;
                    margin-top: 5%;
                    height: max-content;
                    background-color: transparent;
                    cursor: pointer;
      
                    p {
                      margin-top: 2%;
                      background-color: #3c3c3c;
                      padding: 2%;
                      padding-left: 4%;
                      border-radius: 7px;
                      color: azure;
                    }
                  }
                  .options2 {
                    width: 40%;
                    margin-left: 35%;
                    margin-top: 5%;
                    min-height: 10%;
                    height: max-content;
                    background-color: transparent;
                    cursor: pointer;
                    form{
                      p{
                        input{
                          background-color: #A1991D;
                          margin-left: 10%;
                          width: 70%;
                          border: none;
                          border-radius: 15px;
                          height: 90%;
                          padding: 2%;
                          padding-left: 10%;
                        }
                        input:focus{
                          border: none;
                        }
                      }
                    }
                    p {
                      margin-top: 2%;
                      background-color: #3c3c3c;
                      padding: 2%;
                      padding-left: 4%;
                      border-radius: 15px;
                      color: azure;
                    }
                  }
                  .option.selected {
                    background-color: #a1991d; /* Apply yellow color to selected option */
                    color: #1a1a1a;
                  }
                  .option.correct {
                    background-color: limegreen; /* Apply green color to correct answer */
                  }
                  .option.wrong {
                    background-color: red; /* Apply red color to wrong answer */
                  }
                  .answer {
                    margin-top: 2%;
                    background-color: rgb(9, 82, 9);
                    padding: 2%;
                    padding-left: 4%;
                    border-radius: 7px;
                    color: azure;
                  }
                }
                .question-box::-webkit-scrollbar {
                  width: 7px;
                }
      
                .question-box::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                  background: #2b2b2b;
                  border-radius: 11px;
                }
      
                .question-box::-webkit-scrollbar-thumb {
                  max-height: 50px;
                  background: #a8a6a6;
                  border-radius: 11px;
                }
              }
          } 
        }
    }
}

@media only screen and (min-width: 1024px) {
    .chapters {
      background-color: #1a1a1a;
      height: 100vh;
      width: 100vw;
      display: flex;
      .container {
        width: 60%;
        height: 70%;
        position: absolute;
        left: 10%;
        top: 10%;
  
        h1 {
          text-align: center;
          color: yellow;
        }
  
        .frame {
          width: 100%;
          height: 100%;
          border-radius: 25px;
          overflow: auto;

          .cover{
            width: 70vw;
            position: fixed;

            z-index: 6;
            height: 15vh;
          }
        }
  
        .controls {
          display: flex;
          width: 60%;
          margin-left: 20%;
          align-content: center;
          margin-top: 6%;
          .nav-btn {
            background: #a1991d;
            width: 30%;
            height: 6vh;
            border-radius: 15px;
            font-size: 17px;
            margin-right: 3%;
            border: none;
            font-size: 600;
          }
        }
  
        .checkpoint {
          position: fixed;
          width: 100vw;
          height: 100vh;
          background-color:#151515;
          z-index: 3;
          left: 0%;
          top: 0%;
          overflow-y: scroll;

          img{
            margin-top: 1%;
            width: 97%;
            margin-left: 1.5%;
            height: 22%;
            border-radius: 20px;
          }
  
          .main {
            background-color: #1a1a1a;
            width: 60vw;
            margin-left: 20vw;
            height: max-content;
            padding-bottom: 4%;
            min-height: 80%;
            margin-top: 10vh;
            padding-left: 4%;
            padding-right: 4%;
            border-radius: 25px;
            overflow-y: hidden;
            margin-bottom: 3%;
  
            .submit-btn {
              background: #a1991d;
              margin-left: 35%;
              width: 40%;
              height: 6vh;
              border-radius: 15px;
              font-size: 17px;
              margin-right: 3%;
              border: none;
              font-size: 600;
            }
            .final {
              display: flex;
              p {
                color: #a8a6a6;
                font-size: 25px;
                margin-left: 8%;
              }
              .nav-btn {
                background: #a1991d;
                width: 32%;
                height: 5vh;
                border-radius: 15px;
                font-size: 15px;
                margin-right: 3%;
                border: none;
                font-size: 600;
              }
            }
  
            .heading {
              padding-left: 3%;
              padding-top: 0.5%;
              width: 60vw;
              position: absolute;
              top: 30vh;
              left: 20vw;
              height: 8vh;
              border-top-left-radius: 25px;
              border-top-right-radius: 25px;
              background-color:#1f1f1f;
              color: azure;
              font-weight: 300;
              font-size: 25px;
            }
  
            .question-box {
              width: 90%;
              height: max-content;
              padding-bottom: 4%;
              margin-top: 7%;
              margin-left: 5%;
              padding-left: 4%;
              padding-right: 4%;
              border: none;
  
              .question {
                width: 100%;
                text-align: left;
                color: azure;
                font-size: 20px;
              }
              .options {
                width: 60%;
                margin-left: 20%;
                margin-top: 5%;
                height: max-content;
                background-color: transparent;
                cursor: pointer;
              }
              
              .options p {
                margin-top: 2%;
                background-color: #3c3c3c;
                padding: 2%;
                padding-left: 4%;
                border-radius: 15px;
                color: azure;
              }
              
              .options p.selected {
                background-color: #a1991d;
                color: #1a1a1a;
              }
              
              .options p.correct {
                background-color: limegreen;
              }
              
              .options p.wrong {
                background-color:red;
                color: #ffffff;
                border-color: #ff8080;
              }
              .options2 {
                width: 30%;
                margin-left: 35%;
                margin-top: 5%;
                height: max-content;
                background-color: transparent;
                cursor: pointer;
                form{
                  p{
                    input{
                      background-color: #A1991D;
                      margin-left: 10%;
                      width: 70%;
                      border: none;
                      border-radius: 15px;
                      height: 90%;
                      padding: 2%;
                      padding-left: 10%;
                    }
                    input:focus{
                      border: none;
                    }
                  }
                }
                p {
                  margin-top: 2%;
                  background-color: #3c3c3c;
                  padding: 2%;
                  padding-left: 4%;
                  border-radius: 15px;
                  color: azure;
                }
              }
              .answer {
                margin-top: 2%;
                background-color: rgb(9, 82, 9);
                padding: 2%;
                padding-left: 4%;
                border-radius: 15px;
                color: azure;
              }
            }
            .question-box::-webkit-scrollbar {
              width: 7px;
            }
  
            .question-box::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background: #2b2b2b;
              border-radius: 11px;
            }
  
            .question-box::-webkit-scrollbar-thumb {
              max-height: 50px;
              background: #a8a6a6;
              border-radius: 11px;
            }
          }
        }
      }
    }
  }