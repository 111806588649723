
@media only screen and (max-width: 400px) {   
    .profile{
        display: flex;
        flex-direction: column;
        background-color: #161616;
        height: 100vh;
        color: white;
        overflow-y: scroll;
        width: 100vw;

        .ListView{
            position: fixed;
            z-index: 999;
            height: 100vh;
            width:100vw;
            background-color: #161616a3;

            .ListViewContent{
                position: absolute;
                width: 90vw;
                left: 5vw;
                top:7.5vh;
                height: 85vh;
                background-color: #464646;
                padding: 2vh;
                overflow-y: scroll;
                border-radius: 20px;

                .student{
                    display: flex;
                    padding-left: 2vw;
                    padding-right: 2vw;
                    background-color: #464646;
                    margin-top: 2%;
                    font-size: 10px;
                    height: 6vh;
                    border-width: 1px;
                    border-style: solid;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-color: black;

                    p:nth-of-type(1){
                        padding-left: 2vw;
                        padding-top: 1vh;
                        text-align: center;
                        width: 10%;
                        overflow-y: scroll;
                        height: 100%;

                    }
                    p:nth-of-type(2){
                        padding-left: 2vw;
                        text-align: center;
                        padding-top: 1vh;
                        width: 35%;
                        overflow-y: scroll;
                        height: 100%;
                    }
                    p:nth-of-type(3){
                        padding-left: 2vw;
                        text-align: center;
                        padding-top: 1vh;
                        width: 10%;
                        overflow-y: scroll;
                        height: 100%;
                    }
                    p:nth-of-type(4){
                        padding-left: 2vw;
                        text-align: center;
                        padding-top: 1vh;
                        width: 45%;
                        overflow-y: scroll;
                        height: 100%;
                    }
                    p::-webkit-scrollbar {
                        width: 0px;
                    }
                }
            }
            .ListViewContent::-webkit-scrollbar {
                width: 0px;
            }
        }

        .analyticsPopUp{
            position: fixed;
            z-index: 4;
            height: 100vh;
            width: 100vw;
            background-color: #1616169e;
            overflow-y: scroll;
            padding-bottom: 5%;

            .content{
                display: flex;
                flex-direction: column;
                position: absolute;
                height: 90%;
                top: 5%;
                width: 90%;
                left: 5%;
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                padding: 2%;
                overflow-y: scroll;
                padding-bottom: 5%;

                .heading{
                    font-size: 2.5vh;
                    position: absolute;
                    width: 80%;
                    text-align: center;
                    display: flex;
                    padding-top: 2%;
                    
                    img{
                        margin-right: 3%;
                        margin-left: 20%;
                    }
                    button{
                        background-color: transparent;
                        border: none;
                        color: #CED8E1;
                        margin-top: -2%;
                        margin-left: 5%;
                    }
                }
                .graph{
                    
                    border-radius: 20px;
                    border: 1px solid #333;
                    background: #1E1E1E;
                    width: 95%;
                    margin: 2%;
                    padding-top: 8%;
                    padding-bottom: 2%;
                    height: 45%;
                    margin-top: 25%;
                    p{
                        width: 100%;
                        text-align: center;
                        color:#CED8E1;
                        margin-top: 4%;
                        font-size: 8px;
                    }
                }
                .strengthweakness{
                    width: 100%;
                    height: max-content;
                    margin-top: 5%;
                    margin-bottom: 5%;

                    .points{
                        border-radius: 20px;
                        border: 1px solid #333;
                        background: #1E1E1E;
                        width: 95%;
                        margin: 2%;
                        padding-top: 3%;
                        padding-bottom: 2%;
                        min-height: 100%;
                        max-height: 100%;
                        overflow-y: scroll;
                        .heading1{
                            font-size: 2.5vh;
                            width: 100%;
                            text-align: left;
                            font-weight: 500;
                            padding-left: 5%;
                            letter-spacing: -0.5px;
                        }
                        .allTps{
                            overflow-y: scroll;
                            height: 70%;

                            .topic{
                                padding-left: 5%;
                                .ts{
                                    padding: 2%;
                                    padding-left: 10%;
                                    margin-top:2%;
                                }
                                .div1{
                                    display: flex;
                                    border-radius: 15px;
                                    background: #232323;
                                    // display: flex;
                                    padding: 1.5%;
                                    margin-top: 2%;
                                    width: 90%;
                                    margin-left: 5%;
                                    font-size: 1.6vh;
                                    padding-top: 5%;
                                    padding-left: 5%;
                                }
                                p{
                                    width: 90%;
                                    overflow-x: hidden;

                                }
                                button{
                                    width: 15%;
                                    border-radius: 10px;
                                    background-color: #FCEE2173;
                                    border: none;
                                    font-size: 20px;
                                    font-weight: 600;
                                    height: 80%;
                                    margin-top: -2%;
                                    margin-right: 2%;
                                }
                            }
                        }
                        .allTps::-webkit-scrollbar {
                            width: 12px;               /* width of the entire scrollbar */
                          }
                          
                          .allTps::-webkit-scrollbar-track {
                            background:transparent;        /* color of the tracking area */
                          }
                          
                          .allTps::-webkit-scrollbar-thumb {
                            background-color:#FCEE2173;    /* color of the scroll thumb */
                            border-radius: 20px;       /* roundness of the scroll thumb */
                          }
                    }
                }
            }
            .content::-webkit-scrollbar {
                width: 0;               /* width of the entire scrollbar */
              }
              
              .content::-webkit-scrollbar-track {
                background:transparent;        /* color of the tracking area */
              }
              
              .content::-webkit-scrollbar-thumb {
                background-color:#FCEE2173;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
              }
        }

        .mainP{
            position: fixed;
            background-color: #16161686;
            height: 100vh;
            width: 100vw;

            .contentP{
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                width: 80%;
                margin-left: 10%;
                padding: 5%;
                margin-top: 30%;

                .heading{
                    color: #C2B825;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
                img{
                    width: 60%;
                    margin-left: 20%;
                }
                .p1{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .p2{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                button{
                    border-radius: 6px;
                    background-color: #F4E61B;
                    border: none;
                    padding: 2%;
                    width: 50%;
                    margin-left: 25%;
                    font-size: 10px;
                }
            }
        }
        
        .profileLeft{
            height: 100vh;
            width: 100vw;
            background-color: transparent;
            
            .userGreetings{
                margin-top: 30vh;
    
                height: 10vh;
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
    
                .text{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 2%;
                    .P1{
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 12px;
                        font-weight: 400;
                        width: 100%;
                        text-align: center;
                    }
                    .P2{
                        font-size:30px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        text-align: center;
                    }
                }
    
                img{
                    height: 90%;
                    width: 20%;
                    margin-top: 2%;
                    margin-bottom: 2%;
                    border-radius: 15px;
                    margin-left: 40%;
               }
            }
            .cont1{
                display: flex;
                flex-direction: column;
                .classSelect{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    min-height: 12vh;
                    max-height: max-content;
                    width: 98%;
                    margin-left: 1%;
                    .heading2{
                        text-align: center;
                        width: 100%;
                        font-size: 18px;
                    }
                    .grades{
                        width: 90%;
                        // background-color: #BEB419;
                        margin-left: 5%;
                        min-height: 10vh;
                        max-height: max-content;
                        padding: 4%;
                        display: flex;
                        flex: 3;
                        .class{
                            background-color: #BEB419;
                            padding: 2%;
                            width: 30%;
                            height: 5vh;
                            color: black;
                            font-weight: 500;
                            text-align: center;
                            border-radius: 10px;
                            padding-top: 3%;
                            margin-left: 5%;
                        }
                    }

                    .heading{
                        font-size: 20px;
                        width: 100%;
                        text-align: center;
                    }
                    .chapDiv{
                        background-color: #1B1B1B;
                        border-radius: 10px;
                        width: 90%;
                        margin-left: 5%;
                        padding: 3%;
                        display: flex;
                        min-height: 80%;
                        max-height: max-content;
                        padding-bottom: 6%;
        
                        .info{
                            width: 85%;
                            height: 100%;
                            padding-left: 3%;
                            .name{
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .desc{
                                font-size: 10px;
                                font-weight: 400;
                                color: rgb(179, 185, 191);
                            }
                            .outer{
                                min-width: 100%;
                                padding: 0.2vh;
                                min-height: 1vh;
                                margin-top: 0;
                                background-color: #2B2B2B;
                                border-radius: 15px;
                                .inner{
                                    height: 0.8vh;
                                    background-color: #F3E61B;
                                    border-radius: 15px;
                                }
                            }
                        }
        
                        img{
                            height: 25%;
                            cursor: pointer;
                            margin-left: 10%;
                        }
                    }
                }
                .chapterSelect{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    min-height: 15vh;
                    max-height: max-content;
                    width: 98%;
                    margin-left: 1%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10vh;
                    .heading2{
                        text-align: center;
                        width: 50%;
                        font-size: 18px;
                        padding-top: 2%;
                        margin-right: 2%;
                    }
                    .selectDiv{
                        display: flex;
                        margin-bottom: 8%;

                        select{
                            width: 45%;
                            text-align: center;
                            background-color: #2E2E2E;
                            border-radius: 10px;
                            font-size: 18px;
                            border: none;
                            color: white;
                        }
                    }
                    .chapterView{
                        width: 90%;
                        margin-left: 5%;
                        background-color: #C2B825;
                        border: none;
                        border-radius: 6px;
                        cursor: pointer;
                        height: 6vh;
                        font-size: 18px;
                        margin-bottom: 3vh;
                    }
                }
                .sections{
                    margin-top: 2vh;
                    margin-bottom: 2vh;
                    min-height: 10vh;
                    max-height: max-content;
                    width: 98%;
                    margin-left: 1%;
                    .heading2{
                        text-align: center;
                        width: 100%;
                        font-size: 18px;
                    }
                    .sectionSelect{
                        width: 90%;
                        // background-color: #BEB419;
                        margin-left: 5%;
                        height: 20vh;
                        padding: 4%;
                        display: flex;
                        flex: 3;
                        .alert{
                            width: 100%;
                            font-size: 3vh;
                            text-align: center;
                            margin-top: 5vh;
                            font-weight: 400;
                        }
                        .section{
                            background-color: #BEB419;
                            padding: 2%;
                            width: 30%;
                            height: 5vh;
                            color: black;
                            font-weight: 500;
                            text-align: center;
                            border-radius: 10px;
                            padding-top: 3%;
                            margin-left: 5%;
                        }
                    }
                }
                .classSelect{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    min-height: 26vh;
                    max-height: max-content;
                    width: 98%;
                    margin-left: 1%;
                    .heading{
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                    }
                    .chapDiv{
                        background-color: #1B1B1B;
                        border-radius: 10px;
                        width: 90%;
                        margin-left: 5%;
                        min-height: 80%;
                        max-height: max-content;
                        padding: 3%;
                        padding-bottom: 7%;
                        display: flex;
        
                        .info{
                            width: 85%;
                            height: 100%;
                            padding-left: 3%;
                            .name{
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .desc{
                                font-size: 10px;
                                font-weight: 400;
                                color: rgb(179, 185, 191);
                            }
                            .outer{
                                min-width: 100%;
                                padding: 0.2vh;
                                min-height: 1vh;
                                margin-top: 0;
                                background-color: #2B2B2B;
                                border-radius: 15px;
                                .inner{
                                    height: 0.8vh;
                                    background-color: #F3E61B;
                                    border-radius: 15px;
                                }
                            }
                        }
        
                        img{
                            height: 25%;
                            cursor: pointer;
                            margin-left: 10%;
                        }
                    }
                }
                .recommendedChaps{
        
                    height: 50vh;
                    width: 90%;
                    margin-left: 5%;
        
                    margin-top: 2vh;
                    .heading{
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                    }
                    .RecChaps{
                        width: 95%;
                        display: flex;
                        overflow-x: scroll;
                        width: 100% ;
                        height: 80%;
                        padding-bottom: 5%;
        
                        .RecChap{
                            margin-right: 4%;
                            height: 100%;
                            min-width: 70%;
                            background-color: #1B1B1B;
                            padding-top: 3%;
                            border-radius:15px;
        
                            .chapName{
                                margin-top: 2%;
                                width: 100%;
                                text-align: center;
                            }
                            img{
                                height: 30%;
                                width: 60%;
                                margin-left: 20%;
                                padding-top: 2%;
                                padding-bottom: 2%;
                                margin-top: 3%;
                                margin-bottom: 3%;
                                border: 1px solid #333;
                                background: #232323;
                                border-radius: 14px;
                            }
                            .topicName{
                                width: 100%;
                                text-align: center;
                                margin-top: 8%;
                            }
                            .Chapdesc{
                                width: 100%;
                                font-size: 8px;
                                text-align: center;
                            }
                            .start{
                                background-color: #F4E61B;
                                width: 50%;
                                margin-left: 25%;
                                height: 12%;
                                font-size: 1.8vh;
                                border: none;
                                border-radius: 12px;
                            }
                        }
                    }
                    .RecChaps::-webkit-scrollbar {
                        width: 7px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background:transparent;
                        border-radius: 11px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-thumb {
                        max-height: 50px;
                        background:yellow;
                        border-radius: 11px;
                      }
                }
        
            }
            
    
        }
    
        .profileRight{
            margin-top: 30vh;
            height: 100vh;
            width: 100vw;
    
    
    
            .performanceGraph{
                padding: 2%;
                padding-top: 5%;
                height: 40vh;
                width: 90%;
                margin-left: 5%;
                margin-top: 3vh;
                border-radius: 15px;
                background-color: #252525;
    
                .heading{
                    font-size: 2vh;
                    margin-left: 8%;
                    img{
                        margin-right: 1%;
                    }
                }
                .charts{
                    height: 80%;
                    width: 95%;
                    margin-left: 2.5%;
                    display: flex;
                    .y-axis{
                        display: flex;
                        flex-direction: column-reverse;
                        width: 2%;
                        margin-right: 5%;
                        margin-top: 5vh;
                        height: 80%;
                        font-size: 1.7vh;
                        color: rgb(179, 185, 191);
                        // background-color: #F4E61B;
                    }
    
                    .barComponent{
                        height: 95%;
                        margin-top: 1vh;
                        width: 8%;
                        border-radius: 20px;
                        .barOuter{
                            background-color: #34352D;
                            height: 90%;
                            width: 50%;
                            margin-left: 25%;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column-reverse;
        
                            .barInner{
                                background-color: #F4E61B;
                                border-radius: 20px;
                            }
                        }
                        p{
                            font-family: Roboto;
                            margin: 0;
                            margin-top: 2vh;
                            font-size: 1vh;
                            text-align: center;
                            font-style: normal;
                            font-weight: 400;
                            color: rgb(175, 180, 184);
                            // writing-mode: vertical-rl;
                        }
                        p:hover{
                            color: white;
                            cursor: pointer;
                        }
                    }
                }
            }
    
            .rightBottomSection{
    
                display: flex;
                flex-direction: column;
                width: 100%;
                height: max-content;
                padding-bottom: 8vh;
    
                .mentalHealth{
                    width: 90vw;
                    margin-right: 2%;
                    height: 40vh;
                    margin-top: 3.5vh;
                    margin-left: 5vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;

                    .topicsContent{
                        height: 80%;
                        overflow-y: scroll;
                        padding-right: 3%;
                        .topic{
                            display: flex;
                            width: 95%;
                            margin-left: 5%;
                            background-color: #272727;
                            margin-bottom: 2%;
                            border-radius: 10px;
                            height: auto;
    
                            .tNo{
                                height: 95%;
                                margin-top: 2.5%;
                                margin-left: 2%;
                                background-color: #A1991D;
                                padding: 2%;
                                color: black;
                                border-radius: 6px;
                                width: 20%;
                                text-align: center;
                            }
                            .topicName{
                                padding-top: 1.5%;
                                padding-right: 1%;
                                display: flex; /* Use flexbox */
                                // justify-content: center; /* Center horizontally */
                                align-items: center; /* Center vertically */
                                margin-left: 2%;
                                font-size: 1.8vh;
                                width: 80%;
                                justify-self: center;
                            }
                        }
                    }
                    .topicsContent::-webkit-scrollbar {
                        background-color: #2B2B2B; /* or add it to the track */
                        width: 12px;
                        border-radius: 5px;
                    }
                    .topicsContent::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background: #BEB419;
                    }

                    .p1{
                        font-size: 2.2vh;
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        margin-top: 2%;
                    }
                    .p2{
                        font-size: 1.5vh;
                        width: 100%;
                        text-align: center;
                        margin: 0%;
                        color: #F3E61B;
                    }
                    .arc-circle {
                        width: 50%; /* Set the width and height as needed */
                        height: 55%;
                        margin-left: 25%;
                        padding-top: 2.5%;
                        padding-left: 2.5%;
                        margin-bottom: 2vh;
                        margin-top: 3vh;
                        background: conic-gradient(
                          #F3E61B 0% 25%, /* Starting and ending points of the arc */
                          transparent 25% 50%, /* Remaining part of the circle */
                          #F3E61B 50% 100%
                        );
                        border-radius: 50%; /* Make it a circle */
                        .inner-circle {
                            background-color:rgba(231, 231, 231, 1);; /* Set the inner circle color */
                            border-radius: 50%; /* Make it a circle */
                            height: 95%;
                            width: 95%;
                            padding-top: 10%;
                            padding-left: 10%;
                            .inner-circle {
                                background-color:#222222; /* Set the inner circle color */
                                border-radius: 50%; /* Make it a circle */
                                height: 90%;
                                width: 90%;
                                padding-top: 25%;
                                img{
                                    height: 20%;
                                    width: 20%;
                                    margin-left: 35%;
                                }
                                p{
                                    width: 90%;
                                    text-align: center;
                                    font-size: 2vh;
                                }
                              }
                          }
                    }
                    .CS{
                        font-size: 5vh;
                        margin-top: 20%;
                        text-align: center;
                        color: #F4E61B;
                        
                    }
                    .heading{
                        font-size: 2.4vh;
                        width: 100%;
                        text-align: center;
                        
                        img{
                            margin-right: 2%;
                            padding-bottom: 1%;
                        }
                    }
                }
                        
                .analytics{
                    width: 90vw;
                    margin-right: 2%;
                    height: 40vh;
                    margin-top: 3.5vh;
                    margin-left: 5vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    padding-top: 4%;

                    .topicsContent{
                        height: 80%;
                        overflow-y: scroll;
                        padding-right: 3%;
                        .topic{
                            display: flex;
                            width: 95%;
                            margin-left: 5%;
                            background-color: #272727;
                            margin-bottom: 2%;
                            border-radius: 10px;
                            height: auto;
    
                            .tNo{
                                height: 95%;
                                margin-top: 2.5%;
                                margin-left: 2%;
                                background-color: #A1991D;
                                padding: 2%;
                                color: black;
                                border-radius: 6px;
                                width: 20%;
                                text-align: center;
                            }
                            .topicName{
                                padding-top: 1.5%;
                                padding-right: 1%;
                                display: flex; /* Use flexbox */
                                // justify-content: center; /* Center horizontally */
                                align-items: center; /* Center vertically */
                                margin-left: 2%;
                                font-size: 1.8vh;
                                width: 80%;
                                justify-self: center;
                            }
                        }
                    }
                    .topicsContent::-webkit-scrollbar {
                        background-color: #2B2B2B; /* or add it to the track */
                        width: 12px;
                        border-radius: 5px;
                    }
                    .topicsContent::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background: #BEB419;
                    }


                    .heading{
                        font-size: 2.5vh;
                        display: flex;
                        padding-left: 5%;
                        
                        img{
                            margin-right: 3%;
                            padding-bottom: 4%;
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            color: #CED8E1;
                            margin-left: 40%;
                            font-size: 3vh;
                            margin-top: -5%;
                        }
                    }
                }
                .subscribeSection{
                    // background-color: blue;
                    height: 40vh;
                    width: 90vw;
                    margin-top: 1.5vh;
                    margin-left: 5vw;

                    img{
                        // position: absolute;
                        // height: 32%;
                        // margin-left: 4%;
                        // margin-top: 10%;
                        height: 60%;
                        margin-left: 22%;
                        margin-bottom: -60%;
                    }
                    .gradientDiv{
                        height: 70%;
                        margin-top: 25%;
                        border-radius: 20px;
                        background: linear-gradient(135deg, rgba(137, 121, 39, 0.25) 0%, rgba(189, 204, 148, 0.02) 100%);
    
                        p{
                            width: 70%;
                            margin-left: 15%;
                            padding-top: 17%;
                            font-size: 1.8vh;
                            text-align: center;
                        }
    
                        button{
                            height: 20%;
                            border-radius: 15px;
                            background-color: #F4E61B;
                            border: none;
                            font-weight: 500;
                            width: 40%;
                            margin-left: 30%;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:400px) and (max-width: 768px) {   
    .profile{
        display: flex;
        flex-direction: column;
        background-color: #161616;
        height: 100vh;
        color: white;
        overflow-y: scroll;
        width: 100vw;

        .ListView{
            position: fixed;
            z-index: 999;
            height: 100vh;
            width:100vw;
            background-color: #161616a3;

            .ListViewContent{
                position: absolute;
                width: 90vw;
                left: 5vw;
                top:7.5vh;
                height: 85vh;
                background-color: #464646;
                padding: 2vh;
                overflow-y: scroll;
                border-radius: 20px;

                .student{
                    display: flex;
                    padding-left: 2vw;
                    padding-right: 2vw;
                    background-color: #464646;
                    margin-top: 2%;
                    font-size: 10px;
                    height: 6vh;
                    border-width: 1px;
                    border-style: solid;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-color: black;

                    p:nth-of-type(1){
                        padding-left: 2vw;
                        padding-top: 1vh;
                        text-align: center;
                        width: 10%;
                        overflow-y: scroll;
                        height: 100%;

                    }
                    p:nth-of-type(2){
                        padding-left: 2vw;
                        text-align: center;
                        padding-top: 1vh;
                        width: 35%;
                        overflow-y: scroll;
                        height: 100%;
                    }
                    p:nth-of-type(3){
                        padding-left: 2vw;
                        text-align: center;
                        padding-top: 1vh;
                        width: 10%;
                        overflow-y: scroll;
                        height: 100%;
                    }
                    p:nth-of-type(4){
                        padding-left: 2vw;
                        text-align: center;
                        padding-top: 1vh;
                        width: 45%;
                        overflow-y: scroll;
                        height: 100%;
                    }
                    p::-webkit-scrollbar {
                        width: 0px;
                    }
                }
            }
            .ListViewContent::-webkit-scrollbar {
                width: 0px;
            }
        }
        

        .analyticsPopUp{
            position: fixed;
            z-index: 4;
            height: 100vh;
            width: 100vw;
            background-color: #1616169e;
            overflow-y: scroll;

            .content{
                display: flex;
                flex-direction: column;
                position: absolute;
                height: 90%;
                top: 5%;
                width: 90%;
                left: 5%;
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                padding: 2%;
                overflow-y: scroll;
                padding-bottom: 5%;

                .heading{
                    font-size: 2.5vh;
                    position: absolute;
                    width: 80%;
                    text-align: center;
                    display: flex;
                    padding-top: 2%;
                    
                    img{
                        margin-right: 3%;
                        margin-left: 20%;
                    }
                    button{
                        background-color: transparent;
                        border: none;
                        color: #CED8E1;
                        margin-top: -2%;
                        margin-left: 5%;
                    }
                }
                .graph{
                    
                    border-radius: 20px;
                    border: 1px solid #333;
                    background: #1E1E1E;
                    width: 95%;
                    margin: 2%;
                    padding-top: 8%;
                    padding-bottom: 2%;
                    height: 45%;
                    margin-top: 25%;
                    p{
                        width: 100%;
                        text-align: center;
                        color:#CED8E1;
                        margin-top: 4%;
                        font-size: 8px;
                    }
                }
                .strengthweakness{
                    width: 100%;
                    height: 40%;
                    margin-top: 5%;
                    // overflow-y: scroll;
                    height: max-content;

                    .points{
                        border-radius: 20px;
                        border: 1px solid #333;
                        background: #1E1E1E;
                        width: 95%;
                        margin: 2%;
                        padding-top: 3%;
                        padding-bottom: 2%;
                        min-height: 100%;
                        max-height: 100%;
                        overflow-y: scroll;
                        .heading1{
                            font-size: 2.5vh;
                            width: 100%;
                            text-align: left;
                            font-weight: 500;
                            padding-left: 5%;
                            letter-spacing: -0.5px;
                        }
                        .allTps{
                            overflow-y: scroll;
                            height: 70%;

                            .topic{
                                padding-left: 5%;
                                .ts{
                                    padding: 2%;
                                    padding-left: 10%;
                                    margin-top:2%;
                                }
                                .div1{
                                    display: flex;
                                    border-radius: 15px;
                                    background: #232323;
                                    // display: flex;
                                    padding: 1.5%;
                                    margin-top: 2%;
                                    width: 90%;
                                    margin-left: 5%;
                                    font-size: 1.6vh;
                                    padding-top: 5%;
                                    padding-left: 5%;
                                }
                                p{
                                    width: 90%;
                                    overflow-x: hidden;

                                }
                                button{
                                    width: 15%;
                                    border-radius: 10px;
                                    background-color: #FCEE2173;
                                    border: none;
                                    font-size: 20px;
                                    font-weight: 600;
                                }
                            }
                        }
                        .allTps::-webkit-scrollbar {
                            width: 12px;               /* width of the entire scrollbar */
                          }
                          
                          .allTps::-webkit-scrollbar-track {
                            background:transparent;        /* color of the tracking area */
                          }
                          
                          .allTps::-webkit-scrollbar-thumb {
                            background-color:#FCEE2173;    /* color of the scroll thumb */
                            border-radius: 20px;       /* roundness of the scroll thumb */
                          }
                    }
                }
            }
            .content::-webkit-scrollbar {
                width: 0;               /* width of the entire scrollbar */
              }
              
              .content::-webkit-scrollbar-track {
                background:transparent;        /* color of the tracking area */
              }
              
              .content::-webkit-scrollbar-thumb {
                background-color:#FCEE2173;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
              }
        }

        .mainP{
            position: fixed;
            background-color: #16161686;
            height: 100vh;
            width: 100vw;

            .contentP{
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                width: 80%;
                margin-left: 10%;
                padding: 5%;
                margin-top: 30%;

                .heading{
                    color: #C2B825;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
                img{
                    width: 60%;
                    margin-left: 20%;
                }
                .p1{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .p2{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                button{
                    border-radius: 6px;
                    background-color: #F4E61B;
                    border: none;
                    padding: 2%;
                    width: 50%;
                    margin-left: 25%;
                    font-size: 12px;
                }
            }
        }
        
        .profileLeft{
            height: 100vh;
            width: 100vw;
            background-color: transparent;
            
            .userGreetings{
                margin-top: 30vh;
    
                height: 10vh;
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
    
                .text{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 2%;
                    .P1{
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 12px;
                        font-weight: 400;
                        width: 100%;
                        text-align: center;
                    }
                    .P2{
                        font-size:30px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        text-align: center;
                    }
                }
    
                img{
                    height: 90%;
                    width: 20%;
                    margin-top: 2%;
                    margin-bottom: 2%;
                    border-radius: 15px;
                    margin-left: 40%;
               }
            }
            .cont1{
                display: flex;
                flex-direction: column;
                .classSelect{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    min-height: 22vh;
                    max-height: max-content;
                    width: 98%;
                    margin-left: 1%;
                    .heading2{
                        text-align: center;
                        width: 100%;
                        font-size: 18px;
                    }
                    .grades{
                        width: 90%;
                        // background-color: #BEB419;
                        margin-left: 5%;
                        min-height: 10vh;
                        max-height: max-content;
                        padding: 4%;
                        display: flex;
                        flex: 3;
                        .class{
                            background-color: #BEB419;
                            padding: 2%;
                            width: 30%;
                            height: 5vh;
                            color: black;
                            font-weight: 500;
                            text-align: center;
                            border-radius: 10px;
                            padding-top: 3%;
                            margin-left: 5%;
                        }
                    }

                    .heading{
                        font-size: 20px;
                        width: 100%;
                        text-align: center;
                    }
                    .chapDiv{
                        background-color: #1B1B1B;
                        border-radius: 10px;
                        width: 90%;
                        margin-left: 5%;
                        padding: 3%;
                        display: flex;
                        min-height: 80%;
                        max-height: max-content;
                        padding-bottom: 6%;
        
                        .info{
                            width: 85%;
                            height: 100%;
                            padding-left: 3%;
                            .name{
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .desc{
                                font-size: 10px;
                                font-weight: 400;
                                color: rgb(179, 185, 191);
                            }
                            .outer{
                                min-width: 100%;
                                padding: 0.2vh;
                                min-height: 1vh;
                                margin-top: 0;
                                background-color: #2B2B2B;
                                border-radius: 15px;
                                .inner{
                                    height: 0.8vh;
                                    background-color: #F3E61B;
                                    border-radius: 15px;
                                }
                            }
                        }
        
                        img{
                            height: 25%;
                            cursor: pointer;
                            margin-left: 10%;
                        }
                    }
                }
                .sections{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    min-height: 10vh;
                    max-height: max-content;
                    width: 98%;
                    margin-left: 1%;
                    .heading2{
                        text-align: center;
                        width: 100%;
                        font-size: 18px;
                    }
                    .sectionSelect{
                        width: 90%;
                        // background-color: #BEB419;
                        margin-left: 5%;
                        height: 20vh;
                        padding: 4%;
                        display: flex;
                        flex: 3;
                        .alert{
                            width: 100%;
                            font-size: 3vh;
                            text-align: center;
                            margin-top: 5vh;
                            font-weight: 400;
                        }
                        .section{
                            background-color: #BEB419;
                            padding: 2%;
                            width: 30%;
                            height: 5vh;
                            color: black;
                            font-weight: 500;
                            text-align: center;
                            border-radius: 10px;
                            padding-top: 3%;
                            margin-left: 5%;
                        }
                    }
                }
                .chapterSelect{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    min-height: 15vh;
                    max-height: max-content;
                    width: 98%;
                    margin-left: 1%;
                    display: flex;
                    flex-direction: column;
                    .heading2{
                        text-align: center;
                        width: 50%;
                        font-size: 18px;
                        padding-top: 2%;
                        margin-right: 2%;
                    }
                    .selectDiv{
                        display: flex;
                        margin-bottom: 8%;

                        select{
                            width: 45%;
                            text-align: center;
                            background-color: #2E2E2E;
                            border-radius: 10px;
                            font-size: 18px;
                            border: none;
                            color: white;
                        }
                    }
                    .chapterView{
                        width: 90%;
                        margin-left: 5%;
                        background-color: #C2B825;
                        border: none;
                        border-radius: 6px;
                        cursor: pointer;
                        height: 6vh;
                        font-size: 18px;
                        margin-bottom: 3vh;
                    }
                }
                .recommendedChaps{
        
                    height: 50vh;
                    width: 90%;
                    margin-left: 5%;
        
                    margin-top: 2vh;
                    .heading{
                        font-size: 2vh;
                        width: 100%;
                        text-align: center;
                        justify-content: center;
                    }
                    .RecChaps{
                        width: 95%;
                        display: flex;
                        overflow-x: scroll;
                        width: 100% ;
                        height: 80%;
                        padding-bottom: 5%;
        
                        .RecChap{
                            margin-right: 4%;
                            height: 100%;
                            min-width: 70%;
                            background-color: #1B1B1B;
                            padding-top: 3%;
                            border-radius:15px;
        
                            .chapName{
                                margin-top: 2%;
                                width: 100%;
                                text-align: center;
                            }
                            img{
                                height: 30%;
                                width: 60%;
                                margin-left: 20%;
                                padding-top: 2%;
                                padding-bottom: 2%;
                                margin-top: 3%;
                                margin-bottom: 3%;
                                border: 1px solid #333;
                                background: #232323;
                                border-radius: 14px;
                            }
                            .topicName{
                                width: 100%;
                                text-align: center;
                                margin-top: 8%;
                            }
                            .Chapdesc{
                                width: 100%;
                                font-size: 8px;
                                text-align: center;
                            }
                            .start{
                                background-color: #F4E61B;
                                width: 50%;
                                margin-left: 25%;
                                height: 12%;
                                font-size: 1.8vh;
                                border: none;
                                border-radius: 12px;
                            }
                        }
                    }
                    .RecChaps::-webkit-scrollbar {
                        width: 7px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background:transparent;
                        border-radius: 11px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-thumb {
                        max-height: 50px;
                        background:yellow;
                        border-radius: 11px;
                      }
                }
        
            }
            
    
        }
    
        .profileRight{
            margin-top: 30vh;
            height: 100vh;
            width: 100vw;
    
    
    
            .performanceGraph{
                padding: 2%;
                padding-top: 5%;
                height: 40vh;
                width: 90%;
                margin-left: 5%;
                margin-top: 3vh;
                border-radius: 15px;
                background-color: #252525;
    
                .heading{
                    font-size: 2vh;
                    margin-left: 8%;
                    img{
                        margin-right: 1%;
                    }
                }
                .charts{
                    height: 80%;
                    width: 95%;
                    margin-left: 2.5%;
                    display: flex;
                    .y-axis{
                        display: flex;
                        flex-direction: column-reverse;
                        width: 2%;
                        margin-right: 5%;
                        margin-top: 5vh;
                        height: 80%;
                        font-size: 1.7vh;
                        color: rgb(179, 185, 191);
                        // background-color: #F4E61B;
                    }
    
                    .barComponent{
                        height: 95%;
                        margin-top: 1vh;
                        width: 8%;
                        border-radius: 20px;
                        .barOuter{
                            background-color: #34352D;
                            height: 90%;
                            width: 50%;
                            margin-left: 25%;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column-reverse;
        
                            .barInner{
                                background-color: #F4E61B;
                                border-radius: 20px;
                            }
                        }
                        p{
                            font-family: Roboto;
                            margin: 0;
                            margin-top: 2vh;
                            font-size: 1vh;
                            text-align: center;
                            font-style: normal;
                            font-weight: 400;
                            color: rgb(175, 180, 184);
                            // writing-mode: vertical-rl;
                        }
                        p:hover{
                            color: white;
                            cursor: pointer;
                        }
                    }
                }
            }
    
            .rightBottomSection{
    
                display: flex;
                flex-direction: column;
                width: 100%;
                height: max-content;
                padding-bottom: 8vh;
    
                .mentalHealth{
                    width: 90vw;
                    margin-right: 2%;
                    height: 40vh;
                    margin-top: 3.5vh;
                    margin-left: 5vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;

                    .topicsContent{
                        height: 80%;
                        overflow-y: scroll;
                        padding-right: 3%;
                        .topic{
                            display: flex;
                            width: 95%;
                            margin-left: 5%;
                            background-color: #272727;
                            margin-bottom: 2%;
                            border-radius: 10px;
                            height: auto;
    
                            .tNo{
                                height: 95%;
                                margin-top: 2.5%;
                                margin-left: 2%;
                                background-color: #A1991D;
                                padding: 2%;
                                color: black;
                                border-radius: 6px;
                                width: 20%;
                                text-align: center;
                            }
                            .topicName{
                                padding-top: 1.5%;
                                padding-right: 1%;
                                display: flex; /* Use flexbox */
                                // justify-content: center; /* Center horizontally */
                                align-items: center; /* Center vertically */
                                margin-left: 2%;
                                font-size: 1.8vh;
                                width: 80%;
                                justify-self: center;
                            }
                        }
                    }
                    .topicsContent::-webkit-scrollbar {
                        background-color: #2B2B2B; /* or add it to the track */
                        width: 12px;
                        border-radius: 5px;
                    }
                    .topicsContent::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background: #BEB419;
                    }

                    .p1{
                        font-size: 2.2vh;
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        margin-top: 2%;
                    }
                    .p2{
                        font-size: 1.5vh;
                        width: 100%;
                        text-align: center;
                        margin: 0%;
                        color: #F3E61B;
                    }
                    .arc-circle {
                        width: 55%; /* Set the width and height as needed */
                        height: 55%;
                        margin-left: 22.5%;
                        padding-top: 2.5%;
                        padding-left: 2.5%;
                        margin-bottom: 2vh;
                        margin-top: 3vh;
                        background: conic-gradient(
                          #F3E61B 0% 25%, /* Starting and ending points of the arc */
                          transparent 25% 50%, /* Remaining part of the circle */
                          #F3E61B 50% 100%
                        );
                        border-radius: 50%; /* Make it a circle */
                        .inner-circle {
                            background-color:rgba(231, 231, 231, 1);; /* Set the inner circle color */
                            border-radius: 50%; /* Make it a circle */
                            height: 95%;
                            width: 95%;
                            padding-top: 10%;
                            padding-left: 10%;
                            .inner-circle {
                                background-color:#222222; /* Set the inner circle color */
                                border-radius: 50%; /* Make it a circle */
                                height: 90%;
                                width: 90%;
                                padding-top: 25%;
                                img{
                                    height: 20%;
                                    width: 20%;
                                    margin-left: 35%;
                                }
                                p{
                                    width: 90%;
                                    text-align: center;
                                    font-size: 2vh;
                                }
                              }
                          }
                    }
                    .CS{
                        font-size: 5vh;
                        margin-top: 20%;
                        text-align: center;
                        color: #F4E61B;
                        
                    }
                    .heading{
                        font-size: 2.4vh;
                        width: 100%;
                        text-align: center;
                        
                        img{
                            margin-right: 2%;
                            padding-bottom: 1%;
                        }
                    }
                }
                        
                .analytics{
                    width: 90vw;
                    margin-right: 2%;
                    height: 40vh;
                    margin-top: 3.5vh;
                    margin-left: 5vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;
                    padding-top: 4%;

                    .topicsContent{
                        height: 80%;
                        overflow-y: scroll;
                        padding-right: 3%;
                        .topic{
                            display: flex;
                            width: 95%;
                            margin-left: 5%;
                            background-color: #272727;
                            margin-bottom: 2%;
                            border-radius: 10px;
                            height: auto;
    
                            .tNo{
                                height: 95%;
                                margin-top: 2.5%;
                                margin-left: 2%;
                                background-color: #A1991D;
                                padding: 2%;
                                color: black;
                                border-radius: 6px;
                                width: 20%;
                                text-align: center;
                            }
                            .topicName{
                                padding-top: 1.5%;
                                padding-right: 1%;
                                display: flex; /* Use flexbox */
                                // justify-content: center; /* Center horizontally */
                                align-items: center; /* Center vertically */
                                margin-left: 2%;
                                font-size: 1.8vh;
                                width: 80%;
                                justify-self: center;
                            }
                        }
                    }
                    .topicsContent::-webkit-scrollbar {
                        background-color: #2B2B2B; /* or add it to the track */
                        width: 12px;
                        border-radius: 5px;
                    }
                    .topicsContent::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background: #BEB419;
                    }

                    .heading{
                        font-size: 2.5vh;
                        display: flex;
                        padding-left: 5%;
                        
                        img{
                            margin-right: 3%;
                            padding-bottom: 4%;
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            color: #CED8E1;
                            margin-left: 40%;
                            font-size: 3vh;
                            margin-top: -5%;
                        }
                    }
                }
                .subscribeSection{
                    // background-color: blue;
                    height: 40vh;
                    width: 90vw;
                    margin-top: 1.5vh;
                    margin-left: 5vw;

                    img{
                        // position: absolute;
                        // height: 32%;
                        // margin-left: 4%;
                        // margin-top: 10%;
                        height: 60%;
                        margin-left: 22%;
                        margin-bottom: -60%;
                    }
                    .gradientDiv{
                        height: 70%;
                        margin-top: 25%;
                        border-radius: 20px;
                        background: linear-gradient(135deg, rgba(137, 121, 39, 0.25) 0%, rgba(189, 204, 148, 0.02) 100%);
    
                        p{
                            width: 70%;
                            margin-left: 15%;
                            padding-top: 17%;
                            font-size: 1.8vh;
                            text-align: center;
                        }
    
                        button{
                            height: 20%;
                            border-radius: 15px;
                            background-color: #F4E61B;
                            border: none;
                            font-weight: 500;
                            width: 40%;
                            margin-left: 30%;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
    .profile{
        display: flex;
        flex-direction: column;
        background-color: #161616;
        height: 100vh;
        color: white;
        overflow-y: scroll;
        width: 100vw;

        .analyticsPopUp{
            position: fixed;
            z-index: 4;
            height: 100vh;
            width: 100vw;
            background-color: #1616169e;

            .content{
                display: flex;
                flex-direction: column;
                position: absolute;
                height: 90%;
                top: 5%;
                width: 80%;
                left: 5%;
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                padding: 2%;
                overflow-y: hidden;
                padding-bottom: 5%;

                .heading{
                    font-size: 3.5vh;
                    position: absolute;
                    width: 80%;
                    text-align: left;
                    display: flex;
                    
                    img{
                        margin-right: 3%;
                        margin-left: 30%;
                    }
                    button{
                        background-color: transparent;
                        border: none;
                        color: #CED8E1;
                    }
                }
                .graph{
                    
                    border-radius: 20px;
                    border: 1px solid #333;
                    background: #1E1E1E;
                    width: 55%;
                    margin: 2%;
                    padding-top: 4%;
                    padding-bottom: 2%;
                    height: 45%;
                    margin-top: 15%;
                    p{
                        width: 100%;
                        text-align: center;
                        color:#CED8E1;
                        margin-top: 4%;
                    }
                }
                .strengthweakness{
                    display: flex;
                    width: 100%;
                    height: 40%;
                    margin-top: 5%;

                    .points{
                        border-radius: 20px;
                        border: 1px solid #333;
                        background: #1E1E1E;
                        width: 100%;
                        margin: 2%;
                        padding-top: 3%;
                        padding-bottom: 2%;
                        min-height: 100%;
                        max-height: 100%;
                        .heading1{
                            font-size: 2.5vh;
                            width: 100%;
                            text-align: left;
                            font-weight: 500;
                            padding-left: 5%;
                            letter-spacing: -0.5px;
                        }
                        .allTps{
                            overflow-y: scroll;
                            height: 70%;
                            .topic{
                                width: 100%;
                                .ts{
                                    padding: 2%;
                                    padding-left: 10%;
                                    margin-top: 2%;
                                }
                                .div1{
                                    display: flex;
                                    border-radius: 15px;
                                    background: #232323;
                                    // display: flex;
                                    padding: 1.5%;
                                    margin-top: 2%;
                                    width: 90%;
                                    margin-left: 5%;
                                    font-size: 1.3vh;
                                }
                                p{
                                    width: 90%;
                                    overflow-x: hidden;
                                }
                                button{
                                    width: 15%;
                                    border-radius: 10px;
                                    background-color: #FCEE2173;
                                }
                            }
                        }
                        .allTps::-webkit-scrollbar {
                            width: 12px;               /* width of the entire scrollbar */
                          }
                          
                          .allTps::-webkit-scrollbar-track {
                            background:transparent;        /* color of the tracking area */
                          }
                          
                          .allTps::-webkit-scrollbar-thumb {
                            background-color:#FCEE2173;    /* color of the scroll thumb */
                            border-radius: 20px;       /* roundness of the scroll thumb */
                          }
                    }
                }
            }
            .content::-webkit-scrollbar {
                width: 12px;               /* width of the entire scrollbar */
              }
              
              .content::-webkit-scrollbar-track {
                background:transparent;        /* color of the tracking area */
              }
              
              .content::-webkit-scrollbar-thumb {
                background-color:#FCEE2173;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
              }
        }

        .mainP{
            position: fixed;
            background-color: #16161686;
            height: 100vh;
            width: 100vw;

            .contentP{
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                width: 80%;
                margin-left: 10%;
                padding: 5%;
                margin-top: 30%;

                .heading{
                    color: #C2B825;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
                img{
                    width: 60%;
                    margin-left: 20%;
                }
                .p1{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .p2{
                    margin-top: 10%;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                button{
                    border-radius: 6px;
                    background-color: #F4E61B;
                    border: none;
                    padding: 2%;
                    width: 50%;
                    margin-left: 25%;
                }
            }
        }
        
        .profileLeft{
            height: 100vh;
            width: 100vw;
            background-color: transparent;
            
            .userGreetings{
                margin-top: 20vh;
    
                height: 10vh;
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
    
                .text{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 2%;
                    .P1{
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 12px;
                        font-weight: 400;
                        width: 100%;
                        text-align: center;
                    }
                    .P2{
                        font-size:30px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        text-align: center;
                    }
                }
    
                img{
                    height: 90%;
                    width: 20%;
                    margin-top: 2%;
                    margin-bottom: 2%;
                    border-radius: 15px;
                    margin-left: 40%;
               }
            }
    
            .cont1{
                display: flex;
                width: 100vw;
                .classSelect{
                    margin-top: 4vh;
                    margin-bottom: 4vh;
                    margin-left: 12vw;
                    min-height: 22vh;
                    max-height: max-content;
                    width: 50%;
                    .heading{
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                        margin-top: 20%;
                    }

                    .chapDiv{
                        background-color: #1B1B1B;
                        border-radius: 10px;
                        width: 97.5%;
                        margin-left: 5%;
                        min-height: 35%;
                        padding: 3%;
                        display: flex;
        
                        .info{
                            width: 85%;
                            height: 100%;
                            padding-left: 4%;
                            .name{
                                font-size: 14px;
                                font-weight: 600;
                            }
                            .desc{
                                font-size: 10px;
                                font-weight: 400;
                                color: rgb(179, 185, 191);
                            }
                            .outer{
                                min-width: 100%;
                                padding: 0.2vh;
                                min-height: 1vh;
                                margin-top: 0;
                                background-color: #2B2B2B;
                                border-radius: 15px;
                                .inner{
                                    height: 0.8vh;
                                    background-color: #F3E61B;
                                    border-radius: 15px;
                                }
                            }
                        }
        
                        img{
                            height: 25%;
                            cursor: pointer;
                            margin-left: 10%;
                        }
                    }
                }
                .recommendedChaps{
        
                    height: 40vh;
                    width: 50%;
                    margin-left: 5%;
        
                    margin-top: 2vh;
                    .heading{
                        font-size: 18px;
                        width: 100%;
                        text-align: center;
                    }
                    .RecChaps{
                        width: 95%;
                        display: flex;
                        overflow-x: scroll;
                        width: 100% ;
                        height: 80%;
                        padding-bottom: 5%;
        
                        .RecChap{
                            margin-right: 4%;
                            height: 100%;
                            min-width: 70%;
                            background-color: #1B1B1B;
                            padding-top: 3%;
                            border-radius:15px;
        
                            .chapName{
                                margin-top: 2%;
                                width: 100%;
                                text-align: center;
                            }
                            img{
                                height: 30%;
                                width: 60%;
                                margin-left: 20%;
                                padding-top: 2%;
                                padding-bottom: 2%;
                                margin-top: 3%;
                                margin-bottom: 3%;
                                border: 1px solid #333;
                                background: #232323;
                                border-radius: 14px;
                            }
                            .topicName{
                                width: 80%;
                                margin-left: 10%;
                                text-align: center;
                                margin-top: 8%;
                                font-size: 12px;
                            }
                            .Chapdesc{
                                width: 100%;
                                font-size: 8px;
                                text-align: center;
                            }
                            .start{
                                background-color: #F4E61B;
                                width: 50%;
                                margin-left: 25%;
                                height: 12%;
                                font-size: 1.8vh;
                                border: none;
                                border-radius: 12px;
                            }
                        }
                    }
                    .RecChaps::-webkit-scrollbar {
                        width: 7px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background:transparent;
                        border-radius: 11px;
                      }
                       
                    .RecChaps::-webkit-scrollbar-thumb {
                        max-height: 50px;
                        background:yellow;
                        border-radius: 11px;
                      }
                }
            }
    
        }
    
        .profileRight{
            margin-top: 1vh;
            height: 100vh;
            width: 90vw;
            margin-left: 5vw;
    
    
    
            .performanceGraph{
                padding: 2%;
                padding-top: 5%;
                height: 40vh;
                width: 95%;
                margin-left: 7.5%;
                margin-top: 3vh;
                border-radius: 15px;
                background-color: #252525;
    
                .heading{
                    font-size: 2vh;
                    margin-left: 8%;
                    img{
                        margin-right: 1%;
                    }
                }
                .charts{
                    height: 80%;
                    width: 80%;
                    margin-left: 10%;
                    display: flex;
                    .y-axis{
                        display: flex;
                        flex-direction: column-reverse;
                        width: 2%;
                        margin-right: 10%;
                        margin-top: 5vh;
                        height: 80%;
                        font-size: 1.7vh;
                        color: rgb(179, 185, 191);
                        // background-color: #F4E61B;
                    }
    
                    .barComponent{
                        height: 95%;
                        margin-top: 1vh;
                        width: 8%;
                        border-radius: 20px;
                        margin-right: 3%;
                        .barOuter{
                            background-color: #34352D;
                            height: 90%;
                            width: 50%;
                            margin-left: 25%;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column-reverse;
        
                            .barInner{
                                background-color: #F4E61B;
                                border-radius: 20px;
                            }
                        }
                        p{
                            font-family: Roboto;
                            margin-top: 2vh;
                            font-size: 1.4vh;
                            text-align: center;
                            font-style: normal;
                            font-weight: 400;
                            color: rgb(179, 185, 191);
                        }
                        p:hover{
                            color: white;
                            cursor: pointer;
                        }
                    }
                }
            }
    
            .rightBottomSection{
    
                display: flex;
                flex-direction: row;
                width: 100%;
                height: max-content;
                padding-bottom: 8vh;
    
                .mentalHealth{
                    width: 46%;
                    margin-right: 4%;
                    height: 35vh;
                    margin-top: 3.5vh;
                    margin-left: 8vw;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;

                    .topicsContent{
                        height: 80%;
                        overflow-y: scroll;
                        padding-right: 3%;
                        .topic{
                            display: flex;
                            width: 95%;
                            margin-left: 5%;
                            background-color: #272727;
                            margin-bottom: 2%;
                            border-radius: 10px;
                            height: auto;
    
                            .tNo{
                                height: 95%;
                                margin-top: 2.5%;
                                margin-left: 2%;
                                background-color: #A1991D;
                                padding: 2%;
                                color: black;
                                border-radius: 6px;
                                width: 20%;
                                text-align: center;
                            }
                            .topicName{
                                padding-top: 1.5%;
                                padding-right: 1%;
                                display: flex; /* Use flexbox */
                                // justify-content: center; /* Center horizontally */
                                align-items: center; /* Center vertically */
                                margin-left: 2%;
                                font-size: 1.8vh;
                                width: 80%;
                                justify-self: center;
                            }
                        }
                    }
                    .topicsContent::-webkit-scrollbar {
                        background-color: #2B2B2B; /* or add it to the track */
                        width: 12px;
                        border-radius: 5px;
                    }
                    .topicsContent::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background: #BEB419;
                    }

                    .p1{
                        font-size: 2.5vh;
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        margin-top: 2%;
                    }
                    .p2{
                        font-size: 1.75vh;
                        width: 100%;
                        text-align: center;
                        margin: 0%;
                        color: #F3E61B;
                    }
                    .arc-circle {
                        width: 75%; /* Set the width and height as needed */
                        height: 60%;
                        margin-left: 12.5%;
                        padding-top: 2.5%;
                        padding-left: 2.5%;
                        background: conic-gradient(
                          #F3E61B 0% 25%, /* Starting and ending points of the arc */
                          transparent 25% 50%, /* Remaining part of the circle */
                          #F3E61B 50% 100%
                        );
                        border-radius: 50%; /* Make it a circle */
                        .inner-circle {
                            background-color:rgba(231, 231, 231, 1);; /* Set the inner circle color */
                            border-radius: 50%; /* Make it a circle */
                            height: 95%;
                            width: 95%;
                            padding-top: 10%;
                            padding-left: 10%;
                            .inner-circle {
                                background-color:#222222; /* Set the inner circle color */
                                border-radius: 50%; /* Make it a circle */
                                height: 90%;
                                width: 90%;
                                padding-top: 25%;
                                img{
                                    height: 20%;
                                    width: 20%;
                                    margin-left: 35%;
                                }
                                p{
                                    width: 90%;
                                    text-align: center;
                                    font-size: 2vh;
                                }
                              }
                          }
                    }
                    .CS{
                        font-size: 5vh;
                        margin-top: 20%;
                        text-align: center;
                        color: #F4E61B;
                        
                    }
                    .heading{
                        font-size: 2.5vh;
                        
                        img{
                            margin-right: 3%;
                        }
                    }
                }
                .analytics{
                    width: 46%;
                    height: 35vh;
                    margin-top: 3.5vh;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;

                    .topicsContent{
                        height: 80%;
                        overflow-y: scroll;
                        padding-right: 3%;
                        .topic{
                            display: flex;
                            width: 95%;
                            margin-left: 5%;
                            background-color: #272727;
                            margin-bottom: 2%;
                            border-radius: 10px;
                            height: auto;
    
                            .tNo{
                                height: 95%;
                                margin-top: 2.5%;
                                margin-left: 2%;
                                background-color: #A1991D;
                                padding: 2%;
                                color: black;
                                border-radius: 6px;
                                width: 20%;
                                text-align: center;
                            }
                            .topicName{
                                padding-top: 1.5%;
                                padding-right: 1%;
                                display: flex; /* Use flexbox */
                                // justify-content: center; /* Center horizontally */
                                align-items: center; /* Center vertically */
                                margin-left: 2%;
                                font-size: 1.8vh;
                                width: 80%;
                                justify-self: center;
                            }
                        }
                    }
                    .topicsContent::-webkit-scrollbar {
                        background-color: #2B2B2B; /* or add it to the track */
                        width: 12px;
                        border-radius: 5px;
                    }
                    .topicsContent::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background: #BEB419;
                    }

                    .heading{
                        font-size: 2.5vh;
                        display: flex;
                        margin-bottom: 15%;
                        
                        img{
                            margin-right: 3%;
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            color: #CED8E1;
                            margin-left: 30%;
                            font-size: 2vh;
                        }
                    }
                }
                .subscribeSection{
                    // background-color: blue;
                    height: 35vh;
                    width: 90vw;
                    margin-top: 1.5vh;
                    margin-left: 5vw;

                    img{
                        // position: absolute;
                        // height: 32%;
                        // margin-left: 4%;
                        // margin-top: 10%;
                        height: 60%;
                        margin-left: 22%;
                        margin-bottom: -60%;
                    }
                    .gradientDiv{
                        height: 70%;
                        margin-top: 25%;
                        border-radius: 20px;
                        background: linear-gradient(135deg, rgba(137, 121, 39, 0.25) 0%, rgba(189, 204, 148, 0.02) 100%);
    
                        p{
                            width: 70%;
                            margin-left: 15%;
                            padding-top: 17%;
                            font-size: 1.4vh;
                            text-align: center;
                        }
    
                        button{
                            height: 20%;
                            border-radius: 15px;
                            background-color: #F4E61B;
                            border: none;
                            font-weight: 500;
                            width: 40%;
                            margin-left: 30%;
                        }
                    }
                }
            }
        }
    }

}
@media only screen and (min-width: 1024px){
    .profile{
        display: flex;
        direction: column;
        padding-left: 100px;
        background-color: #161616;
        height: 100vh;
        color: white;

        .ListView{
            position: fixed;
            z-index: 999;
            height: 100vh;
            width:100vw;
            background-color: #161616a3;

            .ListViewContent{
                position: absolute;
                width: 60vw;
                left: 15vw;
                top:15vh;
                height: 70vh;
                background-color: #464646;
                padding: 2vh;
                border-radius: 20px;

                .studentList{
                    overflow-y: scroll;

                    .student{
                        display: flex;
                        padding-left: 2vw;
                        padding-right: 2vw;
                        background-color: #464646;
                        margin-top: 2%;
                        font-size: 1.8vh;
                        height: 6vh;
                        border-width: 1px;
                        border-style: solid;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-color: black;

                        p:nth-of-type(1){
                            padding-left: 2vw;
                            padding-top: 0.6vh;
                            text-align: center;
                            width: 10%;
                            overflow-y: scroll;
                            height: 100%;

                        }
                        p:nth-of-type(2){
                            padding-left: 2vw;
                            text-align: center;
                            padding-top: 0.6vh;
                            width: 35%;
                            overflow-y: scroll;
                            height: 100%;
                        }
                        p:nth-of-type(3){
                            padding-left: 2vw;
                            text-align: center;
                            padding-top: 0.6vh;
                            width: 10%;
                            overflow-y: scroll;
                            height: 100%;
                        }
                        p:nth-of-type(4){
                            padding-left: 2vw;
                            text-align: center;
                            padding-top: 0.6vh;
                            width: 45%;
                            overflow-y: scroll;
                            height: 100%;
                        }
                        p::-webkit-scrollbar {
                            width: 0px;
                        }
                    }
                }
                .student{
                    display: flex;
                    padding-left: 2vw;
                    padding-right: 2vw;
                    background-color: #464646;
                    margin-top: 2%;
                    font-size: 1.8vh;
                    height: 6vh;
                    border-width: 1px;
                    border-style: solid;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-color: black;

                    p:nth-of-type(1){
                        padding-left: 2vw;
                        padding-top: 0.6vh;
                        text-align: center;
                        width: 10%;
                        overflow-y: scroll;
                        height: 100%;

                    }
                    p:nth-of-type(2){
                        padding-left: 2vw;
                        text-align: center;
                        padding-top: 0.6vh;
                        width: 35%;
                        overflow-y: scroll;
                        height: 100%;
                    }
                    p:nth-of-type(3){
                        padding-left: 2vw;
                        text-align: center;
                        padding-top: 0.6vh;
                        width: 10%;
                        overflow-y: scroll;
                        height: 100%;
                    }
                    p:nth-of-type(4){
                        padding-left: 2vw;
                        text-align: center;
                        padding-top: 0.6vh;
                        width: 45%;
                        overflow-y: scroll;
                        height: 100%;
                    }
                    p::-webkit-scrollbar {
                        width: 0px;
                    }
                }
                .studentList::-webkit-scrollbar {
                    width: 0px;
                }
            }
        }

    
        .analyticsPopUp{
            position: fixed;
            z-index: 4;
            height: 100vh;
            width: 100vw;
            background-color: #1616169e;

            .content{
                display: flex;
                position: absolute;
                height: 90%;
                top: 5%;
                width: 80%;
                left: 5%;
                border-radius: 20px;
                border: 1px solid #464646;
                background: #202020;
                padding: 2%;
                overflow-y: hidden;
                padding-bottom: 5%;

                .heading{
                    font-size: 3.5vh;
                    position: absolute;
                    width: 80%;
                    text-align: left;
                    display: flex;
                    
                    img{
                        margin-right: 3%;
                        margin-left: 40%;
                    }
                    button{
                        background-color: transparent;
                        border: none;
                        color: #CED8E1;
                    }
                }
                .graph{
                    
                    border-radius: 20px;
                    border: 1px solid #333;
                    background: #1E1E1E;
                    width: 30%;
                    margin: 2%;
                    padding-top: 4%;
                    padding-bottom: 2%;
                    height: 65%;
                    margin-top: 10%;
                    p{
                        width: 100%;
                        text-align: center;
                        color:#CED8E1;
                        margin-top: 4%;
                    }
                }
                .strengthweakness{
                    display: flex;
                    flex-direction: column;
                    width: 35%;
                    height: 90%;
                    margin-top: 5%;

                    .points{
                        border-radius: 20px;
                        border: 1px solid #333;
                        background: #1E1E1E;
                        width: 90%;
                        margin: 2%;
                        padding-top: 2%;
                        padding-bottom: 2%;
                        min-height: 50%;
                        max-height: 50%;
                        .heading1{
                            font-size: 3vh;
                            width: 100%;
                            text-align: left;
                            padding-left: 4%;
                            font-weight: 500;
                            letter-spacing: -0.5px;
                        }
                        .allTps{
                            overflow-y: scroll;
                            height: 70%;
                            width: 90%;
                            margin-left: 5%;
                            .topic{
                                width: 100%;
                                .div1{
                                    display: flex;
                                    border-radius: 15px;
                                    background: #232323;
                                    // display: flex;
                                    padding: 0%;
                                    margin-top: 2%;
                                    width: 90%;
                                    margin-left: 5%;
                                    font-size: 2.2vh;
                                    height: 6vh;
                                }
                                .ts{
                                    padding: 0%;
                                    padding-left: 10%;
                                    margin-top: 2%;
                                    height: max-content;
                                }
                                p{
                                    height: 3.4vh;
                                    margin-top: 1.2vh;
                                    padding-left: 5%;
                                    width: 90%;

                                    overflow-x: hidden;
                                }
                                button{
                                    width: 15%;
                                    height: 4vh;

                                    margin-top: 1vh;
                                    margin-right: 0.6vw;
                                    border-radius: 15px;
                                    background-color: #FCEE2173;
                                    border: none;
                                    font-size: 3vh;
                                    padding: 0%;
                                    img{
                                        width: 40%;
                                        height: 50%;
                                        margin-top: -14%;
                                    }
                                }
                            }
                        }
                        .allTps::-webkit-scrollbar {
                            width: 12px;               /* width of the entire scrollbar */
                          }
                          
                          .allTps::-webkit-scrollbar-track {
                            background:#2B2B2B;
                            border-radius: 20px;        /* color of the tracking area */
                          }
                          
                          .allTps::-webkit-scrollbar-thumb {
                            background-color:#FCEE2173;    /* color of the scroll thumb */
                            border-radius: 20px;       /* roundness of the scroll thumb */
                          }
                    }
                }
            }
            .content::-webkit-scrollbar {
                width: 12px;               /* width of the entire scrollbar */
              }
              
              .content::-webkit-scrollbar-track {
                background:transparent;        /* color of the tracking area */
              }
              
              .content::-webkit-scrollbar-thumb {
                background-color:#FCEE2173;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
              }
        }

        
        .profileLeft{
            height: 100vh;
            width: 35vw;
            background-color: transparent;
            
            .userGreetings{
                margin-top: 6%;
    
                height: 10vh;
                width: 92%;
                margin-left: 8%;
                display: flex;
                flex-direction: row;
    
                .text{
                    display: flex;
                    flex-direction: column;
                    width: 65%;
                    padding: 2%;
                    .P1{
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 2vh;
                        font-weight: 400;
                    }
                    .P2{
                        font-size:4.5vh;
                        font-weight: 400;
                        letter-spacing: 2px;
                    }
                }
    
                img{
                    height: 90%;
                    margin-top: 2%;
                    border-radius: 15px;
                    margin-left: 10%;
               }
            }
            .cont1{
                .viewBtnP{
                    background-color: #F4E61B;
                    width: 75%;
                    margin-left: 10%;
                    margin-top: 5vh;
                    height: 7vh;
                    border-radius: 12px;
                    border: none;
                    font-weight: 500;
                    z-index: 4;
                }
                .classSelect{
                    margin-top: 4vh;
                    margin-bottom: 2vh;
                    height: 30vh;
                    width: 90%;
                    margin-left: 5%;

                    .viewBtnP{
                        background-color: #F4E61B;
                        width: 75%;
                        margin-left: 10%;
                        margin-top: 2vh;
                        height: 7vh;
                        border-radius: 12px;
                        border: none;
                        font-weight: 500;
                        z-index: 4;
                    }

                    .slider{
                        display: flex;
                        height: 8vh;
                        width: 100%;
                        background-color: #2E2E2E;
                        margin-top: 4vh;
                        border-radius: 50px;
                        margin-bottom: 3vh;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                        button{
                            width: 33.33333333%;
                            height: 100%;
                            font-size: 3vh;
                            border-radius: 50px;
                            border: none;
                            background-color: #2E2E2E;
                            color: white;
                        }
                    }
                    .heading{
                        font-size: 3.5vh;
                        width: 90%;
                        margin-left: 5%;
                    }
                    .grades{
                        height: 8vh;
                        // background-color: yellow;
                        max-height: 12vh;
                        overflow-y: scroll;
                        width: 100%;
                        // background-color: #F4E61B;
                        padding-top: 0vh;
                        padding-left: 10%;
                        padding-right: 10%;
                        display: flex;
                        flex: 3;

                        .class{
                            height: 5vh;
                            padding: 1vh;
                            text-align: center;
                            width: 25%;
                            margin-right: 5%;
                            background-color: #F3E61B;
                            cursor: pointer;
                            color: #161616;
                            font-weight: 500;
                            border-radius: 10px;
                            font-size: 1.8vh;
                        }
                    }
                    .grades::-webkit-scrollbar {
                        display: none; /* Safari and Chrome */
                    }
                    .chapDiv{
                        background-color: #1B1B1B;
                        border-radius: 10px;
                        width: 90%;
                        margin-left: 5%;
                        height: 70%;
                        padding: 3%;
                        display: flex;
        
                        .info{
                            width: 80%;
                            height: 100%;
                            padding-left: 4%;
                            .name{
                                font-size: 1.8vh;
                                font-weight: 600;
                            }
                            .desc{
                                font-size: 1.4vh;
                                font-weight: 400;
                                color: rgb(179, 185, 191);
                            }
                            .outer{
                                min-width: 100%;
                                padding: 0.5%;
                                height: 8%;
                                margin-top: 0;
                                background-color: #2B2B2B;
                                border-radius: 15px;
                                .inner{
                                    height: 90%;
                                    background-color: #F3E61B;
                                    border-radius: 15px;
                                }
                            }
                        }
        
                        img{
                            height: 40%;
                            cursor: pointer;
                            margin-left: 15%;
                        }
                    }
                }
                .sections{
                    margin-top: 0vh;
                    margin-bottom: 2vh;
                    height: 15vh;
                    width: 90%;
                    margin-left: 5%;
                    
                    .sectionSelect{
                        min-height: 20vh;
                        max-height: 20vh;
                        overflow-y: scroll;
                        width: 100%;
                        // background-color: #F4E61B;
                        padding-left: 10%;
                        padding-right: 10%;
                        display: flex;
                        flex: 3;

                        .section{
                            height: 5vh;
                            padding: 1vh;
                            text-align: center;
                            width: 25%;
                            margin-right: 5%;
                            background-color: #F3E61B;
                            cursor: pointer;
                            color: #161616;
                            font-weight: 500;
                            border-radius: 10px;
                            font-size: 1.8vh;
                        }
                    }
                    .sectionSelect::-webkit-scrollbar {
                        display: none; /* Safari and Chrome */
                    }
                }
                .chapterSelect{
                    margin-top: 4vh;
                    margin-bottom: 2vh;
                    height: 6vh;
                    width: 90%;
                    margin-left: 5%;

                    .selectDiv{
                        display: flex;
                        margin-bottom: 2vh;
                        
                        .heading2{
                            font-size: 3vh;
                            // padding-top: 2%;
                            text-align: center;
                            width: 40%;
                            margin-right: 5%;
                            height: 5vh;
                        }
                        
                        select{
                            background-color: #2E2E2E;
                            color:#C4C4C4;
                            border: none;
                            width: 40%;
                            height: 5vh;
                            border-radius: 10px;
                            padding: 1%;
                        }
                    }

                }
                .chapterView{
                    background-color: #F4E61B;
                    width: 75%;
                    margin-left: 10%;
                    margin-top: 2vh;
                    height: 7vh;
                    border-radius: 12px;
                    border: none;
                    font-weight: 500;
                    z-index: 4;
                }
                
                .recommendedChaps{
        
                    height: 50vh;
                    width: 80%;
                    margin-left: 10%;
        
                    margin-top: 2vh;
                    .heading{
                        font-size: 3.2vh;
                        height: 12%;
                    }
                    .RecChaps{
                        width: 95%;
                        display: flex;
                        overflow-x: scroll;
                        width: 100% ;
                        height: 90%;
                        padding-bottom: 5%;
        
                        .RecChap{
                            margin-right: 4%;
                            height: 100%;
                            min-width: 55%;
                            background-color: #1B1B1B;
                            padding-top: 3%;
                            border-radius: 25px;
        
                            .chapName{
                                margin-top: 2%;
                                width: 100%;
                                text-align: center;
                                font-size: 2.5vh;
                            }
                            img{
                                height: 34%;
                                width: 70%;
                                margin-left: 15%;
                                margin-top: 3%;
                                margin-bottom: 3%;
                                border: 1px solid #333;
                                background: #232323;
                                border-radius: 14px;
                            }
                            .topicName{
                                width: 100%;
                                text-align: center;
                                margin-top: 4%;
                                width: 85%;
                                margin-left: 7.5%;
                                font-size: 1.9vh;
                                // font-size: 18px;
                                font-weight: 400;
                            }
                            .Chapdesc{
                                width: 100%;
                                font-size: 12px;
                                text-align: center;
                            }
                            .start{
                                background-color: #F4E61B;
                                width: 40%;
                                margin-left: 30%;
                                height: 14%;
                                font-size: 1.8vh;
                                margin-top: -1%;
                                border: none;
                                border-radius: 20px;
                            }
                        }
                    }
                    .RecChaps::-webkit-scrollbar {
                        width: 7px;
                    }
                    
                    .RecChaps::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background:transparent;
                        border-radius: 11px;
                    }
                    
                    .RecChaps::-webkit-scrollbar-thumb {
                        max-height: 50px;
                        background:yellow;
                        border-radius: 11px;
                    }
                }
        
            }
        }
        .profileRight{
            background: #1C1C1C;
            height: 100vh;
            width: 60vw;
    
    
    
            .performanceGraph{
                padding: 2%;
                padding-top: 3%;
                height: 50vh;
                width: 95%;
                margin-left: 2.5%;
                margin-top: 3vh;
                border-radius: 25px;
                background-color: #252525;
    
                .heading{
                    font-size: 3vh;
                    margin-left: 8%;
                    img{
                        margin-right: 1%;
                    }
                }
                .charts{
                    height: 80%;
                    width: 80%;
                    margin-left: 10%;
                    display: flex;
                    .y-axis{
                        display: flex;
                        flex-direction: column-reverse;
                        width: 2%;
                        margin-right: 5%;
                        margin-top: 1vh;
                        height: 95%;
                        color: rgb(179, 185, 191);
                        p{
                            height: 16.66667%;
                            font-size: 2.10vh;
                        }
                        // background-color: #F4E61B;
                    }
    
                    .barComponent{
                        height: 95%;
                        margin-top: 1.5vh;
                        width: 5%;
                        border-radius: 20px;
                        margin-right: 4%;
                        .barOuter{
                            background-color: #34352D;
                            height: 90%;
                            width: 50%;
                            margin-left: 25%;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column-reverse;
        
                            .barInner{
                                background-color: #F4E61B;
                                border-radius: 20px;
                            }
                        }
                        p{
                            // font-family: Roboto;
                            margin-top: 2vh;
                            font-size: 2vh;
                            text-align: center;
                            font-style: normal;
                            font-weight: 400;
                            color: rgb(179, 185, 191);
                        }
                        p:hover{
                            color: white;
                            cursor: pointer;
                        }
                    }
                }
            }
    
            .rightBottomSection{
    
                display: flex;
                width: 100%;
                height: 50%;
    
                .mentalHealth{
                    width: 45%;
                    margin-right: 2%;
                    height: 85%;
                    margin-top: 2.5%;
                    margin-left: 3%;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;

                    .topicsContent{
                        height: 80%;
                        overflow-y: scroll;
                        padding-right: 3%;
                        .topic{
                            display: flex;
                            width: 95%;
                            margin-left: 5%;
                            background-color: #272727;
                            margin-bottom: 2%;
                            border-radius: 10px;
                            height: auto;
    
                            .tNo{
                                height: 95%;
                                margin-top: 2.5%;
                                margin-left: 2%;
                                background-color: #A1991D;
                                padding: 2%;
                                color: black;
                                border-radius: 6px;
                                width: 20%;
                                text-align: center;
                            }
                            .topicName{
                                padding-top: 1.5%;
                                padding-right: 1%;
                                display: flex; /* Use flexbox */
                                // justify-content: center; /* Center horizontally */
                                align-items: center; /* Center vertically */
                                margin-left: 2%;
                                font-size: 1.8vh;
                                width: 80%;
                                justify-self: center;
                            }
                        }
                    }
                    .topicsContent::-webkit-scrollbar {
                        background-color: #2B2B2B; /* or add it to the track */
                        width: 12px;
                        border-radius: 5px;
                    }
                    .topicsContent::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background: #BEB419;
                    }
                    .p1{
                        font-size: 3vh;
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        margin-top: 2%;
                    }
                    .p2{
                        font-size: 2vh;
                        width: 100%;
                        text-align: center;
                        margin: 0%;
                        color: #F3E61B;
                    }
                    .arc-circle {
                        width: 42.5%; /* Set the width and height as needed */
                        height: 60%;
                        margin-left: 28.75%;
                        padding-top: 2.5%;
                        padding-left: 2.5%;
                        background: conic-gradient(
                          #F3E61B 0% 25%, /* Starting and ending points of the arc */
                          transparent 25% 50%, /* Remaining part of the circle */
                          #F3E61B 50% 100%
                        );
                        border-radius: 50%; /* Make it a circle */
                        .inner-circle {
                            background-color:rgba(231, 231, 231, 1);; /* Set the inner circle color */
                            border-radius: 50%; /* Make it a circle */
                            height: 95%;
                            width: 95%;
                            padding-top: 10%;
                            padding-left: 10%;
                            .inner-circle {
                                background-color:#222222; /* Set the inner circle color */
                                border-radius: 50%; /* Make it a circle */
                                height: 90%;
                                width: 90%;
                                padding-top: 25%;
                                img{
                                    height: 20%;
                                    width: 20%;
                                    margin-left: 35%;
                                }
                                p{
                                    width: 90%;
                                    text-align: center;
                                    font-size: 2vh;
                                }
                              }
                          }
                    }
                    .CS{
                        font-size: 5vh;
                        margin-top: 20%;
                        text-align: center;
                        color: #F4E61B;
                        
                    }
                    .heading{
                        font-size: 2.5vh;
                        
                        img{
                            margin-right: 3%;
                        }
                        
                    }
                }
                .analytics{
                    width: 45%;
                    margin-right: 2%;
                    height: 85%;
                    margin-top: 2.5%;
                    margin-left: 1%;
                    border-radius: 25px;
                    background-color: #222222;
                    padding: 3%;

                    .topicsContent{
                        height: 80%;
                        overflow-y: scroll;
                        padding-right: 3%;
                        .topic{
                            display: flex;
                            width: 95%;
                            margin-left: 5%;
                            background-color: #272727;
                            margin-bottom: 2%;
                            border-radius: 10px;
                            height: auto;
    
                            .tNo{
                                height: 95%;
                                margin-top: 2.5%;
                                margin-left: 2%;
                                background-color: #A1991D;
                                padding: 2%;
                                color: black;
                                border-radius: 6px;
                                width: 20%;
                                text-align: center;
                            }
                            .topicName{
                                padding-top: 1.5%;
                                padding-right: 1%;
                                display: flex; /* Use flexbox */
                                // justify-content: center; /* Center horizontally */
                                align-items: center; /* Center vertically */
                                margin-left: 2%;
                                font-size: 1.8vh;
                                width: 80%;
                                justify-self: center;
                            }
                        }
                    }
                    .topicsContent::-webkit-scrollbar {
                        background-color: #2B2B2B; /* or add it to the track */
                        width: 12px;
                        border-radius: 5px;
                    }
                    .topicsContent::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background: #BEB419;
                    }

                    .heading{
                        font-size: 2.5vh;
                        display: flex;
                        
                        img{
                            margin-right: 5%;
                            padding-bottom: 2%;
                        }
                        button{
                            background-color: transparent;
                            border: none;
                            color: #CED8E1;
                            margin-left: 48%;
                            margin-top: -4%;
                        }
                    }
                }
                .subscribeSection{
                    // background-color: blue;
                    height: 85%;
                    width: 40%;
                    margin-top: 2.5%;
                    margin-left: 4%;
                    img{
                        position: absolute;
                        height: 32%;
                        margin-left: 4%;
                        margin-top: -1%;
                    }
                    .gradientDiv{
                        height: 70%;
                        margin-top: 25%;
                        border-radius: 20px;
                        background: linear-gradient(135deg, rgba(137, 121, 39, 0.25) 0%, rgba(189, 204, 148, 0.02) 100%);
    
                        p{
                            width: 70%;
                            margin-left: 15%;
                            padding-top: 17%;
                            font-size: 1.8vh;
                            text-align: center;
                        }
    
                        button{
                            height: 20%;
                            border-radius: 15px;
                            background-color: #F4E61B;
                            border: none;
                            font-weight: 500;
                            width: 40%;
                            margin-left: 30%;
                        }
                    }
                }
            }
        }
    }
}

