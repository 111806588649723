.account-setup {
    display: flex;
    flex-direction: row;
    background-color: #161616;
    height: 100vh;

    .left-section {
        flex: 5;
        margin: 0 5vw;
        padding-top: 15vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .logo {
            height: 48px;
            position: absolute;
            top: 5%;
            left: 5%;
        }

        .title {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 20px;
            /* identical to box height, or 56% */
            letter-spacing: 1.25px;
            color: #FFFFFF;
            margin-top: -13%;
        }

        .subtitle {
            max-width: 450px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            margin-top: 2%;
            /* or 125% */

            text-align: center;
            letter-spacing: 0.75px;

            color: #FFFFFF;
        }

        .card {
            width: 40vw;
            min-height: 50vh;
            height: max-content;

            margin-top: 3%;
            background: #1C1C1C;
            border: 1px solid #3A3A3A;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3vh 4vw;

            .tabs{

                .active-image{
                    height: 100px;
                }
                font-size: 10px;
                width: 100%;
                
            }

            .dropdown-title {
                text-align: start;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #FFFFFF;
                padding: 25px 0 8px 20px;
            }

            .dropdown {
                width: 25vw;
                height: 50px;
                border: 1px solid #464646;
                border-radius: 16px;
                text-align: center;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #F0F0F0;

                background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
                background-color: #272727;
                background-position: calc(100% - 1.2rem) center !important;
                -moz-appearance: none !important;
                -webkit-appearance: none !important;
                appearance: none !important;
            }

            .dropdown:hover {
                cursor: pointer;
            }
            .dropdown:focus{
                border-radius: 10px;
                box-shadow: 0 0 0 0.7px #FF174F, 0 0 0 1.7px #713FFF;
                background-clip: padding-box;
                outline-width: 0;
            }

            .dropdown-option {
                background-color: #1C1C1C;
            }
            .input-feild {
                width: 100%;
                height: 50px;
                margin-bottom: 5px;
                border: 1px solid #464646;
                border-radius: 16px;
                text-align: center;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #F0F0F0;
                background-color: #272727;

            }
            .input-feild:focus{
                // border-width: 1px 0px 1px 0px;
                // border-image: linear-gradient(to right, #FF174F, #713FFF);
                // border-image-slice: 1;
                border-radius: 10px;
                box-shadow: 0 0 0 0.7px #FF174F, 0 0 0 1.7px #713FFF;
                background-clip: padding-box;
                outline-width: 0;
              
            }
            

            

            .next-button {
                background: #FCEE21;
                border-radius: 16px;
                height: 47px;
                width: 120px;
                border: none;
                align-self: center;
                margin-top: 50px;
            }

        }



    }

    .right-section {
        flex: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5%;

        img {
            margin-top: 40px;
            width: 18vw;

        }

        .sub-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #8C8C8C;
            padding-top: 8vh;
            max-width: 450px;
            margin-top: 70%;
        }

    }
}
@media only screen and (min-width: 1024px){
    .lamp {
        position: absolute;
        top: 0%;
        right: 30vw;
        height: 200px;
    }
    .setDisplayPic{
        position: absolute;
        top:15%;
        right:12vw;
        height: 50vh;
        width: 45vw;
    }
}

@media only screen and (max-width: 768px) {
    .account-setup {
        flex-direction: column;
        height: max-content;
        min-height: 100vh;
        .left-section {
            flex: 5;
            margin: 0 5vw;
            padding-top: 15vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 98%;
            margin-left: 1%;
    
            .logo {
                height: 48px;
                position: absolute;
                top: 5%;
                left: 5%;
            }
    
            .title {
                margin-top: 30%;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                line-height: 20px;
                /* identical to box height, or 56% */
                letter-spacing: 1.25px;
                color: #FFFFFF;
            }
    
            .subtitle {
                padding-top: 20px;
                max-width: 450px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 300;
                font-size: 10px;
                line-height: 20px;
                /* or 125% */
    
                text-align: center;
                letter-spacing: 0.75px;
    
                color: #FFFFFF;
            }
    
            .card {
                // width: 25vw;
                width: 85%;
                min-height: 60vh;
                height: max-content;

                .dropdown-title {
                    text-align: start;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #FFFFFF;
                    padding: 28px 0 8px 20px;
                }
    
                .dropdown {
                    width: 90%;
                    height: 55px;
                }
    
                .dropdown-option {
                    background-color: #1C1C1C;
                }
    
                .input-feild {
                    width: 90%;
                }
    
                .next-button {
                    height: 52px;
                    width: 160px;
                }
    
            }
    
    
    
        }
    }
    .lamp {
        position: absolute;
        left:75%;
        margin-top: -6%;
        height: 15vh;
        width:60px;
    }
    .setDisplayPic{
        position: absolute;
        top:0%;
        height: 26vh;
        width: 60vw;
    }
    
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .account-setup {
        flex-direction: column;
        height: max-content;
        min-height: 100vh;
        .left-section {
            flex: 5;
            margin: 0 5vw;
            padding-top: 15vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
    
            .logo {
                height: 48px;
                position: absolute;
                top: 5%;
                left: 5%;
            }
    
            .title {
                margin-top: 30%;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                line-height: 20px;
                /* identical to box height, or 56% */
                letter-spacing: 1.25px;
                color: #FFFFFF;
            }
    
            .subtitle {
                padding-top: 20px;
                max-width: 450px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 300;
                font-size: 10px;
                line-height: 20px;
                /* or 125% */
    
                text-align: center;
                letter-spacing: 0.75px;
    
                color: #FFFFFF;
            }
    
            .card {
                // width: 25vw;
                width: 85%;
                min-height: 60vh;
                height: max-content;

                .dropdown-title {
                    text-align: start;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #FFFFFF;
                    padding: 28px 0 8px 20px;
                }
    
                .dropdown {
                    width: 95%;
                    height: 55px;
                }
    
                .dropdown-option {
                    background-color: #1C1C1C;
                }
    
                .input-feild {
                    width: 95%;
                }
    
                .next-button {
                    height: 52px;
                    width: 360px;
                }
                .errorMsg{
                    font-size: 15px;
                    color: #F0F0F0;
                }
    
            }
        }
        .lamp {
            position: absolute;
            left:60%;
            top: 0;
            height: 200px;
            width:200px;
        }
        .setDisplayPic{
            position: absolute;
            top:0%;
            left:40%;
            height: 26vh;
            width: 40vw;
        }
    }
}